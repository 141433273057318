import authService from '../auth';
import {
  baseDepartment,
  baseEmployee,
  baseClient,
  baseClientManager,
  baseWarehouse,
} from '../data-source/base';

const loadDepartment = dataSource => {
  return baseDepartment
    .load({
      filter: [['fk_company_id', '=', authService.getCompanyId()]],
      take: 1000,
      skip: 0,
    })
    .then(({ data }) => {
      dataSource.department = data;
    });
};

const loadEmployee = (dataSource, departmentId) => {
  return baseEmployee
    .load({
      filter: [['fk_department_id', '=', departmentId]],
      take: 1000,
      skip: 0,
    })
    .then(({ data }) => {
      dataSource.employee = data;
    });
};

const loadClient = dataSource => {
  return baseClient
    .load({
      filter: [['fk_company_id', '=', authService.getCompanyId()]],
      take: 1000,
      skip: 0,
    })
    .then(({ data }) => {
      dataSource.client_company = data;
    });
};

const loadClientManager = (dataSource, clientName) => {
  return baseClient
    .load({
      filter: [['name', '=', clientName]],
    })
    .then(({ data }) => {
      if (data.length > 0) {
        baseClientManager
          .load({
            filter: [['fk_client_id', '=', data[0].id]],
            take: 1000,
            skip: 0,
          })
          .then(({ data }) => {
            dataSource.client_manager = data;
          });
      }
    });
};

const loadWarehouse = dataSource => {
  return baseWarehouse
    .load({
      filter: [['fk_company_id', '=', authService._user.fk_company_id]],
      take: 1000,
      skip: 0,
    })
    .then(({ data }) => {
      dataSource.warehouse = data;
    });
};

export {
  loadDepartment,
  loadEmployee,
  loadClient,
  loadClientManager,
  loadWarehouse,
};
