<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">작지입고현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">작업지시일자</span>
            <dx-date-box v-model:value="vars.formData.startDate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formData.endDate" />
            <span class="search-tab"></span>
            <dx-check-box
              text="미입고유무"
              v-model:value="vars.formData.not_shipped"
            />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="evt => methods.onInitialized(evt, 'status')"
          :data-source="produceWorkOrderItem1"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @row-click="methods.goDetail"
        >
          <dx-column
            data-field="order_number"
            caption="작지번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="order_date"
            caption="작지일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column data-field="client_company" caption="입고번호(최종)" />
          <dx-column data-field="client_manager" caption="입고일자" />
          <dx-column data-field="order_department" caption="작지담당자" />
          <dx-column data-field="order_manager" caption="참고사항" />
          <dx-column data-field="order_type" caption="품목코드" />
          <dx-column data-field="vat_type" caption="품명" />
          <dx-column data-field="payment_terms" caption="규격" />
          <dx-column data-field="delivery_place" caption="작지수량" />
          <dx-column data-field="client_order_number" caption="입고수량" />
          <dx-column data-field="project_number" caption="단위" />
          <dx-column data-field="end_user" caption="대분류" />
          <dx-column data-field="note" caption="중분류" />
          <dx-column data-field="etc" caption="요청납기" />
          <dx-column data-field="vat" caption="미입고수량" />
          <dx-column data-field="vat" caption="가용재고" />
          <dx-column data-field="vat" caption="현재고" />
          <dx-column data-field="vat" caption="고객업체" />
          <dx-column data-field="vat" caption="고객사품번" />
          <dx-column data-field="vat" caption="품목설명" />
          <dx-column data-field="vat" caption="종결" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { produceWorkOrderItem1 } from '../../data-source/produce';
import { reactive } from 'vue';
import moment from 'moment';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxCheckBox,
    DxButton,
  },
  setup() {
    const router = useRouter();
    const vars = {
      grid: {},
      formData: reactive({
        startDate: new Date(),
        enddate: new Date(),
        not_shipped: false,
      }),
    };
    const methods = {
      onInitialized(evt, key) {
        vars.grid[key] = evt.component;
      },
      goDetail({ data }) {
        router.push({ path: `/shipment/order/${data.id}` });
      },
      searchDateFormat(date) {
        return moment(date)
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss');
      },
      async searchDateRange() {
        let filter = [
          [
            'work_order.target_date',
            '>=',
            methods.searchDateFormat(vars.formData.startDate),
          ],
          'and',
          [
            'work_order.target_date',
            '<=',
            methods.searchDateFormat(vars.formData.endDate),
          ],
        ];
        if (vars.formdata.not_shipped) {
          filter = [
            [
              'work_order.target_date',
              '>=',
              methods.searchDateFormat(vars.formData.startDate),
            ],
            'and',
            [
              'work_order.target_date',
              '<=',
              methods.searchDateFormat(vars.formData.endDate),
            ],
            'and',
            ['not_shipped', '>', 0],
          ];
        }
        vars.grid['status'].filter(filter);
      },
    };

    return { vars, methods, produceWorkOrderItem1 };
  },
};
</script>
