<template>
  <dx-data-grid
    :data-source="vars.performance"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="methods.onSelectionChanged"
  >
    <dx-column data-field="number" caption="생산입고번호" />
    <dx-column
      data-field="target_date"
      caption="생산일자"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="department" caption="담당부서" />
    <dx-column data-field="manager" caption="담당자" />
    <dx-column data-field="note" caption="참고사항" />
    <dx-column data-field="etc" caption="비고" />

    <dx-selection
      select-all-mode="allPages"
      show-check-boxes-mode="always"
      :mode="vars.mode"
    />
    <dx-filter-row :visible="true" />
    <dx-paging :page-size="20" />
  </dx-data-grid>
</template>

<script>
import { onMounted, reactive } from 'vue';

import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';

import { getPerformanceRegistration } from '../../data-source/produce';
import authService from '../../auth';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    onMounted(() => {
      methods.refresh();
    });

    const vars = reactive({
      mode: props.multiple ? 'multiple' : 'single',
      performance: null,
      filter: [
        {
          name: 'fk_company_id',
          op: 'eq',
          val: authService._user.fk_company_id || 0,
        },
      ],
    });

    const methods = {
      refresh: async () => {
        vars.performance = getPerformanceRegistration(vars.filter);
      },
      onSelectionChanged: ({ selectedRowsData }) => {
        if (!props.multiple && selectedRowsData)
          selectedRowsData = selectedRowsData[0];
        emit('change', selectedRowsData);
      },
    };

    return {
      vars,
      methods,
    };
  },
};
</script>
