<template>
  <div>
    <dx-data-grid
      :data-source="purchaseReceiving"
      :show-borders="true"
      :remote-operations="true"
      :column-auto-width="true"
    >
      <dx-column
        data-field="receiving.receiving_number"
        caption="입고번호"
        :filter-operations="['contain', '=']"
      />
      <dx-column
        data-field="receiving.receiving_date"
        caption="입고일자"
        data-type="date"
        format="yyyy-MM-dd"
        sort-order="desc"
      />
      <dx-column data-field="receiving_quantity" caption="입고수량" />
      <dx-column data-field="receiving_warehouse" caption="입고창고" />

      <dx-paging :page-size="5" />
    </dx-data-grid>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn, DxPaging } from 'devextreme-vue/data-grid';

import { getPurchaseReceivingItem } from '../../data-source/purchase';

export default {
  components: { DxDataGrid, DxColumn, DxPaging },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { order } = this.templateData.data;
    return {
      purchaseReceiving: this.getTasks(order.id),
    };
  },
  methods: {
    completedValue(rowData) {
      return rowData.Status === 'Completed';
    },
    getTasks(key) {
      return getPurchaseReceivingItem([
        { name: 'fk_order_item_id', op: 'eq', val: key || 0 },
      ]);
    },
  },
};
</script>
