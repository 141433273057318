<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before"
              ><div class="content-title">코드변경관리</div></dx-item
            >
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{ text: '수정', onClick: editCode }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="formData"
          >"
          <dx-group-item :col-count="3">
            <dx-group-item>
              <dx-simple-item
                data-field="change_type"
                :editor-options="{
                  value: '',
                  searchEnabled: false,
                  dataSource: types,
                  valueExpr: 'type',
                  displayExpr: 'name',
                }"
                :validation-rules="validationRules.type"
                editor-type="dxSelectBox"
              >
                <dx-label text="분류" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item data-field="previous_code">
                <dx-label text="변경전" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item data-field="after_code">
                <dx-label text="변경후" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
          <dx-group-item :col-count="1">
            <dx-simple-item data-field="change_reason">
              <dx-label text="변경사유" :show-colon="false" />
            </dx-simple-item>
          </dx-group-item>
        </dx-form>
      </div>
    </div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <dx-tab-panel>
          <dx-item title="품목코드 변경 이력" icon="cart">
            <template #default>
              <div class="tab-first">
                <code-item-history />
              </div>
            </template>
          </dx-item>
          <dx-item title="업체코드 변경 이력" icon="home">
            <template #default>
              <div class="tab-second">
                <code-client-history />
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>
      </div>
    </div>
  </div>
</template>

<script>
import DxTabPanel, { DxItem } from 'devextreme-vue/tab-panel';
import DxToolbar from 'devextreme-vue/toolbar';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';

import CodeItemHistory from './code-item-history.vue';
import CodeClientHistory from './code-client-history.vue';

import { reactive } from 'vue';

export default {
  components: {
    DxTabPanel,
    DxItem,
    DxToolbar,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    CodeItemHistory,
    CodeClientHistory,
  },
  setup() {
    const types = reactive([
      { type: 1, name: '품목코드' },
      { type: 2, name: '업체코드' },
    ]);
    const validationRules = reactive({
      type: [{ type: 'required', message: '분류를 선택하세요' }],
    });

    const formData = reactive({
      change_type: '',
      previous_code: '',
      after_code: '',
      change_reason: '',
    });

    const editCode = () => {};

    return {
      formData,
      types,
      validationRules,
      editCode,
    };
  },
};
</script>

<style>
.tab-first,
.tab-second {
  padding: 8px;
}
</style>
