import RestlessStore from './restless-store';

const shipmentQuote = new RestlessStore('/api/mes/v1/shipment/quote');
const shipmentQuoteItem = new RestlessStore('/api/mes/v1/shipment/quote_item');

const shipmentOrder = new RestlessStore('/api/mes/v1/shipment/order');
const shipmentOrderItem = new RestlessStore('/api/mes/v1/shipment/order_item');

const shipmentRelease = new RestlessStore('/api/mes/v1/shipment/release');
const shipmentReleaseItem = new RestlessStore(
  '/api/mes/v1/shipment/release_item'
);

const procBasicStock = (result, warehouseCode) => {
  result.data.forEach(item => {
    if (warehouseCode) {
      let basicStock = null;
      if (item.basic_stock) {
        if (Array.isArray(item.basic_stock)) {
          basicStock = item.basic_stock.filter(
            stock => stock.wh_code == warehouseCode
          );
        } else {
          if (item.basic_stock.wh_code == warehouseCode) {
            basicStock = [item.basic_stock];
          }
        }
      }
      item.basic_stock = basicStock ? basicStock[0] : null;
    }
  });
};

const getShipmentQuoteItem = (defaultFilters, warehouseCode) => {
  const instance = new RestlessStore('/api/mes/v1/shipment/quote_item', {
    onLoaded: function(result) {
      procBasicStock(result, warehouseCode);
    },
  });
  instance.defaultFilters = defaultFilters;
  return instance;
};

const getShipmentOrderItem = (defaultFilters, warehouseCode) => {
  const instance = new RestlessStore('/api/mes/v1/shipment/order_item', {
    onLoaded: function(result) {
      procBasicStock(result, warehouseCode);
    },
  });
  instance.defaultFilters = defaultFilters;
  return instance;
};

const getShipmentReleaseItem = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/shipment/release_item');
  instance.defaultFilters = defaultFilters;
  return instance;
};

export {
  shipmentQuote,
  shipmentQuoteItem,
  shipmentOrder,
  shipmentOrderItem,
  shipmentRelease,
  shipmentReleaseItem,
  getShipmentQuoteItem,
  getShipmentOrderItem,
  getShipmentReleaseItem,
};
