<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before">
              <div class="content-title">거래처관리</div>
            </dx-item>
          </dx-toolbar>
        </div>

        <div class="pa-2">
          <dx-data-grid ref="grid"
            class="fixed-header-table" height="calc(100vh - 168px)"
            :data-source="baseClient"
            :remote-operations="true"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :focused-row-enabled="true"
            @initialized="(evt) => methods.initialized(evt, 'client-grid')"
            @init-new-row="methods.initInsertedRow"
            @editing-start="methods.clientEditStart"
            @row-validating="methods.rowValidating">
            <!--dx-column data-field="id" caption="번호" :allow-editing="false" sort-order="desc" /-->
            <dx-grid-toolbar>
              <dx-item name="addRowButton" />
              <dx-item name="columnChooserButton" />
              <dx-item location="after" template="excelUploadButton" />
              <dx-item location="after" template="excelSampleDownloadButton" />
            </dx-grid-toolbar>
            <template #excelUploadButton>
              <dx-button icon="upload" @click="methods.excelUpload" />
            </template>
            <template #excelSampleDownloadButton>
              <dx-button icon="download" @click="methods.excelSampleDownload" />
            </template>

            <dx-column data-field="created" caption="생성시간" :allow-editing="false" data-type="date" format="yyyy-MM-dd" :visible="false"/>
            <dx-column data-field="alias" caption="업체약칭" :allow-editing="true" :visible="true">
              <dx-required-rule message="업체약칭을 입력하세요" />
            </dx-column>
            <dx-column data-field="name" caption="업체명" :allow-editing="true" :visible="true"/>
            <dx-column data-field="address" caption="주소" :allow-editing="true" :visible="true" :editor-options="vars.findAddress.columnOptions"/>
            <dx-column data-field="address_detail" caption="상세주소" :allow-editing="true" :visible="false"/>
            <dx-column data-field="zip_code" caption="우편번호" :allow-editing="true" :visible="false"/>
            <dx-column data-field="phone" caption="대표전화번호" :allow-editing="true" :visible="true"/>
            <dx-column data-field="fax" caption="팩스번호" :allow-editing="true" :visible="true"/>
            <dx-column data-field="email" caption="대표 이메일" :allow-editing="true" :visible="true"/>
            <dx-column data-field="homepage" caption="홈페이지" :allow-editing="true" :visible="true"/>
            <dx-column data-field="bill_manager" caption="계산서 담당자" :allow-editing="true" :visible="false"/>
            <dx-column data-field="bill_email" caption="계산서 이메일" :allow-editing="true" :visible="false"/>
            <dx-column data-field="client_type" caption="업체분류" :allow-editing="true" :visible="false">
              <dx-lookup :data-source="vars.common.company_type" display-expr="code_name" value-expr="code_name"/>
            </dx-column>
            <dx-column data-field="district_type" caption="지역구분" :allow-editing="true" :visible="false">
              <dx-lookup :data-source="vars.common.region" display-expr="code_name" value-expr="code_name"/>
            </dx-column>
            <dx-column data-field="manager" caption="당사담당자" :allow-editing="true" :visible="false"/>
            <dx-column data-field="trade_yn" caption="거래중지여부" editor-type="dxCheckBox" :allow-editing="true" :visible="true"/>
            <dx-column data-field="before_alias" caption="변경전 업체약칭" :allow-editing="true" :visible="true"/>
            <dx-column data-field="after_alias" caption="변경후 업체약칭" :allow-editing="true" :visible="true"/>
            <dx-column data-field="corp_number" caption="법인번호" :allow-editing="true" :visible="true">
              <dx-async-rule message="이미 사용중인 법인번호입니다" :validation-callback="methods.duplicationCorpNum" />
            </dx-column>
            <dx-column data-field="business_number" caption="사업자번호" :allow-editing="true" :visible="true">
              <dx-async-rule :validation-callback="methods.duplicationBusnNum" />
              <dx-async-rule :validation-callback="methods.validationBusnNum" />
            </dx-column>
            <dx-column data-field="ceo_name" caption="대표자명" :allow-editing="true" :visible="true"/>
            <dx-column data-field="business_status" caption="업태" :allow-editing="true" :visible="true"/>
            <dx-column data-field="business_sector" caption="종목" :allow-editing="true" :visible="true"/>
            <dx-column data-field="name_en" caption="업체명(영문)" :allow-editing="true" :visible="true"/>
            <dx-column data-field="ceo_name_en" caption="대표자명(영문)" :allow-editing="true" :visible="true"/>
            <dx-column data-field="phone_en" caption="전화번호(국제)" :allow-editing="true" :visible="true"/>
            <dx-column data-field="fax_en" caption="팩스번호(국제)" :allow-editing="true" :visible="true"/>
            <dx-column data-field="address_en" caption="주소(영문)" :allow-editing="true" :visible="true"/>
            <dx-column data-field="register_id" caption="최초등록자" :allow-editing="false" :visible="true"/>
            <dx-column data-field="modify_id" caption="최초수정자" :allow-editing="false" :visible="true"/>
            <dx-column data-field="last_updated_date" caption="최종수정일자" :allow-editing="false" data-type="date" format="yyyy-MM-dd" :visible="true"/>
            
            <dx-editing :allow-adding="true" :allow-updating="true" :allow-deleting="true" :use-icons="true" mode="popup">
              <dx-grid-popup 
                :show-title="true"
                :width="1000" :height="700"
                title="거래처정보">
              </dx-grid-popup>
              <dx-form 
                :col-count="1"
                :show-colon-after-label="false"
                @initialized="(evt) => methods.initialized(evt, 'client-form')">
                
                <dx-group-item :col-count="2" css-class="pa-4">
                  <dx-simple-item data-field="alias">
                    <dx-label text="업체약칭"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="corp_number" :editor-options="{mask: '000000 - 0000000'}">
                    <dx-label text="법인번호"/>
                  </dx-simple-item>

                  <dx-simple-item data-field="name">
                    <dx-label text="정식상호"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="business_number" :editor-options="{mask: '000 - 00 - 00000'}">
                    <dx-label text="사업자번호"/>
                  </dx-simple-item>

                  <dx-simple-item data-field="address">
                    <dx-label text="주소"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="ceo_name">
                    <dx-label text="대표자명"/>
                  </dx-simple-item>

                  <dx-group-item :col-count="2">
                    <dx-simple-item data-field="address_detail">
                      <dx-label text="상세주소"/>
                    </dx-simple-item>
                    <dx-simple-item data-field="zip_code">
                      <dx-label text="우편번호"/>
                    </dx-simple-item>
                  </dx-group-item>
                  <dx-simple-item data-field="business_status">
                    <dx-label text="업태"/>
                  </dx-simple-item>
                  
                  <dx-simple-item data-field="phone">
                    <dx-label text="대표전화번호"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="business_sector">
                    <dx-label text="종목"/>
                  </dx-simple-item>

                  <dx-simple-item data-field="fax">
                    <dx-label text="팩스번호"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="name_en">
                    <dx-label text="업체명(영문)"/>
                  </dx-simple-item>

                  <dx-simple-item data-field="email">
                    <dx-label text="대표 이메일"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="ceo_name_en">
                    <dx-label text="대표자명(영문)"/>
                  </dx-simple-item>

                  <dx-simple-item data-field="homepage">
                    <dx-label text="홈페이지"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="phone_en">
                    <dx-label text="전화번호(국제)"/>
                  </dx-simple-item>

                  <dx-simple-item data-field="bill_manager">
                    <dx-label text="계산서 담당자"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="fax_en">
                    <dx-label text="팩스번호(국제)"/>
                  </dx-simple-item>

                  <dx-simple-item data-field="bill_email">
                    <dx-label text="계산서 이메일"/>
                  </dx-simple-item>
                  <dx-simple-item data-field="address_en">
                    <dx-label text="주소(영문)"/>
                  </dx-simple-item>

                  <dx-group-item :col-count="2">
                    <dx-simple-item data-field="client_type">
                      <dx-label text="업체분류"/>
                    </dx-simple-item>
                    <dx-simple-item data-field="district_type">
                      <dx-label text="지역구분"/>
                    </dx-simple-item>
                  </dx-group-item>
                  <dx-simple-item data-field="remark">
                    <dx-label text="비고"/>
                  </dx-simple-item>

                  <dx-group-item :col-count="2">
                    <dx-simple-item data-field="manager">
                      <dx-label text="당사담당자"/>
                    </dx-simple-item>
                    <dx-simple-item data-field="trade_yn">
                      <dx-label text="거래중지"/>
                    </dx-simple-item>
                  </dx-group-item>
                  <dx-group-item :col-count="2">
                    <dx-simple-item data-field="register_id">
                      <dx-label text="최초등록자"/>
                    </dx-simple-item>
                    <dx-simple-item data-field="created">
                      <dx-label text="최초등록일자"/>
                    </dx-simple-item>
                  </dx-group-item>

                  <dx-group-item :col-count="2">
                    <dx-simple-item data-field="before_alias">
                      <dx-label text="변경전 업체약칭"/>
                    </dx-simple-item>
                    <dx-simple-item data-field="after_alias">
                      <dx-label text="변경후 업체약칭"/>
                    </dx-simple-item>
                  </dx-group-item>
                  <dx-group-item :col-count="2">
                    <dx-simple-item data-field="modify_id">
                      <dx-label text="최종수정자"/>
                    </dx-simple-item>
                    <dx-simple-item data-field="last_updated_date">
                      <dx-label text="최종수정일자"/>
                    </dx-simple-item>
                  </dx-group-item>
                  <dx-group-item :col-span="2" template="secondGrid">
                  </dx-group-item>
                </dx-group-item>
              </dx-form>

            </dx-editing>
            <dx-column-chooser mode="select" :enabled="true"/>
            <dx-filter-row :visible="true"/>
            <dx-paging :page-size="20"/>

            <template #secondGrid>
              <dx-data-grid
                class="fixed-header-table" height="100%"
                :disabled="vars.disableClientManagerGrid.value"
                :data-source="baseClientManager"
                :remote-operations="true"
                :show-borders="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :select-text-on-edit-start="true"
                @initialized="(evt) => methods.initialized(evt, 'client-manager')"
                @init-new-row="methods.initManagerInsertedRow">

                <dx-column data-field="id" caption=" 번호" :allow-editing="false" sort-order="desc" />
                <dx-column data-field="created" caption="생성시간" :allow-editing="false" data-type="date" format="yyyy-MM-dd" :visible="false"/>
                <dx-column data-field="name" caption="거래처담당자" :allow-editing="true" :visible="true" />
                <dx-column data-field="department" caption="부서" :allow-editing="true" :visible="true" />
                <dx-column data-field="position" caption="직급" :allow-editing="true" :visible="true">
                  <dx-lookup :data-source="vars.common.position_code" display-expr="code_name" value-expr="code_name"/>
                </dx-column>
                <dx-column data-field="mobile" caption="휴대폰번호" :allow-editing="true" :visible="true" />
                <dx-column data-field="email" caption="이메일주소" :allow-editing="true" :visible="true" />
                <dx-column data-field="direct_phone" caption="직통전화번호" :allow-editing="true" :visible="true" />
                <dx-column data-field="ext_phone" caption="내선번호" :allow-editing="true" :visible="true" />
                <dx-column data-field="etc" caption="비고" :allow-editing="true" :visible="true" />
                <dx-column data-field="fk_client_id" caption="거래처 ID" :allow-editing="false" :visible="false" />

                <dx-editing :allow-adding="true" :allow-updating="true" :allow-deleting="true" :use-icons="true" mode="row" />
                <dx-paging :page-size="20"/>
              </dx-data-grid>
            </template>
          </dx-data-grid>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.findAddress.popup.value"
      content-template="popup-content"
      title="주소찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true">
      <template #popup-content>
        <div>
          <div style="margin-bottom: 10px">
            <dx-text-box 
              v-model="vars.findAddress.keyword"
              :buttons="vars.findAddress.textBoxOptions"
              @enter-key="methods.findAddressSubmit"
            />
          </div>
          <dx-data-grid
            :height="340"
            :data-source="vars.findAddress.store"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :remote-operations="true"
            @initialized="evt => methods.initialized(evt, 'find-address')"
            @row-click="methods.findAddressSelect">
            <dx-column data-field="road" caption="도로명주소" />
            <dx-column data-field="jibun" caption="지번주소" />
            <dx-column data-field="zip" caption="우편번호" />
            <dx-paging :page-size="20" />
          </dx-data-grid>
        </div>
      </template>
    </dx-popup>

    <input
      hidden
      type="file"
      ref="excelRef"
      accept=".xlsx,.xls"
      @change="methods.excelFileChange"
    />

  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar'
import {DxDataGrid, DxColumn, DxEditing, DxPaging, DxColumnChooser, DxFilterRow, DxLookup, DxPopup as DxGridPopup, DxForm, DxRequiredRule, DxAsyncRule, DxToolbar as DxGridToolbar} from 'devextreme-vue/data-grid'
import {DxLabel, DxGroupItem, DxSimpleItem} from 'devextreme-vue/form'
import {DxPopup} from 'devextreme-vue/popup'
import DxTextBox from 'devextreme-vue/text-box'
import { DxButton } from 'devextreme-vue/button'
import {baseCodeLoader, baseClient, baseClientManager} from '@/data-source/base'
import FindAddressStore from '../../data-source/find-address'
import findBusinessNumber from '../../data-source/find-business-number'
import authService from '../../auth'
import ApiService from '../../utils/api-service'
import { notifyError } from '../../utils/notify';
import stateStore from '@/utils/state-store'
import {reactive, ref, computed} from 'vue'
import moment from 'moment'
import { saveAs } from 'file-saver';

export default {
  components: {
    DxToolbar, DxItem, DxButton,
    DxForm, DxLabel, DxGroupItem, DxSimpleItem,
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxColumnChooser, DxFilterRow, DxLookup, DxGridPopup, DxRequiredRule, DxAsyncRule, DxGridToolbar,
    DxPopup, DxTextBox
  },
  setup() {
    const vars = {}, methods = {}
    const grid = ref(null)

    const excelRef = ref(null)
    const apiService = new ApiService('/api/mes/v1/excel/base/client')

    baseClient.defaultFilters = { name: 'fk_company_id', op: 'eq', val: authService.getCompanyId() }
    const clientCheck = baseClient.clone()

    // Vars
    vars.formData = {}
    vars.common = reactive({ position_code: [], region: [], company_type: [] })    

    vars.disableClientManagerGrid = ref(false)
    vars.editKey = null
    vars.components = {}
    vars.findAddress = {
      popup: ref(false),
      store: new FindAddressStore(),
      keyword: null,
      columnOptions: {
        buttons: [
          {
            name: 'icon', 
            location: 'after', 
            options: { 
              stylingMode: 'text', 
              icon: 'search', 
              onClick: () => {
                const rowIndex = vars.components['client-grid'].getRowIndexByKey(vars.editKey)
                vars.findAddress.keyword = vars.components['client-grid'].cellValue(rowIndex, 'address')
                vars.findAddress.popup.value = true
              } 
            }
          }
        ]
      },
      textBoxOptions: [
        {
          name: 'icon', 
          location: 'after', 
          options: { 
            stylingMode: 'text', 
            icon: 'search', 
            onClick: () => {
              methods.findAddressSubmit()
            } 
          }
        }
      ]
    }

    baseCodeLoader(['직급', '업체분류', '지역구분'])
    .then(response => {
      vars.common.position_code = response['직급']
      vars.common.region = response['지역구분']
      vars.common.company_type = response['업체분류']
    })

    // Methods
    methods.initialized = (evt, key) => {
      vars.components[key] = evt.component
      stateStore.bind(key, evt.component)
    }

    methods.isAddButtonVisible = ({row}) => {
      return !row.isEditing
    }

    methods.initInsertedRow = ({component, data}) => {
      component.columnOption('alias', 'allowEditing', true)
      data.created = moment().format('YYYY-MM-DD HH:mm:ss')
      vars.editKey = 0
      vars.disableClientManagerGrid.value = true
      baseClientManager.defaultFilters = [{name: 'fk_client_id', op: 'eq', val: 0}]
    }

    methods.initManagerInsertedRow = ({data}) => {
      console.log('initManagerInsertedRow')
      data.created = moment().format('YYYY-MM-DD HH:mm:ss')
      data.fk_client_id = vars.formData.id
    }

    methods.clientEditStart = (evt) => {
      console.log(evt)
      const clientId = evt.data.id || 0
      vars.formData = evt.data
      vars.editKey = evt.key
      vars.disableClientManagerGrid.value = false
      evt.component.columnOption('alias', 'allowEditing', false)
      baseClientManager.defaultFilters = [{name: 'fk_client_id', op: 'eq', val: clientId}]
    }

    methods.findAddressSubmit = () => {
      vars.findAddress.store.keyword = vars.findAddress.keyword
      vars.components['find-address'].refresh()
    }

    methods.findAddressSelect = ({data}) => {
      console.log('findAddressSelect')
      const rowIndex = vars.editKey
        ? vars.components['client-grid'].getRowIndexByKey(vars.editKey)
        : 0
      vars.components['client-grid'].beginUpdate()
      vars.components['client-grid'].cellValue(rowIndex, 'address', data.road)
      vars.components['client-grid'].cellValue(rowIndex, 'address_en', data.eng)
      vars.components['client-grid'].cellValue(rowIndex, 'zip_code', data.zip)
      vars.components['client-grid'].cellValue(rowIndex, 'address_detail', '')
      vars.components['client-grid'].endUpdate()
      vars.findAddress.popup.value = false
    }

    methods.duplicationCorpNum = ({data, value}) => {
      if (!value) return Promise.resolve(true)

      const filter = [['corp_number', '=', value], 'and', ['fk_company_id', '=', authService.getCompanyId()]]
      if (data.id) filter.push('and', ['id', '<>', data.id])
      
      const skip = 0, take = 1
      return clientCheck.load({filter, skip, take})
        .then(response => !response.totalCount)
        .catch(ex => {
          console.error(ex.message)
          return false
        })
    }
    methods.duplicationBusnNum = async function duplicationBusnNum ({data, value}) {
      if (!value) return true
      const filter = [['business_number', '=', value], 'and', ['fk_company_id', '=', authService.getCompanyId()]]
      if (data.id) filter.push('and', ['id', '<>', data.id])
      
      const skip = 0, take = 1
      const {totalCount, data: responseData} = await clientCheck.load({filter, skip, take})
      if (totalCount === 0) return true
      throw Error(`"${responseData[0].name}"에서 이미 사용 중 입니다`)
    }

    methods.validationBusnNum = async function validationBusnNum ({data, value}) {
      if (!value) return true
      
      const getException = (msg) => {
        const exception = new Error()
        exception.self = true
        exception.message = msg
        return exception
      }
    
      try {
        const ret = await findBusinessNumber(value)
        if (!ret.result.length || !ret.result[0].b_stt_cd) throw getException('등록되지 않은 사업자번호 입니다')
        if (ret.result[0].b_stt_cd === '01') return true
        throw getException(`${ret.result[0].b_stt} 상태인 사업자 입니다`)
      }
      catch (ex) {
        if (ex.self) throw ex
        else {
          console.log(ex)
          throw Error('조회에 실패했습니다')
        }
      }
    }

    methods.rowValidating = (evt) => {
      console.log(evt)
      if (evt.brokenRules.length === 1 && evt.brokenRules[0].validationCallback.name === 'validationBusnNum') {
        evt.isValid = true
      }
    }

    methods.excelUpload = () => {
      excelRef.value.click();
    }

    methods.excelFileChange = async ({ target }) => {
      if (!target.files.length) return;

      vars.components['client-grid'].beginCustomLoading(
        '엑셀 데이터를 읽고 있습니다'
      );
      const fd = new FormData();
      fd.append('file', target.files[0]);

      try {
        await apiService.post('', fd);
        vars.components['client-grid'].refresh();
      } catch (ex) {
        if (ex.response.data) {
          notifyError(ex.response.data);
        }
      } finally {
        vars.components['client-grid'].endCustomLoading();
        target.value = null;
      }
    }

    methods.excelSampleDownload = () => {
      saveAs('/api/mes/v1/excel/base/client', '거래처입력양식.xlsx');
    }

    return {
      vars, methods, excelRef,
      grid,
      baseClient, baseClientManager
    }
  },
};
</script>
