<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">검사등록현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <dx-form :form-data="vars.formdata">
            <dx-group-item :col-count="5">
              <dx-simple-item data-field="startdate" editor-type="dxDateBox">
                <dx-label text="생산계획일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="enddate" editor-type="dxDateBox">
                <dx-label text="~" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                item-type="button"
                horizontal-alignment="left"
                :button-options="{
                  icon: 'search',
                  text: '검색',
                  type: 'normal',
                  onClick: methods.searchDateRange,
                }"
              />
            </dx-group-item>
          </dx-form>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status-list')"
          :data-source="qualityTestRegistration"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @row-click="methods.goOrderDetail"
        >
          <dx-column
            data-field="order_number"
            caption="검사번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="order_date"
            caption="검사일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column data-field="client_company" caption="메뉴구분" />
          <dx-column data-field="client_manager" caption="검사구분" />
          <dx-column data-field="order_department" caption="설비명" />
          <dx-column data-field="order_manager" caption="LOTNO." />
          <dx-column data-field="order_type" caption="품목코드" />
          <dx-column data-field="vat_type" caption="품명" />
          <dx-column data-field="payment_terms" caption="규격" />
          <dx-column data-field="delivery_place" caption="검사수량" />
          <dx-column data-field="client_order_number" caption="불량수량" />
          <dx-column data-field="project_number" caption="양품수량" />
          <dx-column data-field="end_user" caption="단위" />
          <dx-column data-field="note" caption="검사성적서" />
          <dx-column data-field="etc" caption="공정명" />
          <dx-column data-field="vat" caption="검사항목" />
          <dx-column data-field="vat" caption="검사규격" />
          <dx-column data-field="vat" caption="공차(+)" />
          <dx-column data-field="vat" caption="공차(-)" />
          <dx-column data-field="vat" caption="검사규격상세" />
          <dx-column data-field="vat" caption="검사방법" />
          <dx-column data-field="vat" caption="작업자" />
          <dx-column data-field="vat" caption="측정값" />
          <dx-column data-field="vat" caption="측정단위" />
          <dx-column data-field="vat" caption="측정장비" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { qualityTestRegistration } from '../../data-source/quality';
import { reactive } from 'vue';
import moment from 'moment';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.formdata = reactive({
      startdate: new Date(),
      enddate: new Date(),
    });
    vars.dataGridInstance = {};

    const methods = {};

    methods.onGridInitialized = (evt, key) => {
      vars.dataGridInstance[key] = evt.component;
    };

    methods.goOrderDetail = ({ data }) => {
      router.push({ path: `/shipment/order/${data.id}` });
    };

    methods.searchDateRange = async () => {
      vars.dataGridInstance['status-list'].filter([
        [
          'order_date',
          '>=',
          moment(vars.formdata.startdate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        ],
        'and',
        [
          'order_date',
          '<=',
          moment(vars.formdata.enddate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        ],
      ]);
    };
    return { vars, methods, qualityTestRegistration };
  },
};
</script>
