<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">부적합조치등록</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <dx-form :form-data="vars.formdata">
            <dx-group-item :col-count="5">
              <dx-simple-item data-field="startdate" editor-type="dxDateBox">
                <dx-label text="작지일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="enddate" editor-type="dxDateBox">
                <dx-label text="~" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                item-type="button"
                horizontal-alignment="left"
                :button-options="{
                  icon: 'search',
                  text: '검색',
                  type: 'normal',
                  onClick: methods.searchDateRange,
                }"
              />
            </dx-group-item>
          </dx-form>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 234px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status-list')"
          :data-source="vars.nonconformanceAction"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @row-click="methods.goDetail"
        >
          <dx-column
            data-field="order_number"
            caption="조치여부"
            :filter-operations="['contain', '=']"
          />
          <dx-column data-field="work_order.order_number" caption="작지번호" />
          <dx-column
            data-field="work_order.order_date"
            caption="작지일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column data-field="lot_no" caption="LOT NO." />
          <dx-column data-field="item.item_code" caption="제품코드" />
          <dx-column data-field="item.item_name" caption="제품명" />
          <dx-column data-field="process_name" caption="공정명" />
          <dx-column data-field="equipment_name" caption="설비명" />
          <dx-column data-field="qa_manager" caption="검사자" />
          <dx-column data-field="bad_type" caption="불량유형" />
          <dx-column data-field="bad_quantity" caption="불량수량" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { getQualityNonconformanceAction } from '../../data-source/quality';
import { reactive } from 'vue';
import moment from 'moment';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.formdata = reactive({
      startdate: new Date(),
      enddate: new Date(),
    });

    vars.nonconformanceAction = getQualityNonconformanceAction([
      { name: 'bad_quantity', op: '>', val: 0 },
    ]);

    vars.dataGridInstance = {};

    const methods = {};

    methods.onGridInitialized = (evt, key) => {
      vars.dataGridInstance[key] = evt.component;
    };

    methods.goDetail = ({ data }) => {
      router.push({ path: `/shipment/order/${data.id}` });
    };

    methods.searchDateRange = async () => {
      vars.dataGridInstance['status-list'].filter([
        [
          'work_order.order_date',
          '>=',
          moment(vars.formdata.startdate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        ],
        'and',
        [
          'work_order.order_date',
          '<=',
          moment(vars.formdata.enddate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        ],
      ]);
    };
    return { vars, methods };
  },
};
</script>
