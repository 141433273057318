<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before"
              ><div class="content-title">통제관리</div></dx-item
            >
          </dx-toolbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';

export default {
  components: {
    DxToolbar,
    DxItem,
  },
  setup() {},
};
</script>
