<template>
  <div>
    <dx-data-grid
      :data-source="shipmentRelease"
      :show-borders="true"
      :column-auto-width="true"
    >
      <dx-column
        data-field="release.release_number"
        caption="출고번호"
        :filter-operations="['contain', '=']"
      />
      <dx-column
        data-field="release.release_date"
        caption="출고일자"
        data-type="date"
        format="yyyy-MM-dd"
        sort-order="desc"
      />
      <dx-column data-field="release_quantity" caption="출고수량" />
      <dx-column data-field="non_invoice" caption="미계산서" />
      <dx-column data-field="release_warehouse" caption="출고창고" />

      <dx-paging :page-size="5" />
    </dx-data-grid>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn, DxPaging } from 'devextreme-vue/data-grid';

import { getShipmentReleaseItem } from '../../data-source/shipment';

export default {
  components: { DxDataGrid, DxColumn, DxPaging },
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const { id } = this.templateData.data;
    return {
      shipmentRelease: this.getTasks(id),
    };
  },
  methods: {
    completedValue(rowData) {
      return rowData.Status === 'Completed';
    },
    getTasks(key) {
      return getShipmentReleaseItem([
        { name: 'fk_order_item_id', op: 'eq', val: key || 0 },
      ]);
    },
  },
};
</script>
