<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">기타입출고</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                disabled: vars.disabled.edit,
                onClick: methods.editEtc,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                disabled: vars.disabled.new,
                onClick: methods.newEtc,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'danger',
                disabled: vars.disabled.delete,
                onClick: methods.deleteItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                disabled: vars.disabled.save,
                onClick: methods.saveEtc,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('stock-etc', '기타입출고조회')
                  ),
                }"
              >
                <dx-label text="기타입출고번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="target_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="입출고일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당부서" :show-colon="false" />
                <dx-required-rule message="담당부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '80px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <div class="mt-2">
          <dx-data-grid
            class="fixed-header-table"
            height="calc(100vh - 286px)"
            :on-initialized="evt => methods.onGridInitialized(evt, 'item1')"
            :remote-operations="true"
            :data-source="vars.stockEtcItem"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :select-text-on-edit-start="true"
            :disabled="vars.disabled.items"
            @saving="methods.onSavingItem"
          >
            <dx-grid-toolbar>
              <dx-grid-item
                name="addRowButton"
                :options="{ onClick: methods.showAddPopup }"
              />
              <dx-grid-item name="saveButton" :visible="!!vars.formData.id" />
              <dx-grid-item name="revertButton" />
              <dx-grid-item name="columnChooserButton" />
            </dx-grid-toolbar>

            <dx-column
              data-field="item_type"
              caption="구분"
              :allow-editing="false"
            />
            <dx-column
              data-field="item_code"
              caption="품목코드"
              width="180"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_name"
              caption="품명"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_standard"
              caption="규격"
              :allow-editing="false"
            />
            <dx-column
              data-field="quantity"
              caption="수량"
              data-type="number"
              format="fixedPoint"
            />
            <dx-column
              data-field="item.unit"
              caption="단위"
              :allow-editing="false"
            />
            <dx-column
              data-field="unit_price"
              caption="단가"
              data-type="number"
              format="currency"
            />
            <dx-column
              data-field="supply_price"
              caption="금액"
              data-type="number"
              format="currency"
              :allow-editing="false"
              :calculate-cell-value="methods.calcSupplyPrice"
            />
            <dx-column data-field="inout_warehouse" caption="입출고창고" />
            <dx-column data-field="inout_type" caption="입출고유형" />
            <dx-column
              data-field="available_stock"
              caption="가용재고"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="current_stock"
              caption="현재고"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column data-field="note" caption="참고" />
            <dx-column data-field="item.asset_type" caption="자산구분" />
            <dx-column data-field="item.main_category" caption="대분류" />
            <dx-column data-field="item.middle_category" caption="중분류" />
            <dx-column data-field="item.sub_category" caption="소분류" />
            <dx-column
              data-field="fk_stock_etc_id"
              caption="기타입출고 FK"
              :visible="false"
            />

            <dx-editing
              :allow-adding="true"
              :allow-updating="true"
              :allow-deleting="true"
              mode="batch"
            />
            <dx-scrolling mode="standard" />
            <dx-column-chooser mode="select" :enabled="true" />
          </dx-data-grid>
        </div>
        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가:</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세:</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액:</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column
            data-field="client_item.client_item_code"
            caption="고객사품번"
          />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
    >
      <template #popup-content>
        <data-stock-etc
          v-if="vars.dlg.finder.key === 'stock-etc'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-order-plan
          v-else-if="vars.dlg.finder.key === 'order-plan'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxToolbar as DxGridToolbar,
  DxItem as DxGridItem,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { confirm, alert } from 'devextreme/ui/dialog';
import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import { stockEtc, getStockEtcItem } from '@/data-source/stock';
import {
  baseCodeLoader,
  baseClient,
  baseItem,
  getBaseItem,
} from '../../data-source/base';
import { shipmentOrderItem } from '../../data-source/shipment';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridOrderPlan from '../../components/purchase/data-order-plan.vue';
import DataStockEtc from '../../components/stock/data-stock-etc';
import authService from '../../auth';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import { loadDepartment, loadEmployee } from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxGridToolbar,
    DxGridItem,
    DxButton,
    DxRequiredRule,
    DataGridClient,
    DataGridOrderPlan,
    DataStockEtc,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.formState = reactive({ readOnly: true });
    vars.grid = { baseItem: null, item1: null };
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.warehouseName = '';
    vars.filter = {
      baseItem: {
        clientId: null,
        warehouseCode: null,
      },
      item1: [{ name: 'fk_stock_etc_id', op: 'eq', val: props.id || 0 }],
    };
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      payment_terms: [],
      type: [],
      vat_type: [],
      department: [],
      employee: [],
      baseItem: null,
    });
    vars.formData = reactive({});

    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    vars.stockEtcItem = getStockEtcItem(vars.filter.item1);

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadDepartment(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.number = '';
          vars.formData.target_date = '';
          vars.formData.department = '';
          vars.formData.manager = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await stockEtc.byKey(id);
        Object.assign(vars.formData, data);

        vars.filter.item1[0].val = vars.formData.id;
        vars.stockEtcItem.defaultFilters = vars.filter.item1;
        if (vars.grid.item1) vars.grid.item1.refresh();

        vars.disabled.edit = false;
        vars.disabled.delete = false;
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
      async addSelectedRows() {
        const grid = vars.grid.item1;
        const rows = await vars.grid.base.getSelectedRowsData();
        for (let row of rows) {
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code);
          grid.cellValue(0, 'item.item_name', row.item_name);
          grid.cellValue(0, 'item.item_standard', row.item_standard);
          grid.cellValue(0, 'order_plan_quantity', row.order_quantity);
          grid.cellValue(0, 'unit_price', row.unit_price);
          grid.cellValue(0, 'item.unit', row.unit);
          grid.cellValue(0, 'request_delivery_date', row.request_delivery_date);
          grid.cellValue(0, 'unordered_quantity', row.order_quantity);
          grid.cellValue(0, 'available_stock', row.available_stock);
          grid.cellValue(0, 'current_stock', row.current_stock);
          grid.cellValue(0, 'client_item_number', row.client_item_number);
          grid.cellValue(0, 'end_user', row.end_user);
          grid.cellValue(0, 'fk_stock_etc_id', vars.formData.id);
        }
        vars.dlg.addItem.show = false;
      },
      generateItemSelectOption(items = [], value = '', searchEnabled = false) {
        return { value, searchEnabled, items };
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddPopup() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.addItem.show = true;
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async newEtc() {
        if (vars.grid.item1) {
          vars.filter.item1[0].val = 0;
          vars.stockEtcItem.defaultFilters = vars.filter.item1;
          vars.grid.item1.refresh();
        }
        if (vars.formData.id) {
          router.replace('/stock/etc');
          vars.formData.id = null;
        }
        setTimeout(() => {
          vars.formData.target_date = new Date();
          vars.formData.department = authService.getDepartmentName();
          vars.formData.manager = authService.getUserName();
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editEtc() {
        if (!vars.formData.id) return;
        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteItem() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await stockEtc.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          router.replace({ path: '/stock/etc' });
          vars.formState.readOnly = true;
        }
      },
      async saveEtc() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await stockEtc.update(vars.formData.id, vars.formData);
            if (vars.grid.item1) await vars.grid.item1.saveEditData();

            notifyInfo('저장되었습니다');
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await stockEtc.insert(vars.formData);
            vars.formData.id = data.id;

            const gridItem1 = vars.grid.item1;
            if (gridItem1 && gridItem1.hasEditData()) {
              await gridItem1.saveEditData();
            }

            router.replace({ path: `/stock/etc/${data.id}` });
            vars.formState.readOnly = false;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 기타입출고번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'stock-etc': {
            router.replace({ path: `/stock/etc/${data.id}` });
            vars.formState.readOnly = true;
            break;
          }
          case 'etc': {
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.warehouseName = selectItem ? selectItem.warehouse.wh_name : '';
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.data.fk_stock_etc_id = vars.formData.id;
            delete element.data.item;
            delete element.data.current_stock;
            delete element.data.available_stock;
          }
        });
      },
      setQuantity(newData, value, currentRowData) {
        newData.quantity = value;
        newData.unit_price = currentRowData.unit_price;
      },
      setUnitPrice(newData, value, currentRowData) {
        newData.quantity = currentRowData.quantity;
        newData.unit_price = value;
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.quantity && rowData.unit_price) {
          supply_price = rowData.quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSummary() {
        let total = sumSupplyPrice(vars.grid.item1, 'quantity', 'unit_price');

        const response = calcPriceSummary(vars.formData.vat_type, total);
        vars.formData.supply_price = response.supply_price;
        vars.formData.vat = response.vat;
        vars.formData.total_price = response.total_price;
      },
      loadBaseCode() {
        return baseCodeLoader(['부가세구분', '입출고구분', '결재조건'])
          .then(response => {
            vars.dataSource.payment_terms = response['결재조건'];
            vars.dataSource.type = response['입출고구분'];
            vars.dataSource.vat_type = response['부가세구분'];
          })
          .then(() => (vars.init.value = true));
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          vars.filter.baseItem.clientId,
          vars.filter.baseItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      baseItem,
      baseClient,
      shipmentOrderItem,
      generateItemButtonOption,
    };
  },
};
</script>
