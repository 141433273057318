<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before">
              <div class="content-title">QR라벨관리</div>
            </dx-item>
          </dx-toolbar>
        </div>

        <div>
          <dx-form :form-data="vars.formdata">
            <dx-group-item :col-count="5">
              <dx-simple-item data-field="startdate" editor-type="dxDateBox">
                <dx-label text="견적일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="enddate" editor-type="dxDateBox">
                <dx-label text="~" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                item-type="button"
                horizontal-alignment="left"
                :button-options="{
                  icon: 'search',
                  text: '검색',
                  type: 'normal',
                  onClick: methods.searchDateRange,
                }"
              />
            </dx-group-item>
          </dx-form>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :data-source="ds.management"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @initialized="evt => methods.initialized(evt, 'qr-grid')">
          <!--dx-column data-field="id" caption="번호" sort-order="desc" /-->
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="standard" caption="규격" />
          <dx-column data-field="lot_no" caption="Lot No" />
          <dx-column data-field="transaction_type" caption="거래구분" />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column data-field="stock_number" caption="입고번호" />
          <dx-column data-field="delivery_date" caption="입고일자" data-type="date" format="yyyy-MM-dd" />
          <dx-column data-field="quantity" caption="수량" />
          <dx-column data-field="unit" caption="단위" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>

    <product-label 
      itemcode="ITM20220126-1-13"
      :rows="[
        {name: '로트번호', text: 'ITM20220126-1-13'},
        {name: '제조회사', text: 'Thingscare'}
      ]"
    />

  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar'
import DxForm, {DxGroupItem, DxSimpleItem, DxButtonItem, DxEmptyItem, DxLabel} from "devextreme-vue/form"
import {DxDataGrid, DxColumn, DxPaging, DxFilterRow, DxColumnChooser} from 'devextreme-vue/data-grid'
import {getQrManagement} from '@/data-source/qr'
import authService from '@/auth'
import {reactive, ref} from 'vue';
import ProductLabel from '@/components/product-label'

export default {
  components: {
    DxToolbar, DxItem,
    DxForm, DxGroupItem, DxSimpleItem, DxButtonItem, DxEmptyItem, DxLabel,
    DxDataGrid, DxColumn, DxPaging, DxFilterRow, DxColumnChooser,
    ProductLabel
  },
  setup() {
    const vars = { profile: reactive({}), disabled: ref(true) }, methods = {}, ds = {}
    vars.origin = {}
    vars.components = {}

    ds.management = getQrManagement({name: 'fk_company_id', op: 'eq', val: authService.getCompanyId()})
    
    methods.initialized = (evt, key) => {
      vars.components[key] = evt.component
    }

    return { vars, methods, ds };
  }
};
</script>