<template>
  <dx-data-grid
    :allow-column-reordering="true"
    :show-borders="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    :focused-row-enabled="true"
    :data-source="vars.stock.item"
    :selection="{ mode: 'single' }"
    @initialized="evt => methods.initialized(evt, 'gridStock')"
    @editor-preparing="methods.onStockEditorPreparing"
    @cell-click="methods.onStockCellClick"
    @init-new-row="methods.onStockInitNewRow"
    @saving="methods.onStockSaving"
  >
    <dx-grid-toolbar>
      <dx-item name="searchPanel" location="before" />
      <dx-item name="addRowButton" template="addStockButton" />
      <dx-item name="saveButton" />
      <dx-item name="revertButton" />
      <dx-item location="after" template="excelSampleDownloadButton" />
      <dx-item location="after" template="excelUploadButton" />
    </dx-grid-toolbar>
    <template #addStockButton>
      <dx-button icon="add" @click="methods.showAddItemPopup" />
    </template>
    <template #excelUploadButton>
      <dx-button icon="upload" @click="methods.excelUpload" />
    </template>
    <template #excelSampleDownloadButton>
      <dx-button icon="download" @click="methods.excelSampleDownload" />
    </template>
    <dx-filter-row :visible="true" />
    <dx-header-filter :visible="false" />
    <dx-search-panel :visible="false" :width="240" placeholder="검색" />
    <dx-column data-field="wh_code" caption="창고구분">
      <dx-lookup
        :data-source="vars.dataSource.warehouse"
        value-expr="wh_code"
        display-expr="wh_name"
      />
      <dx-required-rule />
      <dx-async-rule
        :validation-callback="methods.duplicateCheck"
        message="해당 창고에 동일한 품목이 존재합니다"
      />
      <dx-custom-rule
        :validation-callback="methods.duplicateCheckGrid"
        message="해당 창고에 동일한 품목이 존재합니다"
      />
    </dx-column>
    <dx-column
      data-field="item.asset_type"
      caption="자산구분"
      :allow-editing="false"
    />
    <dx-column
      data-field="item_code"
      caption="품목코드"
      :allow-editing="false"
    />
    <dx-column
      data-field="item.item_name"
      caption="품명"
      :allow-editing="false"
    />
    <dx-column
      data-field="item.item_standard"
      caption="규격"
      :allow-editing="false"
    />
    <dx-column
      data-field="current_stock"
      caption="기초재고수량"
      alignment="right"
      data-type="number"
      format="fixedPoint"
    >
      <dx-required-rule />
    </dx-column>
    <dx-column
      data-field="item_unit_price"
      caption="단가"
      alignment="right"
      data-type="number"
      format="currency"
    />
    <dx-column
      data-field="item_price"
      caption="금액"
      alignment="right"
      data-type="number"
      format="currency"
      :allow-editing="false"
      :calculate-cell-value="methods.calcSupplyPrice"
    />
    <dx-column data-field="etc" caption="비고" />
    <dx-editing
      :allow-updating="true"
      :allow-adding="true"
      :allow-deleting="true"
      :use-icons="true"
      mode="batch"
    />
    <dx-export :enabled="true" :allow-export-selected-data="false" />
    <dx-paging :page-size="10" />
    <dx-pager
      :visible="true"
      :show-page-size-selector="false"
      :show-info="false"
      :show-navigation-buttons="false"
    />
  </dx-data-grid>

  <div id="app-container">
    <dx-popup
      v-model:visible="vars.addItem.visible"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="460"
      :resize-enabled="true"
      @initialized="evt => methods.initialized(evt, 'popupItem')"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.initialized(evt, 'gridItem')"
          :data-source="baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column data-field="asset_type" caption="자산구분">
            <dx-lookup
              :data-source="vars.dataSource.asset_type"
              value-expr="code_name"
              display-expr="code_name"
            />
          </dx-column>
          <dx-column data-field="item_detail" caption="품목설명" />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <input
      hidden
      type="file"
      ref="excelRef"
      accept=".xlsx,.xls"
      @change="methods.excelFileChange"
    />
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxHeaderFilter,
  DxFilterRow,
  DxEditing,
  DxExport,
  DxLookup,
  DxPager,
  DxPaging,
  DxToolbar as DxGridToolbar,
  DxItem,
  DxSelection,
  DxScrolling,
  DxColumnChooser,
  DxRequiredRule,
  DxAsyncRule,
  DxCustomRule,
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { reactive, onMounted, ref } from 'vue';
import { setupBasicStock, getSetupBasicStock } from '../../data-source/setup';
import { baseItem, baseCodeLoader } from '../../data-source/base';
import ApiService from '../../utils/api-service';
import { saveAs } from 'file-saver';
import authService from '../../auth';
import stateStore from '@/utils/state-store';
import { loadWarehouse } from '../../utils/data-loader';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxHeaderFilter,
    DxFilterRow,
    DxEditing,
    DxExport,
    DxLookup,
    DxPopup,
    DxToolbarItem,
    DxPager,
    DxPaging,
    DxGridToolbar,
    DxItem,
    DxButton,
    DxSelection,
    DxScrolling,
    DxColumnChooser,
    DxRequiredRule,
    DxAsyncRule,
    DxCustomRule,
  },
  setup() {
    const excelRef = ref(null);
    const apiService = new ApiService('/api/mes/v1/excel/setup/basic_stock');
    const vars = {};
    vars.component = {};
    vars.stock = reactive({
      grid: null,
      selectedCell: null,
      item: null,
      filter: [
        {
          name: 'fk_company_id',
          op: 'eq',
          val: authService._user.fk_company_id || 0,
        },
      ],
    });
    vars.dataSource = reactive({
      warehouse: [],
      asset_type: [],
    });
    vars.addItem = reactive({
      visible: false,
    });

    const methods = {
      initialized(evt, key) {
        vars.component[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async refreshBasicStock() {
        vars.stock.item = getSetupBasicStock(vars.stock.filter);
      },
      isArrayEmpty(array) {
        if ((array && array.length) < 1) {
          return true;
        }
        return false;
      },
      showAddItemPopup() {
        if (vars.component.gridItem) {
          vars.component.gridItem.deselectAll();
          vars.component.gridItem.refresh().then(function() {
            vars.addItem.visible = true;
          });
        } else {
          vars.addItem.visible = true;
        }
      },
      hideAddItemPopup() {
        vars.addItem.visible = false;
      },
      async addSelectedRows() {
        const rows = await vars.component.gridItem.getSelectedRowsData();

        for (let row of rows) {
          await vars.component.gridStock.addRow();
          vars.component.gridStock.cellValue(0, 'item_code', row.item_code);
          vars.component.gridStock.cellValue(
            0,
            'item.item_name',
            row.item_name
          );
          vars.component.gridStock.cellValue(
            0,
            'item.asset_type',
            row.asset_type
          );
          vars.component.gridStock.cellValue(
            0,
            'item.item_standard',
            row.item_standard
          );
        }
        methods.hideAddItemPopup();
      },
      onStockCellClick(e) {
        vars.stock.selectedCell = e;
        vars.stock.grid = e.component;
      },
      onStockInitNewRow(e) {
        e.data.fk_company_id = authService._user.fk_company_id;
      },
      onStockToolbarPreparing(e) {
        let toolbarItems = e.toolbarOptions.items;
        toolbarItems.forEach(function(item) {
          if (item.name === 'searchPanel') {
            item.location = 'before';
          }
        });
      },
      onStockSaving(e) {
        e.changes.forEach(item => {
          if (item.type != 'remove') {
            delete item.data.item;
            item.data.available_stock = item.data.current_stock;
            item.data.fk_company_id = authService.getCompanyId();
          }
        });
      },
      onStockEditorPreparing(e) {
        if (e.dataField == 'item_code' && e.parentType === 'filterRow') {
          e.editorOptions.buttons = [];
        }
      },
      excelUpload() {
        excelRef.value.click();
      },
      async excelFileChange({ target }) {
        if (!target.files.length) return;

        vars.component.gridStock.beginCustomLoading(
          '엑셀 데이터를 읽고 있습니다'
        );
        const fd = new FormData();
        fd.append('file', target.files[0]);

        try {
          await apiService.post('', fd);
          vars.component.gridStock.refresh();
        } catch (ex) {
          console.error(ex);
          console.log(ex.response);
        } finally {
          vars.component.gridStock.endCustomLoading();
          target.value = null;
        }
      },
      excelSampleDownload() {
        saveAs('/api/mes/v1/excel/setup/basic_stock', '기초재고입력양식.xlsx');
      },
      calcSupplyPrice(rowData) {
        let item_price = 0;
        if (rowData.current_stock && rowData.item_unit_price) {
          item_price = rowData.current_stock * rowData.item_unit_price;
        }
        rowData.item_price = item_price;
        return item_price;
      },
      duplicateCheck(params) {
        return setupBasicStock
          .load({
            filter: [
              ['wh_code', '=', params.data.wh_code],
              ['item_code', '=', params.data.item_code],
            ],
          })
          .then(({ data }) => {
            if (data.length > 0) {
              if (params.data.id && params.data.id == data[0].id) {
                return true;
              }
              return false;
            }
            return true;
          });
      },
      duplicateCheckGrid(e) {
        let count = 0;
        const rows = vars.component.gridStock.getVisibleRows();
        for (let i = 0; i < rows.length; i++) {
          const rowData = rows[i].data;
          if (
            rowData.item_code == e.data.item_code &&
            rowData.wh_code == e.data.wh_code
          ) {
            count += 1;
          }
        }
        if (count > 1) {
          return false;
        }
        return true;
      },
    };

    onMounted(() => {
      methods.refreshBasicStock();
      loadWarehouse(vars.dataSource);
      baseCodeLoader(['자산구분'], authService.getCompanyId()).then(
        response => {
          vars.dataSource.asset_type = response['자산구분'];
        }
      );
    });

    return {
      vars,
      excelRef,
      methods,
      baseItem,
    };
  },
};
</script>

<style></style>
