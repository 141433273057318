<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">견적</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '복사',
                type: 'copy',
                disabled: vars.disabled.copy,
                onClick: methods.copyQuote,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                disabled: vars.disabled.edit,
                onClick: methods.editQuote,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                onClick: methods.newQuote,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'danger',
                disabled: vars.disabled.delete,
                onClick: methods.deleteQuote,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                disabled: vars.disabled.save,
                onClick: methods.saveQuote,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="5">
            <dx-group-item>
              <dx-simple-item
                data-field="quote_number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('quote', '견적조회')
                  ),
                }"
              >
                <dx-label text="견적번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="quote_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="견적일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="client_company"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.client_company,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  searchEnabled: 'true',
                  onValueChanged: methods.onClientChanged,
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('client', '고객조회')
                  ),
                  ...vars.formState,
                }"
              >
                <dx-label text="고객업체" :show-colon="false" />
                <dx-required-rule message="고객업체를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="client_manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.client_manager,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  acceptCustomValue: true,
                  disabled: vars.disabled.clientManager,
                  ...vars.formState,
                }"
              >
                <dx-label text="업체담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="quote_department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="견적부서" :show-colon="false" />
                <dx-required-rule message="견적부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="quote_manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="견적담당자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="quote_type"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.quote_type,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="견적구분" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="vat_type"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.vat_type,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="부가세구분" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="payment_terms"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.payment_terms,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  acceptCustomValue: true,
                  ...vars.formState,
                }"
              >
                <dx-label text="결재조건" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="delivery_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="납품기한" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="delivery_place"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.delivery_place,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  acceptCustomValue: true,
                  ...vars.formState,
                }"
              >
                <dx-label text="납품장소" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="previous_quote_number"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="이전견적번호" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="project_number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('project', '프로젝트조회')
                  ),
                  ...vars.formState,
                }"
              >
                <dx-label text="프로젝트번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="end_user"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.client_company,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  searchEnabled: 'true',
                  onValueChanged: methods.onEndUserChanged,
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('enduser', '고객조회')
                  ),
                  ...vars.formState,
                }"
              >
                <dx-label text="EndUser" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '174px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <dx-tab-panel :animation-enabled="false" :swipe-enabled="false">
          <dx-item title="견적품목찾기">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 470px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'item1')
                  "
                  :remote-operations="true"
                  :data-source="vars.shipmentQuoteItem1"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :disabled="vars.disabled.items"
                  @saving="methods.onSavingItem"
                  @focused-cell-changed="methods.onFocusedCellChanged"
                >
                  <dx-grid-toolbar>
                    <dx-grid-item
                      name="addRowButton"
                      :options="{ onClick: methods.showAddPopup }"
                    />
                    <dx-grid-item
                      name="saveButton"
                      :visible="!!vars.formData.id"
                    />
                    <dx-grid-item name="revertButton" />
                    <dx-grid-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_name"
                    caption="품명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_standard"
                    caption="규격"
                    :allow-editing="false"
                  >
                    <!-- <dx-lookup :data-source="lookupDataSourceConfig" value-expr="id" display-expr="name" /> -->
                  </dx-column>
                  <dx-column
                    data-field="quote_quantity"
                    caption="견적수량"
                    data-type="number"
                    format="fixedPoint"
                    :set-cell-value="methods.setQuantity"
                  />
                  <dx-column
                    data-field="unit_price"
                    caption="단가"
                    data-type="number"
                    format="currency"
                    :set-cell-value="methods.setUnitPrice"
                  />
                  <dx-column
                    data-field="item.unit"
                    caption="단위"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="supply_price"
                    caption="공급가"
                    data-type="number"
                    format="currency"
                    :allow-editing="false"
                    :calculate-cell-value="methods.calcSupplyPrice"
                  />
                  <dx-column
                    data-field="request_delivery_date"
                    caption="요청납기"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="client_item_number"
                    caption="고객사품번"
                  />
                  <dx-column
                    data-field="item.item_detail"
                    caption="품목설명"
                    :allow-editing="false"
                  />
                  <dx-column data-field="note" caption="참고사항" />
                  <dx-column
                    data-field="project_number"
                    caption="프로젝트번호"
                    :editor-options="{
                      ...generateItemButtonOption(
                        'search',
                        methods.createFindPopupFn('project2', '프로젝트조회')
                      ),
                    }"
                  />
                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="견적품목입력">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 470px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'item2')
                  "
                  :remote-operations="true"
                  :data-source="vars.shipmentQuoteItem2"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :disabled="vars.disabled.items"
                  @saving="methods.onSavingItem"
                  @init-new-row="methods.onItem2Insert"
                >
                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                  />
                  <dx-column data-field="item_name" caption="품명" />
                  <dx-column data-field="item_standard" caption="규격" />
                  <dx-column
                    data-field="quote_quantity"
                    caption="견적수량"
                    data-type="number"
                    format="fixedPoint"
                    :set-cell-value="methods.setQuantity"
                  />
                  <dx-column
                    data-field="unit_price"
                    caption="단가"
                    data-type="number"
                    format="currency"
                    :set-cell-value="methods.setUnitPrice"
                  />
                  <dx-column data-field="unit" caption="단위" />
                  <dx-column
                    data-field="supply_price"
                    caption="공급가"
                    data-type="number"
                    format="currency"
                    :allow-editing="false"
                    :calculate-cell-value="methods.calcSupplyPrice"
                  />
                  <dx-column
                    data-field="request_delivery_date"
                    caption="요청납기"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                  />
                  <dx-column
                    data-field="client_item_number"
                    caption="고객사품번"
                  />
                  <dx-column data-field="item_detail" caption="품목설명" />
                  <dx-column data-field="note" caption="참고사항" />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>

        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가:</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세:</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액:</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="견적품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="460"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'item-popup')"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column
            data-field="client_item.client_item_code"
            caption="고객사품번"
          />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
      @initialized="evt => methods.onGridInitialized(evt, 'find-popup')"
    >
      <template #popup-content>
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-client
          v-else-if="vars.dlg.finder.key === 'enduser'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-quote
          v-else-if="vars.dlg.finder.key === 'quote'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-project
          v-else-if="vars.dlg.finder.key === 'project'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-project
          v-else-if="vars.dlg.finder.key === 'project2'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="440"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxToolbar as DxGridToolbar,
  DxItem as DxGridItem,
} from 'devextreme-vue/data-grid';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { confirm, alert } from 'devextreme/ui/dialog';
import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import {
  shipmentQuote,
  getShipmentQuoteItem,
} from '../../data-source/shipment';
import { baseCodeLoader, getBaseItem } from '../../data-source/base';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridQuote from '../../components/shipment/data-quote.vue';
import DataGridProject from '../../components/project/data-project.vue';
import authService from '../../auth';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import {
  loadDepartment,
  loadEmployee,
  loadClient,
  loadClientManager,
} from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxRequiredRule,
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxTabPanel,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DataGridClient,
    DataGridQuote,
    DataGridProject,
    DxGridToolbar,
    DxGridItem,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.formState = reactive({ readOnly: true });
    vars.grid = { baseItem: null, item1: null, item2: null };
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.items = reactive({
      focusedCell1: null,
    });
    vars.filter = {
      baseItem: {
        clientId: null,
        warehouseCode: null,
      },
      item1: [
        { name: 'fk_quote_id', op: 'eq', val: props.id || 0 },
        { name: 'type', op: 'eq', val: 1 },
      ],
      item2: [
        { name: 'fk_quote_id', op: 'eq', val: props.id || 0 },
        { name: 'type', op: 'eq', val: 2 },
      ],
    };
    vars.disabled = reactive({
      copy: true,
      edit: true,
      delete: true,
      save: true,
      items: true,
      manger: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      payment_terms: [],
      quote_type: [],
      vat_type: [],
      delivery_place: [],
      client_company: [],
      client_manager: [],
      department: [],
      employee: [],
      baseItem: null,
    });
    vars.formData = reactive({});

    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    vars.shipmentQuoteItem1 = getShipmentQuoteItem(vars.filter.item1);
    vars.shipmentQuoteItem2 = getShipmentQuoteItem(vars.filter.item2);
    vars.optionDataPromise = undefined;

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadDepartment(vars.dataSource);
      await loadClient(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          methods.clearFormData();

          vars.disabled.copy = true;
          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await shipmentQuote.byKey(id);
        Object.assign(vars.formData, data);

        vars.filter.item1[0].val = vars.formData.id;
        vars.shipmentQuoteItem1.defaultFilters = vars.filter.item1;
        if (vars.grid.item1) vars.grid.item1.refresh();

        vars.filter.item2[0].val = vars.formData.id;
        vars.shipmentQuoteItem2.defaultFilters = vars.filter.item2;
        if (vars.grid.item2) vars.grid.item2.refresh();

        vars.disabled.copy = false;
        vars.disabled.edit = false;
        vars.disabled.delete = false;
        if (vars.formData.client_company && vars.formData.quote_department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
      clearFormData() {
        vars.formData.id = null;
        vars.formData.quote_number = '';
        vars.formData.quote_date = '';
        vars.formData.client_company = '';
        vars.formData.client_manager = '';
        vars.formData.quote_department = '';
        vars.formData.quote_manager = '';
        vars.formData.quote_type = '';
        vars.formData.vat_type = '';
        vars.formData.payment_terms = '';
        vars.formData.delivery_date = '';
        vars.formData.delivery_place = '';
        vars.formData.previous_quote_number = '';
        vars.formData.project_number = '';
        vars.formData.end_user = '';
        vars.formData.note = '';
        vars.formData.etc = '';
        vars.formData.supply_price = 0;
        vars.formData.vat = 0;
        vars.formData.total_price = 0;
        vars.formData.fk_company_id = authService.getCompanyId();
      },
      async addSelectedRows() {
        const grid = vars.grid.item1;
        const rows = await vars.grid.baseItem.getSelectedRowsData();
        for (let row of rows) {
          const basicStock = row.basic_stock;
          const clientItem = row.client_item;
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item_standard); // 규격
          grid.cellValue(0, 'quote_quantity', 0); // 견적수량
          grid.cellValue(0, 'unit_price', row.sales_price); // 단가
          grid.cellValue(0, 'item.unit', row.unit); // 단위
          grid.cellValue(0, 'supply_price', row.supply_price); // 공급가
          grid.cellValue(0, 'request_delivery_date', null); // 요청납기
          if (basicStock) {
            grid.cellValue(0, 'available_stock', basicStock.available_stock); // 가용재고
            grid.cellValue(0, 'current_stock', basicStock.current_stock); // 현재고
          } else {
            grid.cellValue(0, 'available_stock', 0); // 가용재고
            grid.cellValue(0, 'current_stock', 0); // 현재고
          }
          if (clientItem) {
            grid.cellValue(
              0,
              'client_item_number',
              clientItem.client_item_code
            ); // 고객사품번
          } else {
            grid.cellValue(0, 'client_item_number', null); // 고객사품번
          }
          grid.cellValue(0, 'item.item_detail', row.item_detail); // 품목설명
          grid.cellValue(0, 'note', ''); // 참고사항
          grid.cellValue(0, 'type', 1); // 타입
          grid.cellValue(0, 'project_number', vars.formData.project_number); // 프로젝트번호
        }
        vars.dlg.addItem.show = false;
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddPopup() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.addItem.show = true;
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      onItem2Insert(evt) {
        evt.data.type = 2;
        evt.data.fk_quote_id = vars.formData.id;
      },
      newQuote() {
        if (vars.grid.item1) {
          vars.filter.item1[0].val = 0;
          vars.shipmentQuoteItem1.defaultFilters = vars.filter.item1;
          vars.grid.item1.refresh();
        }
        if (vars.grid.item2) {
          vars.filter.item2[0].val = 0;
          vars.shipmentQuoteItem2.defaultFilters = vars.filter.item2;
          vars.grid.item2.refresh();
        }
        if (vars.formData.id) {
          router.replace('/shipment/quote');
          methods.clearFormData();
        }

        setTimeout(() => {
          let quote_type = '';
          if (vars.dataSource.quote_type.length > 0) {
            quote_type = vars.dataSource.quote_type[0].code_name;
          }
          let vat_type = '';
          if (vars.dataSource.vat_type.length > 0) {
            vat_type = vars.dataSource.vat_type[0].code_name;
          }
          let payment_terms = '';
          if (vars.dataSource.payment_terms.length > 0) {
            payment_terms = vars.dataSource.payment_terms[0].code_name;
          }
          let delivery_place = '';
          if (vars.dataSource.delivery_place.length > 0) {
            delivery_place = vars.dataSource.delivery_place[0].code_name;
          }

          vars.formData.quote_date = new Date();
          vars.formData.quote_department = authService.getDepartmentName();
          vars.formData.quote_manager = authService.getUserName();
          vars.formData.quote_type = quote_type;
          vars.formData.vat_type = vat_type;
          vars.formData.payment_terms = payment_terms;
          vars.formData.delivery_place = delivery_place;

          vars.formState.readOnly = false;
        }, 200);
      },
      async copyQuote() {
        const result = await confirm('견적을 복사하시겠습니까?', '복사 확인');
        if (result) {
          let params = Object.assign({}, vars.formData);
          params.id = null;
          params.quote_number = '';
          params.quote_date = new Date();
          params.previous_quote_number = vars.formData.quote_number;

          let { data } = await shipmentQuote.insert(params);

          await alert('복사가 완료되었습니다', '복사 확인');
          router.replace({ path: `/shipment/quote/${data.id}` });
          vars.formState.readOnly = false;
        }
      },
      async deleteQuote() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await shipmentQuote.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          router.replace({ path: `/shipment/quote` });
          vars.formState.readOnly = true;
        }
      },
      async editQuote() {
        if (!vars.formData.id) return;
        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async saveQuote() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await shipmentQuote.update(vars.formData.id, vars.formData);

            const gridItem1 = vars.grid.item1;
            if (gridItem1) await gridItem1.saveEditData();
            const gridItem2 = vars.grid.item2;
            if (gridItem2) await gridItem2.saveEditData();

            notifyInfo('저장되었습니다');
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await shipmentQuote.insert(vars.formData);
            vars.formData.id = data.id;

            const gridItem1 = vars.grid.item1;
            if (gridItem1 && gridItem1.hasEditData()) {
              await gridItem1.saveEditData();
            }
            const gridItem2 = vars.grid.item2;
            if (gridItem2 && gridItem2.hasEditData()) {
              await gridItem2.saveEditData();
            }

            router.replace({ path: `/shipment/quote/${data.id}` });
            vars.formState.readOnly = false;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 견적번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'quote': {
            // Object.assign(vars.formData, data)
            router.replace({ path: `/shipment/quote/${data.id}` });
            vars.formState.readOnly = true;
            break;
          }
          case 'client': {
            vars.formData.client_company = data.name;
            break;
          }
          case 'enduser': {
            vars.formData.end_user = data.name;
            break;
          }
          case 'project': {
            vars.formData.project_number = data.project_number;
            break;
          }
          case 'project2': {
            vars.items.focusedCell1.component.cellValue(
              vars.items.focusedCell1.rowIndex,
              vars.items.focusedCell1.columnIndex,
              data.project_number
            );
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      async onClientChanged(e) {
        const client = e.component.option('selectedItem');
        vars.filter.baseItem.clientId = client ? client.id : null;
        methods.loadBaseItem();

        if (!client) {
          vars.disabled.copy = true;
          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.tradeYn = false;
          vars.disabled.clientManager = true;
          vars.formData.client_manager = null;
          vars.dataSource.client_manager = [];
        } else {
          if (vars.formState.readOnly || vars.disabled.tradeYn) {
            loadClientManager(vars.dataSource, client.name);
            vars.disabled.tradeYn = false;
            vars.disabled.clientManager = false;
          } else {
            let isSelect = true;
            if (client.trade_yn) {
              isSelect = await confirm(
                '거래중지 업체입니다. 계속 진행하시겠습니까?',
                '고객업체'
              );
            }
            if (isSelect) {
              loadClientManager(vars.dataSource, client.name);
              vars.disabled.clientManager = false;
              vars.formData.client_manager = null;
            } else {
              vars.formData.client_company = e.previousValue;
              vars.disabled.tradeYn = true;
            }
          }
        }
        methods.checkPossibleSave();
      },
      async onEndUserChanged(e) {
        if (vars.formState.readOnly) {
          return;
        }
        const client = e.component.option('selectedItem');
        if (client) {
          if (client.trade_yn && !vars.disabled.tradeYn) {
            const result = await confirm(
              '거래중지 업체입니다. 계속 진행하시겠습니까?',
              'EndUser'
            );
            if (!result) {
              vars.formData.end_user = e.previousValue;
              vars.disabled.tradeYn = true;
              return;
            }
          }
        }
        vars.disabled.tradeYn = false;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.quote_manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();
      },
      onFocusedCellChanged(e) {
        vars.items.focusedCell1 = e;
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.data.fk_quote_id = vars.formData.id;
            delete element.data.item;
          }
        });
      },
      setQuantity(newData, value, currentRowData) {
        newData.quote_quantity = value;
        newData.unit_price = currentRowData.unit_price;
      },
      setUnitPrice(newData, value, currentRowData) {
        newData.quote_quantity = currentRowData.quote_quantity;
        newData.unit_price = value;
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.quote_quantity && rowData.unit_price) {
          supply_price = rowData.quote_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSummary() {
        let total =
          sumSupplyPrice(vars.grid.item1, 'quote_quantity', 'unit_price') +
          sumSupplyPrice(vars.grid.item2, 'quote_quantity', 'unit_price');

        const response = calcPriceSummary(vars.formData.vat_type, total);
        vars.formData.supply_price = response.supply_price;
        vars.formData.vat = response.vat;
        vars.formData.total_price = response.total_price;
      },
      loadBaseCode() {
        return baseCodeLoader(
          ['부가세구분', '견적구분', '결재조건', '납품장소'],
          authService.getCompanyId()
        )
          .then(response => {
            vars.dataSource.payment_terms = response['결재조건'];
            vars.dataSource.quote_type = response['견적구분'];
            vars.dataSource.vat_type = response['부가세구분'];
            vars.dataSource.delivery_place = response['납품장소'];
          })
          .then(() => (vars.init.value = true));
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          vars.filter.baseItem.clientId,
          vars.filter.baseItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (vars.formData.client_company && vars.formData.quote_department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      generateItemButtonOption,
    };
  },
};
</script>
