<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">생산계획</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                disabled: vars.disabled.edit,
                onClick: methods.editPlan,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                disabled: vars.disabled.new,
                onClick: methods.newPlan,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'danger',
                disabled: vars.disabled.delete,
                onClick: methods.deleteItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                disabled: vars.disabled.save,
                onClick: methods.savePlan,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('plan', '생산계획조회')
                  ),
                }"
              >
                <dx-label text="생산계획번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="target_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="계획일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당부서" :show-colon="false" />
                <dx-required-rule message="담당부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '80px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <div class="mt-2">
          <dx-data-grid
            class="fixed-header-table"
            height="calc(100vh - 324px)"
            :on-initialized="evt => methods.onGridInitialized(evt, 'planItem')"
            :remote-operations="true"
            :data-source="vars.dataSource.planItem"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :select-text-on-edit-start="true"
            :disabled="vars.disabled.items"
            @saving="methods.onSavingItem"
          >
            <dx-grid-toolbar>
              <dx-item location="before" template="addOrder" />
              <dx-grid-item
                name="addRowButton"
                :options="{ onClick: methods.showAddBaseItem }"
              />
              <dx-grid-item name="saveButton" :visible="!!vars.formData.id" />
              <dx-grid-item name="revertButton" />
              <dx-grid-item name="columnChooserButton" />
            </dx-grid-toolbar>
            <template #addOrder>
              <dx-button
                icon="add"
                text="수주에서 가져오기"
                @click="methods.showAddOrderItem"
              />
            </template>

            <dx-column
              data-field="item_code"
              caption="품목코드"
              width="180"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_name"
              caption="품명"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_standard"
              caption="규격"
              :allow-editing="false"
            >
            </dx-column>
            <dx-column
              data-field="production_plan_quantity"
              caption="생산계획수량"
              data-type="number"
              format="fixedPoint"
            />
            <dx-column
              data-field="unit_price"
              caption="단가"
              data-type="number"
              format="currency"
            />
            <dx-column
              data-field="item.unit"
              caption="단위"
              :allow-editing="false"
            />
            <dx-column
              data-field="supply_price"
              caption="공급가"
              data-type="number"
              format="currency"
              :allow-editing="false"
              :calculate-cell-value="methods.calcSupplyPrice"
            />
            <dx-column
              data-field="request_delivery_date"
              caption="요청납기"
              data-type="date"
              format="yyyy-MM-dd"
            />
            <dx-column
              data-field="unordered_quantity"
              caption="미작지수량"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="available_stock"
              caption="가용재고"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="current_stock"
              caption="현재고"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column data-field="order_number" caption="수주번호" />
            <dx-column data-field="project_number" caption="프로젝트번호" />
            <dx-column data-field="client_company" caption="고객업체" />
            <dx-column data-field="client_item_number" caption="고객사품번" />
            <dx-column
              data-field="end_user"
              caption="실수요자"
              :allow-editing="false"
            />
            <dx-column data-field="item.item_detail" caption="품목설명" />
            <dx-column
              data-field="fk_order_item_id"
              caption="수주품목"
              :visible="false"
            />
            <dx-column
              data-field="fk_production_plan_id"
              caption="생산계획"
              :visible="false"
            />

            <dx-editing
              :allow-adding="true"
              :allow-updating="true"
              :allow-deleting="true"
              mode="batch"
            />
            <dx-scrolling mode="standard" />
            <dx-column-chooser mode="select" :enabled="true" />
          </dx-data-grid>
        </div>
        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가:</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세:</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액:</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.orderItem.show"
      content-template="popup-content"
      title="수주품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addOrderItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'orderItem')"
          :data-source="vars.dataSource.orderItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="false"
        >
          <dx-column data-field="order.order_number" caption="수주번호" />
          <dx-column data-field="order.client_company" caption="고객사" />
          <dx-column
            data-field="order.order_date"
            caption="수주일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column data-field="order_quantity" caption="수주수량" />
          <dx-column data-field="not_shipped" caption="미출고수량" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="current_stock" caption="현재고" />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.baseItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addBaseItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column
            data-field="client_item.client_item_code"
            caption="고객사품번"
          />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
      @showing="vars.dlg.finder.onShowing"
    >
      <template #popup-content>
        <data-grid-plan
          ref="popupSearchPlan"
          v-if="vars.dlg.finder.key === 'plan'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxToolbar as DxGridToolbar,
  DxItem as DxGridItem,
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { getShipmentOrderItem } from '../../data-source/shipment';
import { baseCodeLoader, getBaseItem } from '../../data-source/base';
import { producePlan, getProducePlanItem } from '../../data-source/produce';
import DataGridPlan from '../../components/produce/data-plan.vue';
import stateStore from '@/utils/state-store';
import authService from '../../auth';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import { loadDepartment, loadEmployee } from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxRequiredRule,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxGridToolbar,
    DxGridItem,
    DxButton,
    DataGridPlan,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    const popupSearchPlan = ref(null);
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.formState = reactive({ readOnly: true });
    vars.grid = { baseItem: null, orderItem: null, planItem: null };
    vars.dlg.baseItem = reactive({ show: false });
    vars.dlg.orderItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.filter = reactive({
      baseItem: {
        clientId: null,
        warehouseCode: null,
      },
      orderItem: {
        clientCompany: null,
        warehouseCode: null,
      },
      planItem: [
        { name: 'fk_production_plan_id', op: 'eq', val: props.id || 0 },
      ],
    });
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      paymentTerms: [],
      orderType: [],
      vatType: [],
      department: [],
      employee: [],
      planItem: null,
      baseItem: null,
      orderItem: null,
    });
    vars.formData = reactive({});
    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    vars.dataSource.planItem = getProducePlanItem(vars.filter.planItem);

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadDepartment(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.number = '';
          vars.formData.target_date = '';
          vars.formData.department = '';
          vars.formData.manager = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.supply_price = 0;
          vars.formData.vat = 0;
          vars.formData.total_price = 0;
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await producePlan.byKey(id);
        Object.assign(vars.formData, data);

        vars.filter.planItem[0].val = vars.formData.id;
        vars.dataSource.planItem.defaultFilters = vars.filter.planItem;
        if (vars.grid.planItem) vars.grid.planItem.refresh();

        vars.disabled.edit = false;
        vars.disabled.delete = false;
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
      async addOrderItem() {
        const rows = await vars.grid.orderItem.getSelectedRowsData();
        for (let row of rows) {
          const grid = vars.grid.planItem;
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
          grid.cellValue(0, 'production_plan_quantity', row.not_shipped); // 생산계획수량
          grid.cellValue(0, 'unit_price', row.unit_price); // 단가
          grid.cellValue(0, 'item.unit', row.item.unit); // 단위
          grid.cellValue(0, 'supply_price', row.supply_price); // 공급가
          grid.cellValue(0, 'request_delivery_date', row.request_delivery_date); // 요청납기
          grid.cellValue(0, 'unordered_quantity', row.not_shipped); // 미작지수량
          grid.cellValue(0, 'available_stock', row.available_stock); // 가용재고
          grid.cellValue(0, 'current_stock', row.current_stock); // 현재고
          grid.cellValue(0, 'order_number', row.order.order_number); // 수주번호
          grid.cellValue(0, 'project_number', row.project_number); // 프로젝트번호
          grid.cellValue(0, 'client_company', row.order.client_company); // 고객업체
          grid.cellValue(0, 'client_item_number', row.client_item_number); // 고객사품번
          grid.cellValue(0, 'end_user', row.order.end_user); // 실수요자
          grid.cellValue(0, 'item.item_detail', row.item.item_detail); // 품목설명
          grid.cellValue(0, 'fk_order_item_id', row.id); // 수요품목 아이디
          grid.cellValue(0, 'fk_production_plan_id', vars.formData.id); // 생산계획 아이디
        }
        vars.dlg.baseItem.show = false;
        vars.dlg.orderItem.show = false;
      },
      async addBaseItem() {
        const grid = vars.grid.planItem;
        const rows = await vars.grid.baseItem.getSelectedRowsData();
        for (let row of rows) {
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
          grid.cellValue(0, 'production_plan_quantity', 0); // 생산계획수량
          grid.cellValue(0, 'unit_price', row.item.sales_price); // 단가
          grid.cellValue(0, 'item.unit', row.item.unit); // 단위
          grid.cellValue(0, 'supply_price', 0); // 공급가
          grid.cellValue(0, 'request_delivery_date', null); // 요청납기
          grid.cellValue(0, 'unordered_quantity', 0); // 미작지수량
          grid.cellValue(0, 'available_stock', row.available_stock); // 가용재고
          grid.cellValue(0, 'current_stock', row.current_stock); // 현재고
          grid.cellValue(0, 'order_number', null); // 수주번호
          grid.cellValue(0, 'project_number', null); // 프로젝트번호
          grid.cellValue(0, 'client_company', null); // 고객업체
          grid.cellValue(0, 'client_item_number', null); // 고객사품번
          grid.cellValue(0, 'end_user', null); // 실수요자
          grid.cellValue(0, 'item.item_detail', row.item.item_detail); // 품목설명
          grid.cellValue(0, 'fk_order_item_id', null); // 수요품목 아이디
          grid.cellValue(0, 'fk_production_plan_id', vars.formData.id); // 생산계획 아이디
        }
        vars.dlg.baseItem.show = false;
        vars.dlg.orderItem.show = false;
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
          vars.dlg.finder.onShowing = () => {
            if (vars.dlg.finder.key === 'plan') {
              popupSearchPlan.value.methods.refresh();
            }
          };
        };
      },
      showAddBaseItem() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.baseItem.show = true;
      },
      showAddOrderItem() {
        if (vars.grid.orderItem) {
          vars.grid.orderItem.clearSelection();
          vars.grid.orderItem.refresh();
        }
        vars.dlg.orderItem.show = true;
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async newPlan() {
        if (vars.grid.planItem) {
          vars.filter.planItem[0].val = 0;
          vars.dataSource.planItem.defaultFilters = vars.filter.planItem;
          vars.grid.planItem.refresh();
        }
        if (vars.formData.id) {
          router.replace('/produce/plan');
          vars.formData.id = null;
        }
        setTimeout(() => {
          vars.formData.target_date = new Date();
          vars.formData.department = authService.getDepartmentName();
          vars.formData.manager = authService.getUserName();
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editPlan() {
        if (!vars.formData.id) return;
        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteItem() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await producePlan.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          router.replace({ path: '/produce/plan' });
          vars.formState.readOnly = true;
        }
      },
      async savePlan() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await producePlan.update(vars.formData.id, vars.formData);
            if (vars.grid.planItem) await vars.grid.planItem.saveEditData();

            notifyInfo('저장되었습니다');
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await producePlan.insert(vars.formData);
            vars.formData.id = data.id;

            const planItem = vars.grid.planItem;
            if (planItem && planItem.hasEditData()) {
              await planItem.saveEditData();
            }

            router.replace({ path: `/produce/plan/${data.id}` });
            vars.formState.readOnly = false;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 생산계획번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'plan': {
            router.replace({ path: `/produce/plan/${data.id}` });
            vars.formState.readOnly = true;
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }
        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.warehouseName = selectItem ? selectItem.warehouse.wh_name : '';
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();

        vars.filter.orderItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadOrderItem();
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.fk_production_plan_id = vars.formData.id;
            delete element.data.item;
            delete element.data.current_stock;
            delete element.data.available_stock;
          }
        });
      },
      loadBaseCode() {
        return baseCodeLoader(['부가세구분', '수주구분', '결재조건'])
          .then(response => {
            vars.dataSource.paymentTerms = response['결재조건'];
            vars.dataSource.orderType = response['수주구분'];
            vars.dataSource.vatType = response['부가세구분'];
          })
          .then(() => (vars.init.value = true));
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.production_plan_quantity && rowData.unit_price) {
          supply_price = rowData.production_plan_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSummary() {
        let total = sumSupplyPrice(
          vars.grid.planItem,
          'production_plan_quantity',
          'unit_price'
        );

        const response = calcPriceSummary(vars.formData.vat_type, total);
        vars.formData.supply_price = response.supply_price;
        vars.formData.vat = response.vat;
        vars.formData.total_price = response.total_price;
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          vars.filter.baseItem.clientId,
          vars.filter.baseItem.warehouseCode
        );
      },
      loadOrderItem() {
        vars.dataSource.orderItem = getShipmentOrderItem(
          [
            {
              name: 'order',
              op: 'has',
              val: {
                name: 'client_company',
                op: 'eq',
                val: vars.filter.orderItem.clientCompany,
              },
            },
          ],
          vars.filter.orderItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      popupSearchPlan,
      generateItemButtonOption,
    };
  },
};
</script>
