import defaultLayout from '@/layouts/side-nav-outer-toolbar';

import D1 from '@/views/stock/1';
import Etc from '@/views/stock/etc';
import EtcStatus from '@/views/stock/etc-status';
import MoveRequest from '@/views/stock/move-request';
import StockStatus from '@/views/stock/stock-status';
import ReceivePaymentStatus from '@/views/stock/receive-payment-status';
import MoveRelease from '@/views/stock/move-release';
import D8 from '@/views/stock/8';

export default [
  {
    path: '/stock/1',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: D1,
  },
  {
    path: '/stock/etc',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Etc,
  },
  {
    path: '/stock/etc/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Etc,
    props: true
  },
  {
    path: '/stock/etc-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: EtcStatus,
  },
  {
    path: '/stock/move-request',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: MoveRequest,
  },
  {
    path: '/stock/move-request/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: MoveRequest,
    props: true
  },
  {
    path: '/stock/stock-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: StockStatus,
  },
  {
    path: '/stock/receive-payment-status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: ReceivePaymentStatus,
  },
  {
    path: '/stock/move-release',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: MoveRelease,
  },
  {
    path: '/stock/8',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: D8,
  },
];
