<template>
  <dx-data-grid
    :column-auto-width="true"
    :allow-column-reordering="true"
    :show-borders="true"
    :data-source="setupCodeChangeHistory"
  >
    <dx-header-filter :visible="true" />
    <dx-search-panel :visible="true" :width="240" placeholder="검색" />
    <dx-column data-field="previous_code" caption="변경전 코드" />
    <dx-column data-field="after_code" caption="변경후 코드" />
    <dx-column data-field="name" caption="품명" />
    <dx-column data-field="change_reason" caption="변경사유" />
    <dx-column data-field="created" caption="변경일자" />
    <dx-column data-field="fk_user_id" caption="변경담당자" />
    <dx-export :enabled="true" :allow-export-selected-data="false" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxHeaderFilter,
  DxExport,
} from 'devextreme-vue/data-grid';
//import { reactive } from 'vue';
import { setupCodeChangeHistory } from '../../data-source/setup';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxHeaderFilter,
    DxExport,
  },
  setup() {
    const generateItemButtonOption = (
      icon,
      callback,
      location = 'after',
      options = {}
    ) => {
      let buttonOptions = { stylingMode: 'text', icon, onClick: callback };
      return {
        ...options,
        buttons: [{ name: icon, location, options: buttonOptions }],
      };
    };

    return {
      setupCodeChangeHistory,
      generateItemButtonOption,
    };
  },
};
</script>

<style></style>
