<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">발주·입고 현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">발주일자</span>
            <dx-date-box v-model:value="vars.formdata.startdate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formdata.enddate" />
            <span class="search-tab"></span>
            <dx-check-box
              text="미입고유무"
              v-model:value="vars.formdata.not_shipped"
            />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="
            evt => methods.onGridInitialized(evt, vars.gridName.status)
          "
          :data-source="purchaseOrderItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @cell-click="methods.goOrderDetail"
        >
          <dx-column
            data-field="order.order_number"
            caption="발주번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="order.order_date"
            caption="발주일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column data-field="order.order_manager" caption="발주담당자" />
          <dx-column data-field="order.order_type" caption="발주구분" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="standard" caption="규격" />
          <dx-column data-field="order_quantity" caption="발주수량" />
          <dx-column data-field="unit_price" caption="단가" />
          <dx-column data-field="unit" caption="단위" />
          <dx-column
            data-field="supply_price"
            caption="공급가"
            format="currency"
          />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="not_shipped" caption="미입고" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="current_stock" caption="현재고" />
          <dx-column data-field="order_plan_number" caption="발주계획번호" />
          <dx-column data-field="client_item_number" caption="공급사품번" />
          <dx-column data-field="item_detail" caption="품목설명" />
          <dx-column data-field="project_number" caption="프로젝트번호" />
          <dx-column data-field="note" caption="참고사항" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />

          <dx-master-detail :enabled="true" template="masterDetailTemplate" />
          <template #masterDetailTemplate="{ data: order }">
            <order-receiving-detail :template-data="order" />
          </template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxColumnChooser,
  DxMasterDetail,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { purchaseOrderItem } from '../../data-source/purchase';
import { reactive } from 'vue';
import stateStore from '@/utils/state-store';
import moment from 'moment';

import OrderReceivingDetail from './order-receiving-detail.vue';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
    DxColumnChooser,
    DxMasterDetail,
    DxDateBox,
    DxCheckBox,
    DxButton,
    OrderReceivingDetail,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    let startdate = new Date();
    startdate.setDate(startdate.getDate() - 7);
    vars.formdata = reactive({
      startdate: startdate,
      enddate: new Date(),
      not_shipped: false,
    });
    vars.gridName = {
      status: 'grid',
    };
    vars.dataGridInstance = {};

    const methods = {
      onGridInitialized(evt, key) {
        vars.dataGridInstance[key] = evt.component;
        stateStore.bind(key, evt.component);

        methods.searchDateRange();
      },
      goOrderDetail(e) {
        if (e.columnIndex != 0) {
          router.push({ path: `/purchase/order/${e.data.order.id}` });
        }
      },
      async searchDateRange() {
        let filter = [
          [
            'order.order_date',
            '>=',
            moment(vars.formdata.startdate)
              .startOf('day')
              .format('YYYY-MM-DD 00:00:00'),
          ],
          'and',
          [
            'order.order_date',
            '<=',
            moment(vars.formdata.enddate)
              .endOf('day')
              .format('YYYY-MM-DD 23:59:59'),
          ],
        ];
        if (vars.formdata.not_shipped) {
          filter = [
            [
              'order.order_date',
              '>=',
              moment(vars.formdata.startdate)
                .startOf('day')
                .format('YYYY-MM-DD 00:00:00'),
            ],
            'and',
            [
              'order.order_date',
              '<=',
              moment(vars.formdata.enddate)
                .endOf('day')
                .format('YYYY-MM-DD 23:59:59'),
            ],
            'and',
            ['not_shipped', '>', 0],
          ];
        }
        vars.dataGridInstance[vars.gridName.status].filter(filter);
      },
    };

    return { vars, methods, purchaseOrderItem };
  },
};
</script>
<style>
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 20px;
}
.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  > .dx-datagrid-group-space,
.dx-datagrid-rowsview
  .dx-master-detail-row:not(.dx-datagrid-edit-form)
  .dx-master-detail-cell {
  background-color: transparent;
}
</style>
