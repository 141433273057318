<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">재고조회</div></dx-item
            >
          </dx-toolbar>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 200px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status-list')"
          :data-source="stockEtc"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @row-click="methods.goOrderDetail"
        >
          <dx-column
            data-field="asset_type"
            caption="자산구분"
            :filter-operations="['contain', '=']"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column data-field="current_stock" caption="현재고" />
          <dx-column data-field="order_type" caption="할당재고" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="payment_terms" caption="안전재고" />
          <dx-column
            data-field="base_code_main.code_name"
            caption="품목대분류"
          />
          <dx-column
            data-field="base_code_middle.code_name"
            caption="품목중분류"
          />
          <dx-column
            data-field="base_code_sub.code_name"
            caption="품목소분류"
          />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { stockEtc } from '../../data-source/stock';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.dataGridInstance = {};

    const methods = {};

    methods.onGridInitialized = (evt, key) => {
      vars.dataGridInstance[key] = evt.component;
    };

    methods.goOrderDetail = ({ data }) => {
      router.push({ path: `/shipment/order/${data.id}` });
    };

    return { vars, methods, stockEtc };
  },
};
</script>
