<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">출고현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <div class="search-status">
            <span class="search-title">출고일자</span>
            <dx-date-box v-model:value="vars.formdata.startdate" />
            <span class="search-bar">~</span>
            <dx-date-box v-model:value="vars.formdata.enddate" />
            <span class="search-tab"></span>
            <dx-button
              text="검색"
              icon="search"
              @click="methods.searchDateRange()"
            />
          </div>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 230px)"
          :on-initialized="
            evt => methods.onGridInitialized(evt, vars.gridName.status)
          "
          :data-source="shipmentReleaseItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @row-click="methods.goReleaseDetail"
        >
          <dx-column
            data-field="release.release_number"
            caption="출고번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="release.release_date"
            caption="출고일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column data-field="release.client_company" caption="고객업체" />
          <dx-column
            data-field="release.release_department"
            caption="견적부서"
          />
          <dx-column
            data-field="release.release_manager"
            caption="출고담당자"
          />
          <dx-column data-field="release.release_type" caption="출고구분" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column
            data-field="order_quantity"
            caption="수주수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="release_quantity"
            caption="출고수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column
            data-field="supply_price"
            caption="공급가"
            format="currency"
          />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item.main_category" caption="품목대분류" />
          <dx-column data-field="item.middle_category" caption="품목중분류" />
          <dx-column data-field="item.sub_category" caption="품목소분류" />
          <dx-column data-field="order_number" caption="수주번호" />
          <dx-column data-field="client_item_number" caption="고객사품번" />
          <dx-column data-field="item.item_detail" caption="품목설명" />
          <dx-column data-field="note" caption="참고사항" />
          <dx-column data-field="closing_yn" caption="종결" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
          <dx-column-chooser mode="select" :enabled="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxColumnChooser,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxCheckBox from 'devextreme-vue/check-box';
import DxButton from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { shipmentReleaseItem } from '../../data-source/shipment';
import { reactive } from 'vue';
import moment from 'moment';
import stateStore from '@/utils/state-store';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxDateBox,
    DxCheckBox,
    DxButton,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    let startdate = new Date();
    startdate.setDate(startdate.getDate() - 7);
    vars.formdata = reactive({
      startdate: startdate,
      enddate: new Date(),
    });
    vars.gridName = {
      status: 'shipment-release-status-grid',
    };
    vars.dataGridInstance = {};

    const methods = {
      onGridInitialized(evt, key) {
        vars.dataGridInstance[key] = evt.component;
        stateStore.bind(key, evt.component);

        methods.searchDateRange();
      },
      goQuoteDetail({ data }) {
        router.push({ path: `/shipment/release/${data.release.id}` });
      },
      async searchDateRange() {
        vars.dataGridInstance[vars.gridName.status].filter([
          [
            'release.release_date',
            '>=',
            moment(vars.formdata.startdate)
              .startOf('day')
              .format('YYYY-MM-DD 00:00:00'),
          ],
          'and',
          [
            'release.release_date',
            '<=',
            moment(vars.formdata.enddate)
              .endOf('day')
              .format('YYYY-MM-DD 23:59:59'),
          ],
        ]);
      },
    };

    return { vars, methods, shipmentReleaseItem };
  },
};
</script>
