import defaultLayout from '@/layouts/side-nav-outer-toolbar';
import registration from '@/views/project/registration';
import notice from '@/views/project/notice';
import schedule from '@/views/project/schedule';
import resource from '@/views/project/resource';
import G5 from '@/views/project/5';
import G6 from '@/views/project/6';
import G7 from '@/views/project/7';
import G8 from '@/views/project/8';

export default [
  {
    path: '/project/registration',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: registration,
  },
  {
    path: '/project/registration/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: registration,
    props: true,
  },
  {
    path: '/project/notice',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: notice,
  },
  {
    path: '/project/schedule',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: schedule,
  },
  {
    path: '/project/resource',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: resource,
  },
  {
    path: '/project/5',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: G5,
  },
  {
    path: '/project/6',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: G6,
  },
  {
    path: '/project/7',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: G7,
  },
  {
    path: '/project/8',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: G8,
  },
];
