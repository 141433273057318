<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">부적합조치현황</div></dx-item
            >
          </dx-toolbar>
        </div>
        <div>
          <dx-form :form-data="vars.formdata">
            <dx-group-item :col-count="5">
              <dx-simple-item data-field="startdate" editor-type="dxDateBox">
                <dx-label text="생산계획일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item data-field="enddate" editor-type="dxDateBox">
                <dx-label text="~" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                item-type="button"
                horizontal-alignment="left"
                :button-options="{
                  icon: 'search',
                  text: '검색',
                  type: 'normal',
                  onClick: methods.searchDateRange,
                }"
              />
            </dx-group-item>
          </dx-form>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 234px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status-list')"
          :data-source="qualityNonconformanceActionItem"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @row-click="methods.goOrderDetail"
        >
          <dx-column
            data-field="non_conformance_action.work_order.order_number"
            caption="작지번호"
            :filter-operations="['contain', '=']"
          />
          <dx-column
            data-field="non_conformance_action.work_order.order_date"
            caption="작지일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column
            data-field="non_conformance_action.lot_no"
            caption="LOT No"
          />
          <dx-column
            data-field="non_conformance_action.item.item_code"
            caption="제품코드"
          />
          <dx-column
            data-field="non_conformance_action.item.item_name"
            caption="제품명"
          />
          <dx-column
            data-field="non_conformance_action.process_name"
            caption="공정명"
          />
          <dx-column
            data-field="non_conformance_action.equipment_name"
            caption="설비명"
          />
          <dx-column
            data-field="non_conformance_action.qa_manager"
            caption="검사자"
          />
          <dx-column
            data-field="non_conformance_action.bad_type"
            caption="불량유형"
          />
          <dx-column data-field="" caption="작지수량" />
          <dx-column data-field="client_order_number" caption="불량수량" />
          <dx-column data-field="project_number" caption="조치일자" />
          <dx-column data-field="end_user" caption="조치담당자" />
          <dx-column data-field="note" caption="조치수량" />
          <dx-column data-field="etc" caption="조치내용" />
          <dx-column data-field="vat" caption="적요" />
          <dx-column data-field="vat" caption="조치확인자" />
          <dx-column data-field="vat" caption="확인일자" />
          <dx-column data-field="vat" caption="자산구분" />
          <dx-column data-field="vat" caption="대분류" />
          <dx-column data-field="vat" caption="중분류" />
          <dx-column data-field="vat" caption="소분류" />

          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { qualityNonconformanceActionItem } from '../../data-source/quality';
import { reactive } from 'vue';
import moment from 'moment';

export default {
  components: {
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxToolbar,
    DxItem,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.formdata = reactive({
      startdate: new Date(),
      enddate: new Date(),
    });
    vars.dataGridInstance = {};

    const methods = {};

    methods.onGridInitialized = (evt, key) => {
      vars.dataGridInstance[key] = evt.component;
    };

    methods.goOrderDetail = ({ data }) => {
      router.push({ path: `/shipment/order/${data.id}` });
    };

    methods.searchDateRange = async () => {
      vars.dataGridInstance['status-list'].filter([
        [
          'order_date',
          '>=',
          moment(vars.formdata.startdate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        ],
        'and',
        [
          'order_date',
          '<=',
          moment(vars.formdata.enddate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
        ],
      ]);
    };
    return { vars, methods, qualityNonconformanceActionItem };
  },
};
</script>
