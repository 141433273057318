<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">소요량계산</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                disabled: vars.disabled.edit,
                onClick: methods.editRequirement,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                disabled: vars.disabled.new,
                onClick: methods.newRequirement,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'danger',
                disabled: vars.disabled.delete,
                onClick: methods.deleteItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                disabled: vars.disabled.save,
                onClick: methods.saveRequirement,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn(
                      'measure-requirement',
                      '소요량계산 조회'
                    )
                  ),
                }"
              >
                <dx-label text="소요량 산출번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="target_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당부서" :show-colon="false" />
                <dx-required-rule message="담당부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '80px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <dx-tab-panel
          :animation-enabled="false"
          :swipe-enabled="false"
          v-model:selected-index="vars.selectedTabIndex"
        >
          <dx-item title="생산계획">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 416px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'measureItem1')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.measureItem1"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :disabled="vars.disabled.items"
                >
                  <dx-grid-toolbar>
                    <dx-item location="before" template="addFromPlan" />
                    <dx-item name="addRowButton" template="addItem" />
                    <dx-item name="saveButton" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #addFromPlan>
                    <dx-button
                      icon="add"
                      text="생산계획에서 가져오기"
                      @click="methods.showAddPlanItem"
                    />
                  </template>
                  <template #addItem>
                    <dx-button icon="add" @click="methods.showAddBaseItem" />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_name"
                    caption="품명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_standard"
                    caption="규격"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="production_plan_quantity"
                    caption="생산계획수량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="unit_price"
                    caption="단가"
                    data-type="number"
                    format="currency"
                  />
                  <dx-column
                    data-field="item.unit"
                    caption="단위"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="supply_price"
                    caption="공급가"
                    data-type="number"
                    format="currency"
                    :allow-editing="false"
                    :calculate-cell-value="methods.calcSupplyPrice"
                  />
                  <dx-column
                    data-field="request_delivery_date"
                    caption="요청납기"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="unordered_quantity"
                    caption="미작지수량"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column data-field="order_number" caption="수주번호" />
                  <dx-column data-field="client_company" caption="고객업체" />
                  <dx-column
                    data-field="client_item_number"
                    caption="고객사품번"
                  />
                  <dx-column
                    data-field="end_user"
                    caption="실수요자"
                    :allow-editing="false"
                  />
                  <dx-column data-field="item.item_detail" caption="품목설명" />
                  <dx-column
                    data-field="fk_measure_requirement_id"
                    :visible="false"
                  />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="소요량계산">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 416px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'measureItem2')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.measureItem2"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :disabled="vars.disabled.items"
                  @init-new-row="methods.onItem2Insert"
                >
                  <dx-grid-toolbar>
                    <dx-item location="before" template="addFromPlan" />
                    <dx-item location="before" template="calculate" />
                    <dx-item location="before" template="exportPlan" />
                    <dx-item name="saveButton" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #addFromPlan>
                    <dx-button
                      icon="add"
                      text="생산계획에서 가져오기"
                      @click="methods.showAddPlanItem"
                    />
                  </template>
                  <template #calculate>
                    <dx-button
                      icon="formula"
                      text="소요량 재계산하기"
                      @click="methods.showAddPlanItem"
                    />
                  </template>
                  <template #exportPlan>
                    <dx-button
                      icon="export"
                      text="발주계획으로 보내기"
                      @click="methods.showAddPlanItem"
                    />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                  />
                  <dx-column data-field="item.item_name" caption="품명" />
                  <dx-column data-field="item.item_standard" caption="규격" />
                  <dx-column data-field="item.asset_type" caption="자산구분" />
                  <dx-column
                    data-field="item.safety_stock"
                    caption="안전재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column data-field="moq" caption="MOQ" />
                  <dx-column
                    data-field="requirement_quantity"
                    caption="소요량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="order_plan_quantity"
                    caption="발주계획량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="unordered_plan_quantity"
                    caption="미발주 계획수량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column data-field="unit_price" caption="단가" />
                  <dx-column
                    data-field="supply_price"
                    caption="공급가"
                    data-type="number"
                    format="currency"
                    :allow-editing="false"
                    :calculate-cell-value="methods.calcSupplyPrice2"
                  />
                  <dx-column data-field="item.main_category" caption="대분류" />
                  <dx-column
                    data-field="item.middle_category"
                    caption="중분류"
                  />
                  <dx-column data-field="item.sub_category" caption="소분류" />
                  <dx-column data-field="client_company" caption="공급업체" />
                  <dx-column
                    data-field="delivery_date"
                    caption="납기일자"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>

        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addPlanItem.show"
      content-template="popup-content"
      title="생산계획에서 가져오기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addPlanItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'planItem')"
          :data-source="producePlanItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="false"
        >
          <dx-column
            data-field="production_plan.number"
            caption="생산계획번호"
          />
          <dx-column data-field="client_company" caption="고객업체" />
          <dx-column
            data-field="request_delivery_date"
            caption="요청납기"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="standard" caption="규격" />
          <dx-column data-field="unit" caption="단위" />
          <dx-column data-field="order_quantity" caption="자산구분" />
          <dx-column
            data-field="production_plan_quantity"
            caption="생산계획수량"
          />
          <dx-column data-field="unordered_quantity" caption="미작지수량" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="current_stock" caption="현재고" />

          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addBaseItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="standard" caption="규격" />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="price" caption="금액" format="currency" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="current_stock" caption="현재고" />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
    >
      <template #popup-content>
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-measure-requirement
          v-else-if="vars.dlg.finder.key === 'measure-requirement'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxToolbar as DxGridToolbar,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { confirm, alert } from 'devextreme/ui/dialog';

import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import {
  measureRequirement,
  getMeasureRequirementItem1,
  getMeasureRequirementItem2,
} from '../../data-source/produce';
import { baseCodeLoader, getBaseItem } from '../../data-source/base';
import { producePlanItem, getProducePlanItem } from '../../data-source/produce';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridOrderPlan from '../../components/purchase/data-order-plan.vue';
import DataGridMeasureRequirement from '../../components/produce/data-measure-requirement.vue';
import authService from '../../auth';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import { loadDepartment, loadEmployee } from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxRequiredRule,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxGridToolbar,
    DxButton,
    DataGridClient,
    DataGridOrderPlan,
    DataGridMeasureRequirement,
    DxTabPanel,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.selectedTabIndex = 0;
    vars.formState = reactive({ readOnly: true });
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.addPlanItem = reactive({ show: false });
    vars.grid = {
      baseItem: null,
      measureItem1: null,
      measureItem2: null,
      planItem: null,
    };
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.filter = reactive({
      baseItem: {
        clientId: null,
        warehouseCode: null,
      },
      planItem: {
        warehouseCode: null,
      },
      measureItem1: [
        { name: 'fk_measure_requirement_id', op: 'eq', val: props.id || 0 },
      ],
      measureItem2: [
        { name: 'fk_measure_requirement_id', op: 'eq', val: props.id || 0 },
      ],
    });
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      payment_terms: [],
      order_type: [],
      vat_type: [],
      delivery_place: [],
      client_company: [],
      client_manager: [],
      department: [],
      employee: [],
      measureItem1: null,
      measureItem2: null,
      baseItem: null,
      planItem: null,
    });
    vars.formData = reactive({});

    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    vars.dataSource.measureItem1 = getMeasureRequirementItem1(
      vars.filter.measureItem1
    );
    vars.dataSource.measureItem2 = getMeasureRequirementItem2(
      vars.filter.measureItem2
    );

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadDepartment(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.number = '';
          vars.formData.target_date = '';
          vars.formData.department = '';
          vars.formData.manager = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.supply_price = 0;
          vars.formData.vat = 0;
          vars.formData.total_price = 0;
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await measureRequirement.byKey(id);
        Object.assign(vars.formData, data);

        vars.filter.measureItem1[0].val = vars.formData.id;
        vars.dataSource.measureItem1.defaultFilters = vars.filter.measureItem1;
        if (vars.grid.measureItem1) vars.grid.measureItem1.refresh();

        vars.filter.measureItem2[0].val = vars.formData.id;
        vars.dataSource.measureItem2.defaultFilters = vars.filter.measureItem2;
        if (vars.grid.measureItem2) vars.grid.measureItem2.refresh();

        vars.disabled.edit = false;
        vars.disabled.delete = false;
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
      async addPlanItem() {
        const rows = [...(await vars.grid.planItem.getSelectedRowsData())];
        for (let row of rows) {
          let grid = null;
          if (vars.selectedTabIndex == 0) {
            // 생산계획
            grid = vars.grid.measureItem1;
            await grid.addRow();
            grid.cellValue(
              0,
              'production_plan_quantity',
              row.production_plan_quantity
            ); // 생산계획수량
            grid.cellValue(0, 'item.unit', row.item.unit); // 단위
            grid.cellValue(
              0,
              'request_delivery_date',
              row.request_delivery_date
            ); // 요청납기
            grid.cellValue(0, 'unordered_quantity', row.unordered_quantity); // 미작지수량
            grid.cellValue(0, 'available_stock', 0); // 가용재고
            grid.cellValue(0, 'current_stock', 0); // 현재고
            grid.cellValue(0, 'order_number', row.order_number); // 수주번호
            grid.cellValue(0, 'client_company', row.client_company); // 고객업체
            grid.cellValue(0, 'client_item_number', row.client_item_number); // 고객사품번
            grid.cellValue(0, 'end_user', row.end_user); // 실수요자
            grid.cellValue(0, 'item.item_detail', row.item.item_detail); // 품목설명
          } else {
            // 소요량계산
            grid = vars.grid.measureItem2;
            await grid.addRow();
            grid.cellValue(0, 'item.asset_type', row.item.asset_type); // 자산구분
            grid.cellValue(0, 'item.safety_stock', row.item.safety_stock); // 안전재고
            grid.cellValue(0, 'item.moq', row.item.moq); // MOQ
            grid.cellValue(0, 'requirement_quantity', 0); // 소요량
            grid.cellValue(0, 'order_plan_quantity', 0); // 발주계획량
            grid.cellValue(0, 'unordered_plan_quantity', 0); // 미발주 계획수량
            grid.cellValue(0, 'available_stock', 0); // 가용재고
            grid.cellValue(0, 'current_stock', 0); // 현재고
            grid.cellValue(0, 'item.main_category', row.item.main_category); // 대분류
            grid.cellValue(0, 'item.middle_category', row.item.middle_category); // 중분류
            grid.cellValue(0, 'item.sub_category', row.item.sub_category); // 소분류
            grid.cellValue(0, 'client_company', row.client_company); // 공급업체
            grid.cellValue(0, 'delivery_date', row.request_delivery_date); // 납기일자
          }
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item.teim_standard); // 규격
          grid.cellValue(0, 'unit_price', row.unit_price); // 단가
          grid.cellValue(0, 'supply_price', row.supply_price); // 공급가
          grid.cellValue(0, 'fk_measure_requirement_id', vars.formData.id); // 소요량계산 FK
        }
        vars.dlg.addItem.show = false;
        vars.dlg.addPlanItem.show = false;
      },
      async addBaseItem() {
        const rows = [...(await vars.grid.baseItem.getSelectedRowsData())];
        for (let row of rows) {
          const grid = vars.grid.measureItem1;
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.teim_standard); // 규격
          grid.cellValue(0, 'production_plan_quantity', 0); // 생산계획수량
          grid.cellValue(0, 'unit_price', row.unit_price); // 단가
          grid.cellValue(0, 'unit', row.unit); // 단위
          grid.cellValue(0, 'supply_price', 0); // 공급가
          grid.cellValue(0, 'request_delivery_date', null); // 요청납기
          grid.cellValue(0, 'unordered_quantity', null); // 미작지수량
          grid.cellValue(0, 'available_stock', row.available_stock); // 가용재고
          grid.cellValue(0, 'current_stock', row.current_stock); // 현재고
          grid.cellValue(0, 'order_number', null); // 수주번호
          grid.cellValue(0, 'client_company', null); // 고객업체
          grid.cellValue(0, 'client_item_number', null); // 고객사품번
          grid.cellValue(0, 'end_user', null); // 실수요자
          grid.cellValue(0, 'item.item_detail', row.item_detail); // 품목설명
          grid.cellValue(0, 'fk_measure_requirement_id', vars.formData.id); // 소요량계산 FK
        }
        vars.dlg.addItem.show = false;
        vars.dlg.addPlanItem.show = false;
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddBaseItem() {
        vars.dlg.addItem.show = true;
      },
      showAddPlanItem() {
        vars.dlg.addPlanItem.show = true;
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async newRequirement() {
        if (vars.grid.measureItem1) {
          vars.filter.measureItem1[0].val = 0;
          vars.dataSource.measureItem1.defaultFilters =
            vars.filter.measureItem1;
          vars.grid.measureItem1.refresh();
        }
        if (vars.grid.measureItem2) {
          vars.filter.measureItem2[0].val = 0;
          vars.dataSource.measureItem2.defaultFilters =
            vars.filter.measureItem2;
          vars.grid.measureItem2.refresh();
        }
        if (vars.formData.id) {
          router.replace('/produce/measure-requirement');
          vars.formData.id = null;
        }
        setTimeout(() => {
          vars.formData.target_date = new Date();
          vars.formData.department = authService.getDepartmentName();
          vars.formData.manager = authService.getUserName();
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editRequirement() {
        if (!vars.formData.id) return;
        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteItem() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await measureRequirement.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          router.replace({ path: '/produce/measure-requirement' });
          vars.formState.readOnly = true;
        }
      },
      async saveRequirement() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await measureRequirement.update(vars.formData.id, vars.formData);
            if (vars.grid.measureItem1)
              await vars.grid.measureItem1.saveEditData();
            if (vars.grid.measureItem2)
              await vars.grid.measureItem2.saveEditData();

            notifyInfo('저장되었습니다');
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await measureRequirement.insert(vars.formData);
            vars.formData.id = data.id;

            const measureItem1 = vars.grid.measureItem1;
            if (measureItem1 && measureItem1.hasEditData()) {
              await measureItem1.saveEditData();
            }
            const measureItem2 = vars.grid.measureItem2;
            if (measureItem2 && measureItem2.hasEditData()) {
              await measureItem2.saveEditData();
            }

            router.replace({ path: `/produce/measure-requirement/${data.id}` });
            vars.formState.readOnly = false;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 소요량산출번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'measure-requirement': {
            router.replace({
              path: `/produce/measure-requirement/${data.id}`,
            });
            vars.formState.readOnly = true;
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.warehouseName = selectItem ? selectItem.warehouse.wh_name : '';
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();

        vars.filter.planItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadPlanItem();
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.fk_measure_requirement_id = vars.formData.id;
            delete element.data.item;
            delete element.data.current_stock;
            delete element.data.available_stock;
          }
        });
      },
      loadBaseCode() {
        return baseCodeLoader([
          '부가세구분',
          '수주구분',
          '결재조건',
          '납품장소',
        ])
          .then(response => {
            vars.dataSource.payment_terms = [...response['결재조건']];
            vars.dataSource.order_type = [...response['수주구분']];
            vars.dataSource.vat_type = [...response['부가세구분']];
            vars.dataSource.delivery_place = [...response['납품장소']];
          })
          .then(() => (vars.init.value = true));
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.production_plan_quantity && rowData.unit_price) {
          supply_price = rowData.production_plan_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSupplyPrice2(rowData) {
        let supply_price = 0;
        if (rowData.order_plan_quantity && rowData.unit_price) {
          supply_price = rowData.order_plan_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSummary() {
        let total =
          sumSupplyPrice(
            vars.grid.measureItem1,
            'production_plan_quantity',
            'unit_price'
          ) +
          sumSupplyPrice(
            vars.grid.measureItem2,
            'order_plan_quantity',
            'unit_price'
          );

        const response = calcPriceSummary(vars.formData.vat_type, total);
        vars.formData.supply_price = response.supply_price;
        vars.formData.vat = response.vat;
        vars.formData.total_price = response.total_price;
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          vars.filter.baseItem.clientId,
          vars.filter.baseItem.warehouseCode
        );
      },
      loadPlanItem() {
        vars.dataSource.planItem = getProducePlanItem(
          null,
          vars.filter.planItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      generateItemButtonOption,
      producePlanItem,
    };
  },
};
</script>
