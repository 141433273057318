import RestlessStore from './restless-store';

const projectRegistration = new RestlessStore(
  '/api/mes/v1/project/project_management'
);
const projectGuarantee = new RestlessStore(
  '/api/mes/v1/project/project_guarantee'
);
const projectInsurance = new RestlessStore(
  '/api/mes/v1/project/project_insurance'
);
const projectCollect = new RestlessStore('/api/mes/v1/project/project_collect');
const projectNotice = new RestlessStore('/api/mes/v1/project/project_notice');
const projectAttachment = new RestlessStore(
  '/api/mes/v1/project/project_attachment'
);
const projectSchedule = new RestlessStore('/api/mes/v1/project/project_schedule')

const getProjectRegistration = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/project/project_management');
  instance.defaultFilters = defaultFilters;
  return instance;
};
const getProjectGuarantee = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/project/project_guarantee');
  instance.defaultFilters = defaultFilters;
  return instance;
};
const getProjectInsurance = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/project/project_insurance');
  instance.defaultFilters = defaultFilters;
  return instance;
};
const getProjectCollect = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/project/project_collect');
  instance.defaultFilters = defaultFilters;
  return instance;
};
const getProjectAttachment = defaultFilters => {
  const instance = new RestlessStore('/api/mes/v1/project/project_attachment');
  instance.defaultFilters = defaultFilters;
  return instance;
};

export {
  projectRegistration,
  projectGuarantee,
  projectInsurance,
  projectCollect,
  projectAttachment,
  projectSchedule,
  projectNotice,
  getProjectRegistration,
  getProjectGuarantee,
  getProjectInsurance,
  getProjectCollect,
  getProjectAttachment,
};
