<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">측정장비등록</div></dx-item
            >
          </dx-toolbar>
        </div>
      </div>
      <div class="dx-card responsive-paddings mt-1">
        <dx-data-grid
          height="calc(100vh - 200px)"
          :on-initialized="evt => methods.onGridInitialized(evt, 'status-list')"
          :data-source="qualityMeasuringEquipment"
          :show-borders="true"
          :column-auto-width="true"
          :remote-operations="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
          @row-click="methods.goOrderDetail"
        >
          <dx-column
            data-field="order_number"
            caption="장비코드"
            :filter-operations="['contain', '=']"
          />
          <dx-column data-field="client_company" caption="장비명" />
          <dx-column data-field="client_manager" caption="제조원" />
          <dx-column
            data-field="purchase_date"
            caption="구입일자"
            data-type="date"
            format="yyyy-MM-dd"
            sort-order="desc"
          />
          <dx-column
            data-field="delivery_date"
            caption="교정기한"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="order_department" caption="교정주기" />
          <dx-column data-field="order_manager" caption="알람기준일" />
          <dx-column data-field="order_type" caption="모델명" />
          <dx-column data-field="vat_type" caption="시리얼번호" />
          <dx-column data-field="payment_terms" caption="적요" />

          <dx-editing
            mode="batch"
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            :use-icons="true"
          />
          <dx-export :enabled="true" :allow-export-selected-data="false" />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxEditing,
  DxExport,
} from 'devextreme-vue/data-grid';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import { useRouter } from 'vue-router';
import { qualityMeasuringEquipment } from '../../data-source/quality';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxEditing,
    DxToolbar,
    DxItem,
    DxExport,
  },
  setup() {
    const router = useRouter();
    const vars = {};
    vars.dataGridInstance = {};

    const methods = {};

    methods.onGridInitialized = (evt, key) => {
      vars.dataGridInstance[key] = evt.component;
    };

    methods.goOrderDetail = ({ data }) => {
      router.push({ path: `/shipment/order/${data.id}` });
    };

    return { vars, methods, qualityMeasuringEquipment };
  },
};
</script>
