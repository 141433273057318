<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>

      <dx-item location="before">
        <template #default>
          <img v-if="logo" class="logo-image" :src="`data:;base64,${logo}`"/>
          <div v-else class="header-title dx-toolbar-label">{{title}}</div>
        </template>
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem">
        <template #default>
          <dx-drop-down-button
            :items="profileSettings.menu"
            :text="userName"
            :drop-down-options="{width: 140}"
            icon="user"
            display-expr="name"
            key-expr="id"
            styling-mode="text"
            @item-click="profileSettings.onMenuClick"
          />
        </template>
      </dx-item>
      
    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxDropDownButton from 'devextreme-vue/drop-down-button';
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const email = ref("");
    const userName = ref("");
    const logo = ref("");

    auth.getUser().then((e) => {
      email.value = e.data.email
      userName.value = e.data.user_name
      logo.value = e.logo
    });

    const profileSettings = {
      menu: [
        { id: 'profile', name: '내정보수정', icon: 'user' },
        { id: 'logout', name: '로그아웃', icon: 'runner' }
      ],
      onMenuClick ({itemData}) {
        switch (itemData.id) {
          case 'profile': {
            router.push({ path: "/profile", query: {redirect: route.path} });
            break
          }
          case 'logout': {
            auth.logOut();
            router.push({ path: "/login-form", query: {redirect: route.path} });
            break
          }
        }
      }
    }

    return {
      email, logo, userName,
      profileSettings
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    DxDropDownButton
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.logo-image {
  height: 36px;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.header-toolbar {
  &.dx-toolbar {
    padding: 10px 0;
    background-color: $header-panel-background-color;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
</style>
