const generateItemButtonOption = (
  icon,
  callback,
  location = 'after',
  options = {}
) => {
  let buttonOptions = { stylingMode: 'text', icon, onClick: callback };
  return {
    ...options,
    buttons: [{ name: icon, location, options: buttonOptions }],
  };
};

const sumSupplyPrice = (grid, quantityName, priceName) => {
  let total = 0;
  if (grid) {
    const rows = grid.getVisibleRows();
    if (rows.length > 0) {
      rows.forEach(elem => {
        total += elem.data[quantityName] * elem.data[priceName];
      });
    }
  }
  return total;
};

const calcPriceSummary = (vat_type, total) => {
  let supply_price = 0;
  let vat = 0;
  let total_price = 0;

  switch (vat_type) {
    case '별도': {
      supply_price = total;
      vat = Math.floor(total * 0.1);
      total_price = total + vat;
      break;
    }
    case '포함': {
      total_price = total;
      vat = Math.floor(total * 0.1);
      supply_price = total - vat;
      break;
    }
    case '영세': {
      total_price = total;
      vat = 0;
      supply_price = total;
      break;
    }
    default: {
      total_price = total;
      vat = 0;
      supply_price = total;
    }
  }
  return { supply_price, vat, total_price };
};

export { generateItemButtonOption, sumSupplyPrice, calcPriceSummary };
