<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">작업지시</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                disabled: vars.disabled.edit,
                onClick: methods.editOrder,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                disabled: vars.disabled.new,
                onClick: methods.newOrder,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'danger',
                disabled: vars.disabled.delete,
                onClick: methods.deleteItem,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                disabled: vars.disabled.save,
                onClick: methods.saveOrder,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('work-order', '작업지시조회')
                  ),
                }"
              >
                <dx-label text="작업지시번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="target_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="작업지시일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당부서" :show-colon="false" />
                <dx-required-rule message="담당부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '80px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <dx-tab-panel
          :animation-enabled="false"
          :swipe-enabled="false"
          v-model:selected-index="vars.selectedTabIndex"
        >
          <dx-item title="작업지시">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 416px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'orderItem1')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.orderItem1"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :disabled="vars.disabled.items"
                >
                  <dx-grid-toolbar>
                    <dx-item location="before" template="addFromPlan" />
                    <dx-item name="addRowButton" template="addItem" />
                    <dx-item name="saveButton" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #addFromPlan>
                    <dx-button
                      icon="add"
                      text="생산계획에서 가져오기"
                      @click="methods.showPlanItem"
                    />
                  </template>
                  <template #addItem>
                    <dx-button icon="add" @click="methods.showBaseItem" />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_name"
                    caption="품명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item.item_standard"
                    caption="규격"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="required_quantity"
                    caption="작업지시수량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="bom_yn"
                    caption="BOM여부"
                    data-type="boolean"
                  />
                  <dx-column
                    data-field="item.unit"
                    caption="단위"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="production_plan_number"
                    caption="생산계획번호"
                  />
                  <dx-column
                    data-field="request_delivery_date"
                    caption="요청납기"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="unproduced_quantity"
                    caption="미생산수량"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                    :allow-editing="false"
                  />
                  <dx-column data-field="order_number" caption="수주번호" />
                  <dx-column data-field="client_company" caption="고객업체" />
                  <dx-column
                    data-field="client_item_number"
                    caption="고객사품번"
                    :allow-editing="false"
                  />
                  <dx-column data-field="end_user" caption="실수요자" />
                  <dx-column
                    data-field="project_number"
                    caption="프로젝트번호"
                  />
                  <dx-column
                    data-field="end_yn"
                    caption="종료"
                    data-type="boolean"
                  />
                  <dx-column
                    data-field="fk_work_order_id"
                    caption="작업지시"
                    :visible="false"
                  />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="필요자재">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 416px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'orderItem2')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.orderItem2"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :disabled="vars.disabled.items"
                  @init-new-row="methods.onItem2Insert"
                >
                  <dx-grid-toolbar>
                    <dx-item location="before" template="addFromPlan" />
                    <dx-item location="before" template="calculate" />
                    <dx-item location="before" template="exportOrder" />
                    <dx-item name="saveButton" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #addFromPlan>
                    <dx-button
                      icon="add"
                      text="생산계획에서 가져오기"
                      @click="methods.showPlanItem"
                    />
                  </template>
                  <template #calculate>
                    <dx-button
                      icon="formula"
                      text="투입자재 재계산"
                      @click="methods.calculateItem"
                    />
                  </template>
                  <template #exportOrder>
                    <dx-button
                      icon="export"
                      text="필요자재 출고요청"
                      @click="methods.requestExport"
                    />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="투입품목코드"
                    width="180"
                  />
                  <dx-column data-field="item.item_name" caption="품명" />
                  <dx-column data-field="item.item_standard" caption="규격" />
                  <dx-column
                    data-field="required_quantity"
                    caption="필요수량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="delivery_request_quantity"
                    caption="출고요청수량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column data-field="item.asset_type" caption="자산구분">
                    <dx-lookup
                      :data-source="vars.dataSource.asset_type"
                      display-expr="code_name"
                      value-expr="code_name"
                    />
                  </dx-column>
                  <dx-column data-field="item.unit" caption="단위" />
                  <dx-column data-field="wh_code" caption="출고창고" />
                  <dx-column
                    data-field="request_delivery_date"
                    caption="요청납기"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="uninput_quantity"
                    caption="미투입수량"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                    format="fixedPoint"
                  />
                  <dx-column
                    data-field="release_completed"
                    caption="자재출고요청완료"
                    data-type="boolean"
                  />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addplanItem.show"
      content-template="popup-content"
      title="생산계획품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addOrderPlanRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'planItem')"
          :data-source="vars.dataSource.planItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="false"
        >
          <dx-column
            data-field="production_plan.number"
            caption="생산계획번호"
          />
          <dx-column data-field="client_company" caption="고객사" />
          <dx-column
            data-field="order_date"
            caption="수주일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column
            data-field="production_plan_quantity"
            caption="계획수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field=""
            caption="미생산수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.addBaseItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column
            data-field="client_item.client_item_code"
            caption="고객사품번"
          />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
    >
      <template #popup-content>
        <data-grid-work-order
          v-if="vars.dlg.finder.key === 'work-order'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxLookup,
  DxToolbar as DxGridToolbar,
} from 'devextreme-vue/data-grid';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { confirm, alert } from 'devextreme/ui/dialog';
import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import { baseCodeLoader, getBaseItem } from '../../data-source/base';
import {
  produceWorkOrder,
  producePlanItem,
  getProducePlanItem,
  getProduceWorkOrderItem1,
  getProduceWorkOrderItem2,
} from '../../data-source/produce';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridOrderPlan from '../../components/purchase/data-order-plan.vue';
import DataGridWorkOrder from '../../components/produce/data-work-order.vue';
import authService from '../../auth';
import { generateItemButtonOption } from '../../utils/util';
import { loadDepartment, loadEmployee } from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxRequiredRule,
    DxLookup,
    DxGridToolbar,
    DxButton,
    DxTabPanel,
    DataGridClient,
    DataGridOrderPlan,
    DataGridWorkOrder,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.selectedTabIndex = 0;
    vars.formState = reactive({ readOnly: true });
    vars.grid = {
      orderItem1: null,
      orderItem2: null,
      baseItem: null,
      planItem: null,
    };
    vars.dlg.addBaseItem = reactive({ show: false });
    vars.dlg.addplanItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.filter = reactive({
      orderItem1: [{ name: 'fk_work_order_id', op: 'eq', val: props.id || 0 }],
      orderItem2: [{ name: 'fk_work_order_id', op: 'eq', val: props.id || 0 }],
      baseItem: {
        warehouseCode: null,
      },
      planItem: {
        warehouseCode: null,
      },
    });
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      payment_terms: [],
      order_type: [],
      vat_type: [],
      delivery_place: [],
      client_company: [],
      client_manager: [],
      department: [],
      employee: [],
      baseItem: null,
      planItem: null,
      orderItem1: getProduceWorkOrderItem1(vars.filter.orderItem1),
      orderItem2: getProduceWorkOrderItem2(vars.filter.orderItem2),
    });
    vars.formData = reactive({});
    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadDepartment(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.number = '';
          vars.formData.target_date = '';
          vars.formData.department = '';
          vars.formData.manager = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await produceWorkOrder.byKey(id);
        Object.assign(vars.formData, data);

        vars.filter.orderItem1[0].val = vars.formData.id;
        vars.dataSource.orderItem1.defaultFilters = vars.filter.orderItem1;
        if (vars.grid.orderItem1) vars.grid.orderItem1.refresh();

        vars.filter.orderItem2[0].val = vars.formData.id;
        vars.dataSource.orderItem2.defaultFilters = vars.filter.orderItem2;
        if (vars.grid.orderItem2) vars.grid.orderItem2.refresh();

        vars.disabled.edit = false;
        vars.disabled.delete = false;
        if (vars.formData.client_company && vars.formData.order_department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
      async addOrderPlanRows() {
        const rows = await vars.grid.planItem.getSelectedRowsData();
        for (let row of rows) {
          let grid = null;
          if (vars.selectedTabIndex == 0) {
            // 작업지시
            grid = vars.grid.orderItem1;
            await grid.addRow();
            grid.cellValue(0, 'required_quantity', 0); // 작업지시수량
            grid.cellValue(0, 'bom_yn', false); // BOM여부
            grid.cellValue(
              0,
              'production_plan_number',
              row.production_plan.number
            ); // 생산계획번호
            grid.cellValue(0, 'unproduced_quantity', 0); // 미생산수량
            grid.cellValue(0, 'order_number', row.order_number); // 수주번호
            grid.cellValue(0, 'client_company', row.client_company); // 고객업체
            grid.cellValue(0, 'client_item_number', row.client_item_number); // 고객사품번
            grid.cellValue(0, 'end_user', row.end_user); // 실수요자
            grid.cellValue(0, 'project_number', row.project_number); // 프로젝트번호
            grid.cellValue(0, 'end_yn', false); // 종료
          } else {
            // 필요자재
            grid = vars.grid.orderItem2;
            await grid.addRow();
            grid.cellValue(0, 'required_quantity', 0); // 필요수량
            grid.cellValue(0, 'delivery_request_quantity', 0); // 출고요청수량
            grid.cellValue(0, 'item.asset_type', row.item.asset_type); // 자산구분
            grid.cellValue(0, 'wh_code', ''); // 출고창고
            grid.cellValue(0, 'uninput_quantity', 0); // 미투입수량
            grid.cellValue(0, 'release_completed', false); // 자재출고요청완료
          }
          grid.cellValue(0, 'item_code', row.item_code); //품목코드
          grid.cellValue(0, 'item.item_name', row.item.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
          grid.cellValue(0, 'item.unit', row.item.unit); // 단위
          grid.cellValue(0, 'request_delivery_date', row.request_delivery_date); // 요청납기
          grid.cellValue(0, 'current_stock', 0); // 현재고
          grid.cellValue(0, 'available_stock', 0); // 가용재고
          grid.cellValue(0, 'fk_work_order_id', vars.formData.id); // 작업지시 FK
        }
        vars.dlg.addBaseItem.show = false;
        vars.dlg.addplanItem.show = false;
      },
      async addSelectedRows() {
        const grid = vars.grid.orderItem1;
        const rows = await vars.grid.baseItem.getSelectedRowsData();
        for (let row of rows) {
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
          grid.cellValue(0, 'required_quantity', 0); // 작업지시수량
          grid.cellValue(0, 'bom_yn', false); // BOM여부
          grid.cellValue(0, 'item.unit', row.item.unit); // 단위
          grid.cellValue(0, 'production_plan_number', null); // 생산계획번호
          grid.cellValue(0, 'request_delivery_date', null); // 요청납기
          grid.cellValue(0, 'unproduced_quantity', 0); // 미생산수량
          grid.cellValue(0, 'available_stock', row.available_stock); // 가용재고
          grid.cellValue(0, 'current_stock', row.current_stock); // 현재고
          grid.cellValue(0, 'order_number', null); // 수주번호
          grid.cellValue(0, 'client_company', null); // 고객업체
          grid.cellValue(0, 'client_item_number', null); // 고객사품번
          grid.cellValue(0, 'end_user', null); // 실수요자
          grid.cellValue(0, 'project_number', null); // 프로젝트번호
          grid.cellValue(0, 'end_yn', false); // 종료
        }
        vars.dlg.addBaseItem.show = false;
        vars.dlg.addplanItem.show = false;
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key;
        const _title = title;
        const _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showBaseItem() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.addBaseItem.show = true;
      },
      showPlanItem() {
        if (vars.grid.planItem) {
          vars.grid.planItem.clearSelection();
          vars.grid.planItem.refresh();
        }
        vars.dlg.addplanItem.show = true;
      },
      calculateItem() {
        console.log('calculateItem');
      },
      requestExport() {
        console.log('requestExport');
      },
      async newOrder() {
        if (vars.grid.orderItem1) {
          vars.filter.orderItem1[0].val = 0;
          vars.dataSource.orderItem1.defaultFilters = vars.filter.orderItem1;
          vars.grid.orderItem1.refresh();
        }
        if (vars.grid.orderItem2) {
          vars.filter.orderItem2[0].val = 0;
          vars.dataSource.orderItem2.defaultFilters = vars.filter.orderItem2;
          vars.grid.orderItem2.refresh();
        }
        if (vars.formData.id) {
          router.replace('/produce/work-order');
          vars.formData.id = null;
        }
        setTimeout(() => {
          vars.formData.target_date = new Date();
          vars.formData.department = authService.getDepartmentName();
          vars.formData.manager = authService.getUserName();
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editOrder() {
        if (!vars.formData.id) return;
        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteItem() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await produceWorkOrder.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          router.replace({ path: '/produce/work-order' });
          vars.formState.readOnly = true;
        }
      },
      async saveOrder() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await produceWorkOrder.update(vars.formData.id, vars.formData);
            if (vars.grid.orderItem1) {
              await vars.grid.orderItem1.saveEditData();
            }
            if (vars.grid.orderItem2) {
              await vars.grid.orderItem2.saveEditData();
            }
            notifyInfo('저장되었습니다');
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await produceWorkOrder.insert(vars.formData);
            vars.formData.id = data.id;

            const item1 = vars.grid.orderItem1;
            if (item1 && item1.hasEditData()) {
              await item1.saveEditData();
            }
            const item2 = vars.grid.orderItem2;
            if (item2 && item2.hasEditData()) {
              await item2.saveEditData();
            }

            router.replace({ path: `/produce/work-order/${data.id}` });
            vars.formState.readOnly = false;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 생산계획번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'work-order': {
            router.replace({ path: `/produce/work-order/${data.id}` });
            vars.formState.readOnly = true;
            break;
          }
          case 'etc': {
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.warehouseName = selectItem ? selectItem.warehouse.wh_name : '';
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();

        vars.filter.planItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadPlanItem();
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            delete element.data.item;
            delete element.data.current_stock;
            delete element.data.available_stock;
          }
        });
      },
      loadBaseCode() {
        baseCodeLoader(['부가세구분', '결재조건', '납품장소', '자산구분'])
          .then(response => {
            vars.dataSource.payment_terms = response['결재조건'];
            vars.dataSource.vat_type = response['부가세구분'];
            vars.dataSource.delivery_place = response['납품장소'];
            vars.dataSource.asset_type = response['자산구분'];
          })
          .then(() => (vars.init.value = true));
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          null,
          vars.filter.baseItem.warehouseCode
        );
      },
      loadPlanItem() {
        vars.dataSource.planItem = getProducePlanItem(
          null,
          vars.filter.planItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      producePlanItem,
      generateItemButtonOption,
    };
  },
};
</script>
