<template>
  <dx-data-grid
    :data-source="shipmentRelease"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="onSelectionChanged"
  >
    <dx-column data-field="release_number" caption="출고번호" />
    <dx-column
      data-field="release_date"
      caption="출고일자"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="client_company" caption="고객업체" />
    <dx-column data-field="client_manager" caption="업체담당자" />
    <dx-column data-field="release_department" caption="출고부서" />
    <dx-column data-field="release_manager" caption="출고담당자" />
    <dx-column data-field="release_type" caption="출고구분" />
    <dx-column data-field="vat_type" caption="부가세구분" />
    <dx-column data-field="payment_terms" caption="결재조건" />
    <dx-column
      data-field="delivery_date"
      caption="납품기한"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="delivery_place" caption="납품장소" />
    <dx-column data-field="client_order_number" caption="고객발주번호" />
    <dx-column data-field="project_number" caption="프로젝트번호" />
    <dx-column data-field="end_user" caption="EndUser" />
    <dx-column data-field="note" caption="참고사항" />
    <dx-column data-field="etc" caption="비고" />
    <dx-column
      data-field="supply_price"
      caption="공급가"
      data-type="number"
      format="currency"
    />
    <dx-column
      data-field="vat"
      caption="부가세"
      data-type="number"
      format="currency"
    />
    <dx-column
      data-field="total_price"
      caption="합계금액"
      data-type="number"
      format="currency"
    />

    <dx-selection
      select-all-mode="allPages"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
    <dx-paging :page-size="20" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { shipmentRelease } from '../../data-source/shipment';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    const onSelectionChanged = ({ selectedRowsData }) => {
      if (!props.multiple && selectedRowsData)
        selectedRowsData = selectedRowsData[0];
      emit('change', selectedRowsData);
    };

    return {
      mode: props.multiple ? 'multiple' : 'single',
      shipmentRelease,
      onSelectionChanged,
    };
  },
};
</script>
