<template>
  <div>
    <div class="content-block">
      <div class="dx-card responsive-paddings fit-height-content">
        <div class="content-header">
          <dx-toolbar>
            <dx-item location="before"><div class="content-title">대시보드</div></dx-item>
          </dx-toolbar>
        </div>


        <dx-data-grid
          class="fixed-header-table" height="calc(100vh - 150px)"
          :data-source="store.projectNotice"
          :show-borders="true"
          :hover-state-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
          @initialized="evt => methods.initialized(evt, 'notice-grid')"
          @row-click="methods.rowClick">
          <!--dx-column data-field="id" caption="번호" sort-order="desc" :width="60" /-->
          <dx-column data-field="created" caption="생성시간" data-type="date" format="yyyy-MM-dd" :allow-editing="false" :visible="false" :width="120" />
          <dx-column data-field="important" caption="중요여부" data-type="boolean" :width="80" />
          <dx-column data-field="title" caption="제목"/>
          <dx-column data-field="start_date" caption="공지시작일" data-type="date" format="yyyy-MM-dd" :width="100" />
          <dx-column data-field="end_date" caption="공지종료일" data-type="date" format="yyyy-MM-dd" :width="100" />
          <dx-column data-field="content_html" caption="내용" :visible="false" />
          <dx-column data-field="register_id" caption="최초등록자" :allow-editing="false" :width="100" />
          <dx-column data-field="fk_company_id" caption="회사" :allow-editing="false" :visible="false" />
          <dx-filter-row :visible="true"/>
          <dx-paging :page-size="20" />
        </dx-data-grid>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.noticeDetail.visible"
      :title="vars.noticeDetail.title"
      :drag-enabled="true"
      :close-on-outside-click="true"
      :show-close-button="true"
      :resize-enabled="true"
      :width="740"
      :height="540"
      @initialized="evt => methods.initialized(evt, 'notice-detail')"
      @hiding="methods.popupClose">
      <div class="popup-notice" v-html="vars.noticeDetail.context"></div>
    </dx-popup>

  </div>
</template>

<script>
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar'
import {DxDataGrid, DxColumn, DxPaging, DxFilterRow} from 'devextreme-vue/data-grid'
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import {projectNotice} from '@/data-source/project'
import authService from '@/auth'
import stateStore from '@/utils/state-store'
import { reactive } from 'vue';

export default {
  components: {
    DxToolbar, DxItem,
    DxDataGrid, DxColumn, DxPaging, DxFilterRow,
    DxPopup, DxToolbarItem
  },
  setup() {
    const vars = {}, store = {}, methods = {}
    projectNotice.defaultFilters = [{name: 'fk_company_id', op: 'eq', val: authService.getCompanyId()}]
    store.projectNotice = projectNotice

    vars.components = {}
    vars.noticeDetail = reactive({
      visible: false,
      title: '',
      context: ''
    })

    methods.initialized = (evt, key) => {
      vars.components[key] = evt.component
      stateStore.bind(key, evt.component)
    }
    methods.rowClick = (evt) => {
      vars.noticeDetail.title = evt.data.title
      vars.noticeDetail.context = evt.data.content_html
      vars.noticeDetail.visible = true
    }
    methods.popupClose = () => {
      vars.noticeDetail.title = ''
      vars.noticeDetail.context = ''
    }

    return {vars, store, methods}
  }
}
</script>


<style lang="scss">
</style>
