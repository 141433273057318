<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">검사등록관리</div></dx-item
            >
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                onClick: methods.editOrder,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                onClick: methods.newOrder,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{ text: '삭제', type: 'danger' }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                onClick: methods.saveOrder,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData" :show-colon-after-label="false">
          <template #avatar-template="{}">
            <div class="form-avatar" />
          </template>

          <dx-group-item :col-count="4" css-class="first-group">
            <dx-group-item :col-span="1">
              <dx-simple-item data-field="name"
                ><dx-label :text="'바코드입력'"
              /></dx-simple-item>
              <dx-simple-item
                data-field="qa_number"
                :editor-options="{
                  ...methods.generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('test-registration', '검사조회')
                  ),
                }"
                ><dx-label :text="'검사번호'"
              /></dx-simple-item>
              <dx-simple-item data-field="qa_date"
                ><dx-label :text="'검사일자'"
              /></dx-simple-item>
              <dx-simple-item data-field="equipment"
                ><dx-label :text="'설비'"
              /></dx-simple-item>
              <dx-simple-item
                data-field="item_code"
                :editor-options="{
                  ...methods.generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('order', '품목조회')
                  ),
                }"
                ><dx-label :text="'품목코드'"
              /></dx-simple-item>
              <dx-simple-item data-field="item_name"
                ><dx-label :text="'품명'"
              /></dx-simple-item>
              <dx-simple-item data-field="standard"
                ><dx-label :text="'규격'"
              /></dx-simple-item>
            </dx-group-item>
            <dx-group-item :col-span="1">
              <dx-simple-item data-field="qa_manager"
                ><dx-label :text="'검사자'"
              /></dx-simple-item>
              <dx-simple-item data-field="qa_count"
                ><dx-label :text="'검사횟수'"
              /></dx-simple-item>
              <dx-simple-item data-field=""
                ><dx-label :text="'검사구분'"
              /></dx-simple-item>
              <dx-simple-item data-field="process_name"
                ><dx-label :text="'공정명'"
              /></dx-simple-item>
              <dx-simple-item data-field="worker"
                ><dx-label :text="'작업자'"
              /></dx-simple-item>
              <dx-simple-item data-field="lot_no"
                ><dx-label :text="'LOT NO.'"
              /></dx-simple-item>
              <dx-simple-item data-field=""
                ><dx-label :text="'검사성적서'"
              /></dx-simple-item>
            </dx-group-item>
            <dx-group-item :col-span="2">
              <dx-simple-item template="avatar-template" />
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <div class="mt-2">
          <dx-data-grid
            class="fixed-header-table"
            height="calc(100vh - 600px)"
            :on-initialized="evt => methods.onGridInitialized(evt, 'item1')"
            :remote-operations="true"
            :data-source="vars.shipmentOrderItem1"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :select-text-on-edit-start="true"
            :row-alternation-enabled="true"
            @toolbar-preparing="methods.onGrid1ToolbarPreparing"
          >
            <dx-column
              data-field="item_code"
              caption="검사항목"
              width="180"
              :allow-editing="false"
            />
            <dx-column
              data-field="item_name"
              caption="검사규격"
              :allow-editing="false"
            />
            <dx-column
              data-field="standard"
              caption="단위"
              :allow-editing="false"
            >
              <!-- <dx-lookup :data-source="lookupDataSourceConfig" value-expr="id" display-expr="name" /> -->
            </dx-column>
            <dx-column
              data-field="order_quantity"
              caption="공차(+)"
              data-type="number"
            />
            <dx-column
              data-field="assign_quantity"
              caption="공차(-)"
              data-type="number"
            />
            <dx-column
              data-field="unit_price"
              caption="검사규격상세"
              data-type="number"
              format="currency"
            />
            <dx-column
              data-field="unit"
              caption="검사방법"
              :allow-editing="false"
            />
            <dx-column
              data-field="supply_price"
              caption="측정장비"
              data-type="number"
              format="currency"
              :allow-editing="false"
            />
            <dx-column
              data-field="request_delivery_date"
              caption="요청납기"
              data-type="date"
              format="yyyy-MM-dd"
            />
            <dx-column
              data-field="not_shipped"
              caption="미출고"
              data-type="number"
              :allow-editing="false"
            />
            <dx-column
              data-field="available_stock"
              caption="가용재고"
              data-type="number"
              :allow-editing="false"
            />
            <dx-column
              data-field="current_stock"
              caption="현재고"
              data-type="number"
              :allow-editing="false"
            />
            <dx-column data-field="quote_number" caption="견적번호" />
            <dx-column data-field="client_item_number" caption="고객사품번" />
            <dx-column
              data-field="item_detail"
              caption="품목설명"
              :allow-editing="false"
            />
            <dx-column data-field="note" caption="참고사항" />
            <dx-column
              data-field="closing_yn"
              caption="종결"
              data-type="boolean"
            />
            <dx-column
              data-field="fk_order_id"
              caption="수주아이디"
              :visible="false"
            />

            <dx-editing
              :allow-adding="true"
              :allow-updating="true"
              :allow-deleting="true"
              mode="batch"
            />
            <dx-scrolling mode="standard" />
            <dx-column-chooser mode="select" :enabled="true" />
          </dx-data-grid>
        </div>
        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급수량</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>불량수량</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>양품수량</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addQuoteItem.show"
      content-template="popup-content"
      title="견적품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'base')"
          :data-source="shipmentQuoteItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="false"
        >
          <dx-column data-field="quote.quote_number" caption="견적번호" />
          <dx-column
            data-field="quote.quote_date"
            caption="견적일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="quote.client_company" caption="고객업체" />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="standard" caption="규격" />
          <dx-column data-field="quote_quantity" caption="견적수량" />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="unit" caption="단위" />
          <dx-column data-field="price" caption="금액" format="currency" />
          <dx-column data-field="request_delivery_date" caption="요청납기" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="current_stock" caption="현재고" />
          <dx-column data-field="client_item_number" caption="고객사품번" />
          <dx-column data-field="item_detail" caption="품목설명" />
          <dx-column data-field="note" caption="참고사항" />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'base')"
          :data-source="baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="standard" caption="규격" />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="price" caption="금액" format="currency" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="current_stock" caption="현재고" />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
    >
      <template #popup-content>
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-quote
          v-else-if="vars.dlg.finder.key === 'quote'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-test-registration
          v-else-if="vars.dlg.finder.key === 'test-registration'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import notify from 'devextreme/ui/notify';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import {
  shipmentOrder,
  getShipmentOrderItem,
} from '../../data-source/shipment';
import { baseCodeLoader, baseClient, baseItem } from '../../data-source/base';
import { shipmentQuoteItem } from '../../data-source/shipment';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridQuote from '../../components/shipment/data-quote.vue';
import DataGridTestRegistration from '../../components/quality/data-test-registration.vue';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DataGridClient,
    DataGridQuote,
    DataGridTestRegistration,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.formState = reactive({ readOnly: true });
    vars.dataGridInstance = { base: null, item1: null, item2: null };
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.addQuoteItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.items = reactive({
      data1: [],
      filter1: [{ name: 'fk_order_id', op: 'eq', val: props.id || 0 }],
    });
    vars.common = {
      payment_terms: [],
      order_type: [],
      vat_type: [],
    };
    vars.formData = reactive({
      id: null,
      qa_number: '',
      qa_date: '',
      equipment: '',
      item_code: '',
      item_name: '',
      standard: '',
      qa_manager: '',
      qa_count: '',
      process_name: '',
      workder: '',
      lot_no: '',
      qa_standard_path: '',
      process_quantity: '',
      bad_quantity: '',
      good_quantity: '',
      fk_company_id: null,
    });

    vars.summary = {};
    vars.summary.supply_price = computed(() =>
      numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(() => numeral(vars.formData.vat).format('0,0'));
    vars.summary.total_price = computed(() =>
      numeral(vars.formData.total_price).format('0,0')
    );

    vars.shipmentOrderItem1 = getShipmentOrderItem(vars.items.filter1);

    let total = 0;
    vars.shipmentOrderItem1.off('loaded');
    vars.shipmentOrderItem1.on('loaded', ({ data }) => {
      if (data.length) {
        if (data[0].type === 1)
          total = data.reduce((a, v) => a + v.supply_price, 0);
        else total += data.reduce((a, v) => a + v.supply_price, 0);

        if (data[0].type === 1) console.log(data.length, total);
        // TODO: 필터안된 데이터를 두번 불러옴
        switch (vars.formData.vat_type) {
          case '별도': {
            vars.formData.supply_price = total;
            vars.formData.vat = Math.floor(total * 0.1);
            vars.formData.total_price = total + vars.formData.vat;
            break;
          }
          case '포함': {
            vars.formData.total_price = total;
            vars.formData.vat = Math.floor(total * 0.1);
            vars.formData.supply_price = total - vars.formData.vat;
            break;
          }
          case '영세': {
            vars.formData.total_price = total;
            vars.formData.vat = 0;
            vars.formData.supply_price = total;
            break;
          }
          default: {
            vars.formData.total_price = total;
            vars.formData.vat = 0;
            vars.formData.supply_price = total;
          }
        }
      }
    });

    baseCodeLoader(['부가세구분', '수주구분', '결재조건'])
      .then(response => {
        vars.common.payment_terms = response['결재조건'];
        vars.common.order_type = response['수주구분'];
        vars.common.vat_type = response['부가세구분'];
      })
      .then(() => (vars.init.value = true));

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.order_number = '';
          vars.formData.order_date = '';
          vars.formData.client_company = '';
          vars.formData.client_manager = '';
          vars.formData.order_department = '';
          vars.formData.order_manager = '';
          vars.formData.order_type = '';
          vars.formData.vat_type = '';
          vars.formData.payment_terms = '';
          vars.formData.delivery_date = '';
          vars.formData.delivery_place = '';
          vars.formData.client_order_number = '';
          vars.formData.project_number = '';
          vars.formData.end_user = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.supply_price = 0;
          vars.formData.vat = 0;
          vars.formData.total_price = 0;
          vars.formData.fk_company_id = null;
          return;
        }

        let { data } = await shipmentOrder.byKey(id);
        Object.assign(vars.formData, data);

        vars.items.filter1[0].val = vars.formData.id;
        vars.shipmentOrderItem1.defaultFilters = vars.items.filter1;
        if (vars.dataGridInstance.item1) vars.dataGridInstance.item1.refresh();
      },
      async addSelectedRows() {
        const rows = await vars.dataGridInstance.base.getSelectedRowsData();

        // Add Row Type
        for (let row of rows) {
          await vars.dataGridInstance.item1.addRow();
          vars.dataGridInstance.item1.cellValue(0, 'item_code', row.item_code);
          vars.dataGridInstance.item1.cellValue(0, 'item_name', row.item_name);
          vars.dataGridInstance.item1.cellValue(0, 'standard', row.standard);
          vars.dataGridInstance.item1.cellValue(
            0,
            'order_quantity',
            row.order_quantity
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'assign_quantity',
            row.assign_quantity
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'unit_price',
            row.unit_price
          );
          vars.dataGridInstance.item1.cellValue(0, 'unit', row.unit);
          vars.dataGridInstance.item1.cellValue(
            0,
            'supply_price',
            row.supply_price
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'request_delivery_date',
            row.request_delivery_date
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'available_stock',
            row.available_stock
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'current_stock',
            row.current_stock
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'quote_number',
            row.quote.quote_number
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'client_item_number',
            row.client_item_number
          );
          vars.dataGridInstance.item1.cellValue(
            0,
            'item_detail',
            row.item_detail
          );
          vars.dataGridInstance.item1.cellValue(0, 'closing_yn', false);
          vars.dataGridInstance.item1.cellValue(
            0,
            'fk_order_id',
            vars.formData.id
          );
        }

        vars.dlg.addItem.show = false;
        vars.dlg.addQuoteItem.show = false;
      },
      generateItemSelectOption(items = [], value = '', searchEnabled = false) {
        return { value, searchEnabled, items };
      },
      generateItemButtonOption(
        icon,
        callback,
        location = 'after',
        options = {}
      ) {
        let buttonOptions = { stylingMode: 'text', icon, onClick: callback };
        return {
          ...options,
          buttons: [{ name: icon, location, options: buttonOptions }],
        };
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddPopup() {
        vars.dlg.addItem.show = true;
      },
      showAddQuotePopup() {
        vars.dlg.addQuoteItem.show = true;
      },
      calculateAssignQuantity() {
        console.log('할당수량 재계산');
      },
      onGridInitialized(evt, key) {
        vars.dataGridInstance[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      onGrid1ToolbarPreparing(evt) {
        //evt.toolbarOptions.items = evt.toolbarOptions.items.filter(
        //  v => v.name !== 'saveButton'
        //);
        evt.toolbarOptions.items.find(
          v => v.name === 'addRowButton'
        ).options.onClick = methods.showAddPopup;
      },
      async newOrder() {
        console.log(moment().format('YYYY-MM-DD'));
        let sq = Date.now()
          .toString()
          .substr(2, 6);

        let params = { order_number: `ORDER-${sq}`, order_date: new Date() };
        let { data } = await shipmentOrder.insert(params);
        // Object.assign(vars.formData, data)
        router.replace({ path: `/shipment/order/${data.id}` });
        vars.formState.readOnly = false;
      },
      async editOrder() {
        if (!vars.formData.id) return;
        if (vars.formState.readOnly && vars.formData.confirmed) return;

        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'order': {
            // Object.assign(vars.formData, data)
            router.replace({ path: `/shipment/order/${data.id}` });
            vars.formState.readOnly = true;
            break;
          }
          case 'client': {
            console.log(data);
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      async saveOrder() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await shipmentOrder.update(vars.formData.id, vars.formData);
            if (vars.dataGridInstance.item1)
              await vars.dataGridInstance.item1.saveEditData();
            if (vars.dataGridInstance.item2)
              await vars.dataGridInstance.item2.saveEditData();
          } else {
            if (!vars.formData.order_number) {
              // 신규 채번이 안된 경우
              notify(
                { message: '저장 할 내용이 없습니다', width: 450 },
                'error',
                2000
              );
            } else {
              // 신규 등록
              await shipmentOrder.insert(vars.formData);
            }
          }
        } catch (ex) {
          console.error(ex);
          notify(
            { message: '저장 할 내용이 없습니다', width: 450 },
            'error',
            2000
          );
        } finally {
          vars.loading.value = false;
        }
      },
    };

    methods.initById(props.id);
    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      baseItem,
      baseClient,
      shipmentQuoteItem,
    };
  },
};
</script>
<style scoped>
.form-avatar {
  height: 312px;
  width: 100%;
  border: 1px solid #d2d3d5;
  border-radius: 0%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
