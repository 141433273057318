<template>
  <dx-data-grid
    :data-source="qualityTestRegistration"
    :show-borders="true"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :remote-operations="true"
    @selection-changed="onSelectionChanged"
  >
    <dx-column data-field="qa_number" caption="검사번호" />
    <dx-column
      data-field="qa_date"
      caption="검사일자"
      data-type="date"
      format="yyyy-MM-dd"
    />
    <dx-column data-field="equipment" caption="설비" />
    <dx-column data-field="item_code" caption="품목코드" />
    <dx-column data-field="item_name" caption="품명" />
    <dx-column data-field="standard" caption="규격" />
    <dx-column data-field="qa_manager" caption="검사자" />
    <dx-column data-field="qa_count" caption="검사횟수" />
    <dx-column data-field="process_name" caption="공정명" />
    <dx-column data-field="worker" caption="작업자" />
    <dx-column data-field="lot_no" caption="LOT No." />
    <dx-column data-field="process_quantity" caption="공정수량" />
    <dx-column data-field="bad_quantity" caption="불량수량" />
    <dx-column data-field="good_quantity" caption="양품수량" />

    <dx-selection
      select-all-mode="allPages"
      show-check-boxes-mode="always"
      :mode="mode"
    />
    <dx-filter-row :visible="true" />
    <dx-paging :page-size="20" />
  </dx-data-grid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
} from 'devextreme-vue/data-grid';
import { qualityTestRegistration } from '../../data-source/quality';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow },
  setup(props, { emit }) {
    const onSelectionChanged = ({ selectedRowsData }) => {
      if (!props.multiple && selectedRowsData)
        selectedRowsData = selectedRowsData[0];
      emit('change', selectedRowsData);
    };

    return {
      mode: props.multiple ? 'multiple' : 'single',
      qualityTestRegistration,
      onSelectionChanged,
    };
  },
};
</script>
