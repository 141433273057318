<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before">
              <div class="content-title">실적등록</div>
            </dx-item>
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                disabled: vars.disabled.edit,
                onClick: methods.editObject,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                disabled: vars.disabled.new,
                onClick: methods.newObject,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'danger',
                disabled: vars.disabled.delete,
                onClick: methods.deleteObject,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                disabled: vars.disabled.save,
                onClick: methods.saveObject,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="4">
            <dx-group-item>
              <dx-simple-item
                data-field="number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn(
                      'performance-registration',
                      '실적등록조회'
                    )
                  ),
                }"
              >
                <dx-label text="생산입고번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="target_date"
                editor-type="dxDateBox"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="생산일자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당부서" :show-colon="false" />
                <dx-required-rule message="담당부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '80px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <dx-tab-panel
          :animation-enabled="false"
          :swipe-enabled="false"
          v-model:selected-index="vars.selectedTabIndex"
        >
          <dx-item title="생산입고">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 382px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'item1')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.item1"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :row-alternation-enabled="true"
                  :disabled="vars.disabled.items"
                >
                  <dx-grid-toolbar>
                    <dx-item location="before" template="addFromWorkOrder" />
                    <dx-item name="addRowButton" template="addItem" />
                    <dx-item name="saveButton" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #addFromWorkOrder>
                    <dx-button
                      icon="add"
                      text="작지에서 가져오기"
                      @click="methods.showAddWorkOrderItem"
                    />
                  </template>
                  <template #addItem>
                    <dx-button icon="add" @click="methods.showAddBaseItem" />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="item_name"
                    caption="품명"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="standard"
                    caption="규격"
                    :allow-editing="false"
                  >
                  </dx-column>
                  <dx-column
                    data-field="work_order_quantity"
                    caption="작지수량"
                    data-type="number"
                  />
                  <dx-column
                    data-field="production_receiving_quantity"
                    caption="생산입고수량"
                    data-type="number"
                  />
                  <dx-column
                    data-field="unit"
                    caption="단위"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="work_order_number"
                    caption="작지번호"
                  />
                  <dx-column
                    data-field="request_delivery_date"
                    caption="요청납기"
                    data-type="date"
                    format="yyyy-MM-dd"
                  />
                  <dx-column
                    data-field="unproduced_quantity"
                    caption="미생산수량"
                    data-type="number"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                    :allow-editing="false"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                    :allow-editing="false"
                  />
                  <dx-column data-field="order_number" caption="수주번호" />
                  <dx-column data-field="client_company" caption="고객업체" />
                  <dx-column
                    data-field="client_item_number"
                    caption="고객사품번"
                  />
                  <dx-column
                    data-field="end_user"
                    caption="실수요자"
                    :allow-editing="false"
                  />
                  <dx-column data-field="warehouse" caption="입고창고" />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
          <dx-item title="자재소모">
            <template #default>
              <div class="pa-2">
                <dx-data-grid
                  class="fixed-header-table"
                  height="calc(100vh - 382px)"
                  :on-initialized="
                    evt => methods.onGridInitialized(evt, 'item2')
                  "
                  :remote-operations="true"
                  :data-source="vars.dataSource.item2"
                  :show-borders="true"
                  :allow-column-reordering="true"
                  :allow-column-resizing="true"
                  :column-auto-width="true"
                  :select-text-on-edit-start="true"
                  :disabled="vars.disabled.items"
                  @init-new-row="methods.onItem2Insert"
                >
                  <dx-grid-toolbar>
                    <dx-item location="before" template="addFromWorkOrder" />
                    <dx-item location="before" template="calculate" />
                    <dx-item name="addRowButton" template="addItem" />
                    <dx-item name="saveButton" />
                    <dx-item name="revertButton" />
                    <dx-item name="columnChooserButton" />
                  </dx-grid-toolbar>
                  <template #addFromWorkOrder>
                    <dx-button
                      icon="add"
                      text="작지에서 가져오기"
                      @click="methods.showAddWorkOrderItem"
                    />
                  </template>
                  <template #calculate>
                    <dx-button
                      icon="formula"
                      text="자재소모 재계산"
                      @click="methods.consumptionCalculate"
                    />
                  </template>
                  <template #addItem>
                    <dx-button icon="add" @click="methods.showAddBaseItem" />
                  </template>

                  <dx-column
                    data-field="item_code"
                    caption="품목코드"
                    width="180"
                  />
                  <dx-column data-field="item_name" caption="품명" />
                  <dx-column data-field="standard" caption="규격" />
                  <dx-column
                    data-field="ordered_quantity"
                    caption="작지수량"
                    data-type="number"
                  />
                  <dx-column
                    data-field="material_quantity"
                    caption="자재소모수량"
                    data-type="number"
                  />
                  <dx-column data-field="unit" caption="단위" />
                  <dx-column data-field="warehouse" caption="자재소모창고" />
                  <dx-column
                    data-field="available_stock"
                    caption="가용재고"
                    data-type="number"
                  />
                  <dx-column
                    data-field="current_stock"
                    caption="현재고"
                    data-type="number"
                  />
                  <dx-column data-field="asset_type" caption="자산구분" />
                  <dx-column data-field="note" caption="대분류" />
                  <dx-column data-field="note" caption="중분류" />
                  <dx-column data-field="note" caption="소분류" />

                  <dx-editing
                    :allow-adding="true"
                    :allow-updating="true"
                    :allow-deleting="true"
                    mode="batch"
                  />
                  <dx-scrolling mode="standard" />
                  <dx-column-chooser mode="select" :enabled="true" />
                </dx-data-grid>
              </div>
            </template>
          </dx-item>
        </dx-tab-panel>

        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.orderItem.show"
      content-template="popup-content"
      title="작업지시품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addWorkOrderItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'orderItem')"
          :data-source="vars.dataSource.orderItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="false"
        >
          <dx-column data-field="work_order.number" caption="작업지시번호" />
          <dx-column data-field="client_company" caption="고객업체" />
          <dx-column
            data-field="work_order.created"
            caption="작업지시일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column data-field="item.unit" caption="단위" />
          <dx-column data-field="item.asset_type" caption="자산구분" />
          <dx-column
            data-field="required_quantity"
            caption="작업지시수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="unproduced_quantity"
            caption="미생산수량"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.baseItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addBaseItem,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="standard" caption="규격" />
          <dx-column data-field="unit_price" caption="단가" format="currency" />
          <dx-column data-field="price" caption="금액" format="currency" />
          <dx-column data-field="available_stock" caption="가용재고" />
          <dx-column data-field="current_stock" caption="현재고" />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="320"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
    >
      <template #popup-content>
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-performance
          v-else-if="vars.dlg.finder.key === 'performance-registration'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxToolbar as DxGridToolbar,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { confirm, alert } from 'devextreme/ui/dialog';

import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import {
  performance,
  getPerformanceItem1,
  getPerformanceItem2,
  getProduceWorkOrderItem1,
} from '../../data-source/produce';
import { baseCodeLoader, getBaseItem } from '../../data-source/base';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridOrderPlan from '../../components/purchase/data-order-plan.vue';
import DataGridPerformance from '../../components/produce/data-performance-registration.vue';
import authService from '../../auth';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import { loadDepartment, loadEmployee } from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxTabPanel,
    DxRequiredRule,
    DxGridToolbar,
    DxButton,
    DataGridClient,
    DataGridOrderPlan,
    DataGridPerformance,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.selectedTabIndex = 0;
    vars.formState = reactive({ readOnly: true });
    vars.grid = { baseItem: null, item1: null, item2: null };
    vars.dlg.baseItem = reactive({ show: false });
    vars.dlg.orderItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.filter = reactive({
      baseItem: {
        warehouseCode: null,
      },
      orderItem: {
        warehouseCode: null,
      },
      item1: [
        {
          name: 'fk_performance_registration_id',
          op: 'eq',
          val: props.id || 0,
        },
      ],
      item2: [
        {
          name: 'fk_performance_registration_id',
          op: 'eq',
          val: props.id || 0,
        },
      ],
    });
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      paymentTerms: [],
      orderType: [],
      vatType: [],
      deliveryPlace: [],
      clientCompany: [],
      clientManager: [],
      department: [],
      employee: [],
      baseItem: null,
      orderItem: null,
      item1: getPerformanceItem1(vars.filter.item1),
      item2: getPerformanceItem2(vars.filter.item2),
    });
    vars.formData = reactive({});

    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadDepartment(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.number = '';
          vars.formData.target_date = '';
          vars.formData.department = '';
          vars.formData.manager = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await performance.byKey(id);
        Object.assign(vars.formData, data);

        vars.filter.item1[0].val = vars.formData.id;
        vars.dataSource.item1.defaultFilters = vars.filter.item1;
        if (vars.grid.item1) vars.grid.item1.refresh();

        vars.filter.item2[0].val = vars.formData.id;
        vars.dataSource.item2.defaultFilters = vars.filter.item2;
        if (vars.grid.item2) vars.grid.item2.refresh();

        vars.disabled.edit = false;
        vars.disabled.delete = false;
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
      async addWorkOrderItem() {
        const rows = await vars.grid.workOrderItem.getSelectedRowsData();
        for (let row of rows) {
          let grid = null;
          if (vars.selectedTabIndex == 0) {
            // 생산입고
            grid = vars.grid.performItem1;
            await grid.addRow();
            grid.cellValue(0, 'item_code', row.item_code); // 품목코드
            grid.cellValue(0, 'item.item_name', row.item.item_name); // 품목이름
            grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
            grid.cellValue(0, 'order_plan_quantity', row.required_quantity); // 작지수량
            grid.cellValue(
              0,
              'production_receiving_quantity',
              row.unproduced_quantity
            ); // 생산입고수량
            grid.cellValue(0, 'item.unit', row.item.unit); // 단위
            grid.cellValue(0, 'work_order_number', row.number); // 작지번호
            grid.cellValue(
              0,
              'request_delivery_date',
              row.request_delivery_date
            ); // 요청납기
            grid.cellValue(0, 'unproduced_quantity', row.unproduced_quantity); // 미생산수량
            grid.cellValue(0, 'available_stock', row.available_stock); // 가용재고
            grid.cellValue(0, 'current_stock', row.order.current_stock); // 현재고
            grid.cellValue(0, 'order_number', row.order.order_number); // 수주번호
            grid.cellValue(0, 'client_company', row.client_company); // 고객업체
            grid.cellValue(0, 'client_item_number', row.client_item_number); // 고객사품번
            grid.cellValue(0, 'end_user', row.end_user); // 실수요자
            grid.cellValue(0, 'wh_code', null); // 입고창고
            grid.cellValue(
              0,
              'fk_performance_registration_id',
              vars.formData.id
            ); // 작업지시 FK
          } else {
            // 자재소모
            grid = vars.grid.performItem2;
            await grid.addRow();
            grid.cellValue(0, 'item_code', row.item_code); // 품목코드
            grid.cellValue(0, 'item.item_name', row.item.item_name); // 품명
            grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
            grid.cellValue(0, 'ordered_quantity', 0); // 작지수량
            grid.cellValue(0, 'material_quantity', 0); // 자재소모수량
            grid.cellValue(0, 'item.unit', row.item.unit); // 단위
            grid.cellValue(0, 'wh_code', row.wh_code); // 자재소모창고
            grid.cellValue(0, 'available_stock', row.available_stock); // 가용재고
            grid.cellValue(0, 'current_stock', row.current_stock); // 현재고
            grid.cellValue(0, 'item.asset_type', row.item.asset_type); // 자산구분
            grid.cellValue(
              0,
              'item.main_category',
              row.item.item_main_category
            ); // 대분류
            grid.cellValue(0, 'item.middle_category', row.item.middle_category); // 중분류
            grid.cellValue(0, 'item.sub_category', row.item.sub_category); // 소분류
            grid.cellValue(
              0,
              'fk_performance_registration_id',
              vars.formData.id
            ); // 작업지시 FK
          }
        }
        vars.dlg.baseItem.show = false;
        vars.dlg.orderItem.show = false;
      },
      async addBaseItem() {
        const grid = vars.grid.performItem1;
        const rows = await vars.grid.baseItem.getSelectedRowsData();
        for (let row of rows) {
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item.item_name); // 품목이름
          grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
          grid.cellValue(0, 'order_plan_quantity', 0); // 작지수량
          grid.cellValue(
            0,
            'production_receiving_quantity',
            row.unproduced_quantity
          ); // 생산입고수량
          grid.cellValue(0, 'item.unit', row.item.unit); // 단위
          grid.cellValue(0, 'work_order_number', null); // 작지번호
          grid.cellValue(0, 'request_delivery_date', null); // 요청납기
          grid.cellValue(0, 'unproduced_quantity', 0); // 미생산수량
          grid.cellValue(0, 'available_stock', row.available_stock); // 가용재고
          grid.cellValue(0, 'current_stock', row.order.current_stock); // 현재고
          grid.cellValue(0, 'order_number', null); // 수주번호
          grid.cellValue(0, 'client_company', null); // 고객업체
          grid.cellValue(0, 'client_item_number', null); // 고객사품번
          grid.cellValue(0, 'end_user', null); // 실수요자
          grid.cellValue(0, 'wh_code', null); // 입고창고
          grid.cellValue(0, 'fk_performance_registration_id', vars.formData.id); // 작업지시 FK
        }
        vars.dlg.baseItem.show = false;
        vars.dlg.orderItem.show = false;
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddBaseItem() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.baseItem.show = true;
      },
      showAddWorkOrderItem() {
        if (vars.grid.orderItem) {
          vars.grid.orderItem.clearSelection();
          vars.grid.orderItem.refresh();
        }
        vars.dlg.orderItem.show = true;
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async newObject() {
        if (vars.grid.item1) {
          vars.filter.item1[0].val = 0;
          vars.dataSource.item1.defaultFilters = vars.filter.item1;
          vars.grid.item1.refresh();
        }
        if (vars.grid.item2) {
          vars.filter.item2[0].val = 0;
          vars.dataSource.item2.defaultFilters = vars.filter.item2;
          vars.grid.item2.refresh();
        }
        if (vars.formData.id) {
          router.replace('/produce/performance-registration');
          vars.formData.id = null;
        }
        setTimeout(() => {
          vars.formData.target_date = new Date();
          vars.formData.department = authService.getDepartmentName();
          vars.formData.manager = authService.getUserName();
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editObject() {
        if (!vars.formData.id) return;
        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteObject() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await performance.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          router.replace({ path: '/produce/performance-registration' });
          vars.formState.readOnly = true;
        }
      },
      async saveObject() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await performance.update(vars.formData.id, vars.formData);
            if (vars.grid.item1) await vars.grid.item1.saveEditData();
            if (vars.grid.item2) await vars.grid.item2.saveEditData();

            notifyInfo('저장되었습니다');
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await performance.insert(vars.formData);
            vars.formData.id = data.id;

            const item1 = vars.grid.item1;
            if (item1 && item1.hasEditData()) {
              await item1.saveEditData();
            }
            const item2 = vars.grid.item2;
            if (item2 && item2.hasEditData()) {
              await item2.saveEditData();
            }

            router.replace({
              path: `/produce/performance-registration/${data.id}`,
            });
            vars.formState.readOnly = false;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 생산입고번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'performance-registration': {
            router.replace({
              path: `/produce/performance-registration/${data.id}`,
            });
            vars.formState.readOnly = true;
            break;
          }
          case 'client': {
            console.log(data);
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.warehouseName = selectItem ? selectItem.warehouse.wh_name : '';
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();

        vars.filter.orderItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadOrderItem();
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            delete element.data.item;
            delete element.data.current_stock;
            delete element.data.available_stock;
          }
        });
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.production_plan_quantity && rowData.unit_price) {
          supply_price = rowData.production_plan_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSupplyPrice2(rowData) {
        let supply_price = 0;
        if (rowData.order_plan_quantity && rowData.unit_price) {
          supply_price = rowData.order_plan_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcSummary() {
        let total =
          sumSupplyPrice(
            vars.grid.measureItem1,
            'production_plan_quantity',
            'unit_price'
          ) +
          sumSupplyPrice(
            vars.grid.measureItem2,
            'order_plan_quantity',
            'unit_price'
          );

        const response = calcPriceSummary(vars.formData.vat_type, total);
        vars.formData.supply_price = response.supply_price;
        vars.formData.vat = response.vat;
        vars.formData.total_price = response.total_price;
      },
      consumptionCalculate() {
        console.log('자재소모재계산 : consumptionCalculate');
      },
      loadBaseCode() {
        return baseCodeLoader([
          '부가세구분',
          '수주구분',
          '결재조건',
          '납품장소',
        ])
          .then(response => {
            vars.dataSource.paymentTerms = response['결재조건'];
            vars.dataSource.orderType = response['수주구분'];
            vars.dataSource.vatType = response['부가세구분'];
            vars.dataSource.deliveryPlace = response['납품장소'];
          })
          .then(() => (vars.init.value = true));
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          vars.filter.baseItem.clientId,
          vars.filter.baseItem.warehouseCode
        );
      },
      loadOrderItem() {
        vars.dataSource.orderItem = getProduceWorkOrderItem1(
          null,
          vars.filter.orderItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (vars.formData.department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      generateItemButtonOption,
    };
  },
};
</script>
