<template>
  <div v-if="vars.init.value">
    <dx-load-panel v-model:visible="vars.loading.value" :show-pane="true" />
    <div class="content-block">
      <div class="dx-card responsive-paddings back-colored">
        <div class="content-header">
          <dx-toolbar class="back-colored">
            <dx-item location="before"
              ><div class="content-title">수주</div></dx-item
            >
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '수정',
                type: 'normal',
                disabled: vars.disabled.edit,
                onClick: methods.editOrder,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '신규',
                type: 'default',
                disabled: vars.disabled.new,
                onClick: methods.newOrder,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '삭제',
                type: 'danger',
                disabled: vars.disabled.delete,
                onClick: methods.deleteOrder,
              }"
            />
            <dx-item
              location="after"
              locate-in-menu="auto"
              widget="dxButton"
              :options="{
                text: '저장',
                type: 'success',
                disabled: vars.disabled.save,
                onClick: methods.saveOrder,
              }"
            />
          </dx-toolbar>
        </div>
        <dx-form :form-data="vars.formData">
          <dx-group-item :col-count="5">
            <dx-group-item>
              <dx-simple-item
                data-field="order_number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('order', '수주조회')
                  ),
                }"
              >
                <dx-label text="수주번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="order_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="수주일자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="client_company"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.client_company,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  searchEnabled: 'true',
                  onValueChanged: methods.onClientChanged,
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('client', '고객조회')
                  ),
                  ...vars.formState,
                }"
              >
                <dx-label text="고객업체" :show-colon="false" />
                <dx-required-rule message="고객업체를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="client_manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.client_manager,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  acceptCustomValue: true,
                  disabled: vars.disabled.clientManager,
                  ...vars.formState,
                }"
              >
                <dx-label text="업체담당자" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="order_department"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.department,
                  displayExpr: 'department_name',
                  valueExpr: 'department_name',
                  acceptCustomValue: true,
                  onValueChanged: methods.onDepartmentChanged,
                  ...vars.formState,
                }"
              >
                <dx-label text="수주부서" :show-colon="false" />
                <dx-required-rule message="수주부서를 선택하세요" />
              </dx-simple-item>
              <dx-simple-item
                data-field="order_manager"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.employee,
                  displayExpr: 'emp_name',
                  valueExpr: 'emp_name',
                  disabled: vars.disabled.manager,
                  ...vars.formState,
                }"
              >
                <dx-label text="수주담당자" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="order_type"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.order_type,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="수주구분" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="vat_type"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.vat_type,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  ...vars.formState,
                }"
              >
                <dx-label text="부가세구분" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="payment_terms"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.payment_terms,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  acceptCustomValue: true,
                  ...vars.formState,
                }"
              >
                <dx-label text="결재조건" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="delivery_date"
                editor-type="dxDateBox"
                :editor-options="{
                  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss',
                  ...vars.formState,
                }"
              >
                <dx-label text="납품기한" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="delivery_place"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.delivery_place,
                  displayExpr: 'code_name',
                  valueExpr: 'code_name',
                  acceptCustomValue: true,
                  ...vars.formState,
                }"
              >
                <dx-label text="납품장소" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="client_order_number"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="고객발주번호" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="project_number"
                :editor-options="{
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('project', '프로젝트조회')
                  ),
                  ...vars.formState,
                }"
              >
                <dx-label text="프로젝트번호" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="end_user"
                editor-type="dxSelectBox"
                :editor-options="{
                  dataSource: vars.dataSource.client_company,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  searchEnabled: 'true',
                  onValueChanged: methods.onEndUserChanged,
                  ...generateItemButtonOption(
                    'search',
                    methods.createFindPopupFn('enduser', '고객조회')
                  ),
                  ...vars.formState,
                }"
              >
                <dx-label text="EndUser" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="note"
                :editor-options="{ ...vars.formState }"
              >
                <dx-label text="참고사항" :show-colon="false" />
              </dx-simple-item>
              <dx-simple-item
                data-field="confirmed"
                editor-type="dxCheckBox"
                :editor-options="{ onValueChanged: methods.onConfirmChanged }"
              >
                <dx-label text="수주확정" :show-colon="false" />
              </dx-simple-item>
            </dx-group-item>
            <dx-group-item>
              <dx-simple-item
                data-field="etc"
                editor-type="dxTextArea"
                :editor-options="{
                  ...generateItemButtonOption(
                    'rename',
                    methods.createFindPopupFn('etc', '비고', vars.formData.etc)
                  ),
                  ...vars.formState,
                  height: '174px',
                  labelMode: 'hidden',
                  placeholder: '비고',
                }"
              >
                <dx-label text="비고" :show-colon="false" :visible="false" />
              </dx-simple-item>
            </dx-group-item>
          </dx-group-item>
        </dx-form>
      </div>

      <div class="dx-card responsive-paddings mt-1">
        <div class="mt-2">
          <dx-data-grid
            class="fixed-header-table"
            height="calc(100vh - 416px)"
            :on-initialized="evt => methods.onGridInitialized(evt, 'item1')"
            :remote-operations="true"
            :data-source="vars.shipmentOrderItem1"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :select-text-on-edit-start="true"
            :row-alternation-enabled="true"
            :disabled="vars.disabled.items"
            @saving="methods.onSavingItem"
          >
            <dx-grid-toolbar>
              <dx-item location="before" template="addFromQuote" />
              <dx-item location="before" template="calcAvailStock" />
              <dx-grid-item
                name="addRowButton"
                :options="{ onClick: methods.showAddPopup }"
              />
              <dx-grid-item name="saveButton" :visible="!!vars.formData.id" />
              <dx-grid-item name="revertButton" />
              <dx-grid-item name="columnChooserButton" />
            </dx-grid-toolbar>
            <template #addFromQuote>
              <dx-button
                icon="add"
                text="견적에서 가져오기"
                @click="methods.showAddQuotePopup"
              />
            </template>
            <template #calcAvailStock>
              <dx-button
                icon="formula"
                text="할당수량 재계산"
                @click="methods.calculateAssignQuantity"
              />
            </template>

            <dx-column
              data-field="item_code"
              caption="품목코드"
              width="180"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_name"
              caption="품명"
              :allow-editing="false"
            />
            <dx-column
              data-field="item.item_standard"
              caption="규격"
              :allow-editing="false"
            />
            <dx-column
              data-field="order_quantity"
              caption="수주수량"
              data-type="number"
              format="fixedPoint"
              :set-cell-value="methods.setQuantity"
            />
            <dx-column
              data-field="assign_quantity"
              caption="할당수량"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="unit_price"
              caption="단가"
              data-type="number"
              format="currency"
              :set-cell-value="methods.setUnitPrice"
            />
            <dx-column
              data-field="item.unit"
              caption="단위"
              :allow-editing="false"
            />
            <dx-column
              data-field="supply_price"
              caption="공급가"
              data-type="number"
              format="currency"
              :allow-editing="false"
              :calculate-cell-value="methods.calcSupplyPrice"
            />
            <dx-column
              data-field="request_delivery_date"
              caption="요청납기"
              data-type="date"
              format="yyyy-MM-dd"
            />
            <dx-column
              data-field="release_warehouse"
              caption="출고창고"
              :allow-editing="false"
            />
            <dx-column
              data-field="not_shipped"
              caption="미출고"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="available_stock"
              caption="가용재고"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="current_stock"
              caption="현재고"
              data-type="number"
              format="fixedPoint"
              :allow-editing="false"
            />
            <dx-column
              data-field="quote_number"
              caption="견적번호"
              :allow-editing="false"
            />
            <dx-column data-field="client_item_number" caption="고객사품번" />
            <dx-column
              data-field="item.item_detail"
              caption="품목설명"
              :allow-editing="false"
            />
            <dx-column data-field="note" caption="참고사항" />
            <dx-column
              data-field="closing_yn"
              caption="종결"
              data-type="boolean"
            />
            <dx-column
              data-field="fk_order_id"
              caption="수주아이디"
              :visible="false"
            />
            <dx-column data-field="project_number" caption="프로젝트번호" />

            <dx-editing
              :allow-adding="true"
              :allow-updating="true"
              :allow-deleting="true"
              mode="batch"
            />
            <dx-scrolling mode="standard" />
            <dx-column-chooser mode="select" :enabled="true" />
          </dx-data-grid>
        </div>
        <div class="mt-2">
          <table class="summary-table">
            <tr>
              <th>공급가:</th>
              <td>{{ vars.summary.supply_price.value }}</td>
              <th>부가세:</th>
              <td>{{ vars.summary.vat.value }}</td>
              <th>합계금액:</th>
              <td>{{ vars.summary.total_price.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <dx-popup
      v-model:visible="vars.dlg.addQuoteItem.show"
      content-template="popup-content"
      title="견적품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedQuoteRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'quoteItem')"
          :data-source="vars.dataSource.quoteItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="quote.client_company" caption="고객업체" />
          <dx-column data-field="quote.quote_number" caption="견적번호" />
          <dx-column
            data-field="quote.quote_date"
            caption="견적일자"
            data-type="date"
            format="yyyy-MM-dd"
          />
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item.item_name" caption="품명" />
          <dx-column data-field="item.item_standard" caption="규격" />
          <dx-column data-field="quote_quantity" caption="견적수량" />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.addItem.show"
      content-template="popup-content"
      title="품목찾기"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :resize-enabled="true"
    >
      <dx-toolbar-item
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="{
          icon: 'add',
          text: '선택된 항목 추가',
          onClick: methods.addSelectedRows,
        }"
      />

      <template #popup-content>
        <dx-data-grid
          :on-initialized="evt => methods.onGridInitialized(evt, 'baseItem')"
          :data-source="vars.dataSource.baseItem"
          :show-borders="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :remote-operations="true"
        >
          <dx-column data-field="item_code" caption="품목코드" />
          <dx-column data-field="item_name" caption="품명" />
          <dx-column data-field="item_standard" caption="규격" />
          <dx-column
            data-field="client_item.client_item_code"
            caption="고객사품번"
          />
          <dx-column data-field="asset_type" caption="자산구분" />
          <dx-column
            data-field="basic_stock.available_stock"
            caption="가용재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-column
            data-field="basic_stock.current_stock"
            caption="현재고"
            data-type="number"
            format="fixedPoint"
          />
          <dx-selection
            select-all-mode="allPages"
            show-check-boxes-mode="onClick"
            mode="multiple"
          />
          <dx-paging :page-size="20" />
          <dx-filter-row :visible="true" />
        </dx-data-grid>
      </template>
    </dx-popup>

    <dx-popup
      v-model:visible="vars.dlg.finder.show"
      content-template="popup-content"
      :title="vars.dlg.finder.title"
      :close-on-outside-click="true"
      :width="680"
      :height="500"
      :key="vars.dlg.finder.key"
      :resize-enabled="true"
    >
      <template #popup-content>
        <data-grid-client
          v-if="vars.dlg.finder.key === 'client'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-client
          v-else-if="vars.dlg.finder.key === 'enduser'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-order
          v-else-if="vars.dlg.finder.key === 'order'"
          @change="methods.finderReturnHandler"
        />
        <data-grid-project
          v-else-if="vars.dlg.finder.key === 'project'"
          @change="methods.finderReturnHandler"
        />
        <div v-else-if="vars.dlg.finder.key === 'etc'">
          <div class="mb-2">
            <dx-text-area
              :height="190"
              :value="vars.dlg.finder.data"
              @update:value="methods.updateEtcValue"
            />
          </div>
          <dx-toolbar>
            <dx-item
              widget="dxButton"
              toolbar="top"
              location="after"
              :options="{
                icon: null,
                text: '닫기',
                onClick: methods.finderReturnHandler,
              }"
            />
          </dx-toolbar>
        </div>
      </template>
    </dx-popup>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTextArea from 'devextreme-vue/text-area';
import {
  DxForm,
  DxLabel,
  DxGroupItem,
  DxSimpleItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxEditing,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxToolbar as DxGridToolbar,
  DxItem as DxGridItem,
} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { confirm, alert } from 'devextreme/ui/dialog';
import { onMounted, ref, reactive, computed, watch, nextTick } from 'vue';
import { useRouter } from 'vue-router';

import stateStore from '@/utils/state-store';
import {
  shipmentOrder,
  getShipmentOrderItem,
} from '../../data-source/shipment';
import { baseCodeLoader, getBaseItem } from '../../data-source/base';
import {
  shipmentQuoteItem,
  getShipmentQuoteItem,
} from '../../data-source/shipment';
import DataGridClient from '../../components/base/data-client.vue';
import DataGridOrder from '../../components/shipment/data-order.vue';
import DataGridProject from '../../components/project/data-project.vue';
import authService from '../../auth';
import ApiService from '../../utils/api-service';
import {
  generateItemButtonOption,
  sumSupplyPrice,
  calcPriceSummary,
} from '../../utils/util';
import {
  loadDepartment,
  loadEmployee,
  loadClient,
  loadClientManager,
  loadWarehouse,
} from '../../utils/data-loader';
import { notifyInfo, notifyError } from '../../utils/notify';

export default {
  components: {
    DxRequiredRule,
    DxToolbar,
    DxItem,
    DxTextArea,
    DxLoadPanel,
    DxForm,
    DxLabel,
    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxSelection,
    DxPaging,
    DxPopup,
    DxToolbarItem,
    DxFilterRow,
    DxScrolling,
    DxColumnChooser,
    DxGridToolbar,
    DxGridItem,
    DxButton,
    DataGridClient,
    DataGridOrder,
    DataGridProject,
  },
  props: {
    id: [String, Number],
  },
  setup(props) {
    // variable 설정
    const router = useRouter();
    const vars = { dlg: {} };
    const updateStockApiService = new ApiService('/api/mes/v1/update-stock');
    vars.init = ref(false);
    vars.loading = ref(false);
    vars.formState = reactive({ readOnly: true });
    vars.grid = { baseItem: null, quoteItem: null, item1: null };
    vars.dlg.addItem = reactive({ show: false });
    vars.dlg.addQuoteItem = reactive({ show: false });
    vars.dlg.finder = reactive({
      show: false,
      title: '',
      key: null,
      data: null,
    });
    vars.warehouseName = '';
    vars.filter = {
      baseItem: {
        clientId: null,
        warehouseCode: null,
      },
      quoteItem: {
        clientCompany: null,
        warehouseCode: null,
      },
      item1: [{ name: 'fk_order_id', op: 'eq', val: props.id || 0 }],
    };
    vars.disabled = reactive({
      edit: true,
      new: false,
      delete: true,
      save: true,
      items: true,
      manager: true,
      clientManager: true,
      tradeYn: false,
    });
    vars.dataSource = reactive({
      payment_terms: [],
      order_type: [],
      vat_type: [],
      delivery_place: [],
      warehouse: [],
      client_company: [],
      client_manager: [],
      department: [],
      employee: [],
      baseItem: null,
      quoteItem: null,
    });
    vars.formData = reactive({});

    vars.summary = {};
    vars.summary.supply_price = computed(
      () => '₩' + numeral(vars.formData.supply_price).format('0,0')
    );
    vars.summary.vat = computed(
      () => '₩' + numeral(vars.formData.vat).format('0,0')
    );
    vars.summary.total_price = computed(
      () => '₩' + numeral(vars.formData.total_price).format('0,0')
    );

    vars.shipmentOrderItem1 = getShipmentOrderItem(vars.filter.item1);

    onMounted(async () => {
      await methods.loadBaseCode();
      await loadWarehouse(vars.dataSource);
      await loadDepartment(vars.dataSource);
      await loadClient(vars.dataSource);
      methods.initById(props.id);
    });

    // public methods
    const methods = {
      async initById(id) {
        if (!id) {
          vars.formData.id = null;
          vars.formData.order_number = '';
          vars.formData.order_date = '';
          vars.formData.client_company = '';
          vars.formData.client_manager = '';
          vars.formData.order_department = '';
          vars.formData.order_manager = '';
          vars.formData.order_type = '';
          vars.formData.vat_type = '';
          vars.formData.payment_terms = '';
          vars.formData.delivery_date = '';
          vars.formData.delivery_place = '';
          vars.formData.client_order_number = '';
          vars.formData.project_number = '';
          vars.formData.end_user = '';
          vars.formData.note = '';
          vars.formData.etc = '';
          vars.formData.supply_price = 0;
          vars.formData.vat = 0;
          vars.formData.total_price = 0;
          vars.formData.confirmed = false;
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
          return;
        }

        let { data } = await shipmentOrder.byKey(id);
        Object.assign(vars.formData, data);

        vars.filter.item1[0].val = vars.formData.id;
        vars.shipmentOrderItem1.defaultFilters = vars.filter.item1;
        if (vars.grid.item1) vars.grid.item1.refresh();

        vars.disabled.edit = false;
        vars.disabled.delete = false;
        if (vars.formData.client_company && vars.formData.order_department) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }

        if (vars.formData.confirmed) {
          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.disabled.clientManager = true;
        }
      },
      async addSelectedRows() {
        const grid = vars.grid.item1;
        const rows = await vars.grid.baseItem.getSelectedRowsData();
        for (let row of rows) {
          const basicStock = row.basic_stock;
          const clientItem = row.client_item;
          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item_standard); // 규격
          grid.cellValue(0, 'order_quantity', 0); // 수주 수량
          grid.cellValue(0, 'assign_quantity', 0); // 할당 수량
          grid.cellValue(0, 'unit_price', row.sales_price); // 단가
          grid.cellValue(0, 'item.unit', row.unit); // 단위
          grid.cellValue(0, 'supply_price', row.supply_price); // 공급가
          grid.cellValue(0, 'request_delivery_date', null); // 요청납기
          grid.cellValue(0, 'release_warehouse', vars.warehouseName); // 출고창고
          grid.cellValue(0, 'not_shipped', 0); // 미출고
          if (basicStock) {
            grid.cellValue(0, 'available_stock', basicStock.available_stock); // 가용재고
            grid.cellValue(0, 'current_stock', basicStock.current_stock); // 현재고
          } else {
            grid.cellValue(0, 'available_stock', 0); // 가용재고
            grid.cellValue(0, 'current_stock', 0); // 현재고
          }
          grid.cellValue(0, 'quote_number', null); // 견적번호
          if (clientItem) {
            grid.cellValue(
              0,
              'client_item_number',
              clientItem.client_item_code
            ); // 고객사품번
          } else {
            grid.cellValue(0, 'client_item_number', null); // 고객사품번
          }
          grid.cellValue(0, 'item.item_detail', row.item_detail); // 품목설명
          grid.cellValue(0, 'note', ''); // 참고사항
          grid.cellValue(0, 'closing_yn', false); // 종결
          grid.cellValue(0, 'project_number', vars.formData.project_number); // 프로젝트번호
        }
        vars.dlg.addItem.show = false;
      },
      async addSelectedQuoteRows() {
        const grid = vars.grid.item1;
        const rows = await vars.grid.quoteItem.getSelectedRowsData();
        for (let row of rows) {
          const basicStock = row.basic_stock;
          let availableStock = 0;
          let currentStock = 0;
          if (basicStock) {
            availableStock = basicStock.available_stock;
            currentStock = basicStock.current_stock;
          }
          let assignQuantity = row.quote_quantity;
          if (availableStock < row.quote_quantity) {
            assignQuantity = availableStock;
          }

          await grid.addRow();
          grid.cellValue(0, 'item_code', row.item_code); // 품목코드
          grid.cellValue(0, 'item.item_name', row.item.item_name); // 품명
          grid.cellValue(0, 'item.item_standard', row.item.item_standard); // 규격
          grid.cellValue(0, 'order_quantity', row.quote_quantity); // 수주수량
          grid.cellValue(0, 'assign_quantity', assignQuantity); // 할당수량
          grid.cellValue(0, 'unit_price', row.unit_price); // 단가
          grid.cellValue(0, 'item.unit', row.item.unit); // 단위
          grid.cellValue(0, 'supply_price', row.supply_price); // 공급가
          grid.cellValue(0, 'request_delivery_date', row.request_delivery_date); // 요청납기
          grid.cellValue(0, 'release_warehouse', vars.warehouseName); // 출고창고
          grid.cellValue(0, 'not_shipped', row.quote_quantity); // 미출고
          grid.cellValue(0, 'available_stock', availableStock); // 가용재고
          grid.cellValue(0, 'current_stock', currentStock); // 현재고
          grid.cellValue(0, 'quote_number', row.quote.quote_number); // 견적번호
          grid.cellValue(0, 'client_item_number', row.client_item_number); // 고객사품번
          grid.cellValue(0, 'item.item_detail', row.item.item_detail); // 픔목설명
          grid.cellValue(0, 'note', row.note); // 참고사항
          grid.cellValue(0, 'closing_yn', false); // 종결
          grid.cellValue(0, 'project_number', row.project_number); // 프로젝트번호
          grid.cellValue(0, 'fk_order_id', vars.formData.id);
        }
        vars.dlg.addQuoteItem.show = false;
      },
      createFindPopupFn(key, title, data = null) {
        const _key = key,
          _title = title,
          _data = data;
        return () => {
          vars.dlg.finder.key = _key;
          vars.dlg.finder.data = _data;
          vars.dlg.finder.title = _title;
          vars.dlg.finder.show = true;
        };
      },
      showAddPopup() {
        if (vars.grid.baseItem) {
          vars.grid.baseItem.clearSelection();
          vars.grid.baseItem.refresh();
        }
        vars.dlg.addItem.show = true;
      },
      showAddQuotePopup() {
        if (vars.grid.quoteItem) {
          vars.grid.quoteItem.clearSelection();
          vars.grid.quoteItem.refresh();
        }
        vars.dlg.addQuoteItem.show = true;
      },
      async calculateAssignQuantity() {
        console.log('할당수량 재계산');
        console.log(vars.grid.item1.getVisibleRows());
        console.log(`부서: ${vars.formData.order_department}`);
        const params = { department: vars.formData.order_department };
        const rows = vars.grid.item1.getVisibleRows();
        for (const row of rows) {
          params.item_code = row.data.item_code;
          console.log(`품목: ${row.data.item_code}`);
          await updateStockApiService.post('', params);
        }
        vars.grid.item1.refresh();
      },
      onGridInitialized(evt, key) {
        vars.grid[key] = evt.component;
        stateStore.bind(key, evt.component);
      },
      async newOrder() {
        if (vars.grid.item1) {
          vars.filter.item1[0].val = 0;
          vars.shipmentOrderItem1.defaultFilters = vars.filter.item1;
          vars.grid.item1.refresh();
        }
        if (vars.formData.id) {
          router.replace('/shipment/order');
          vars.formData.id = null;
        }
        setTimeout(() => {
          let orderType = '';
          if (vars.dataSource.order_type.length > 0) {
            orderType = vars.dataSource.order_type[0].code_name;
          }
          let vatType = '';
          if (vars.dataSource.vat_type.length > 0) {
            vatType = vars.dataSource.vat_type[0].code_name;
          }
          let paymentTerms = '';
          if (vars.dataSource.payment_terms.length > 0) {
            paymentTerms = vars.dataSource.payment_terms[0].code_name;
          }
          let deliveryPlace = '';
          if (vars.dataSource.delivery_place.length > 0) {
            deliveryPlace = vars.dataSource.delivery_place[0].code_name;
          }

          vars.formData.order_date = new Date();
          vars.formData.order_department = authService.getDepartmentName();
          vars.formData.order_manager = authService.getUserName();
          vars.formData.order_type = orderType;
          vars.formData.vat_type = vatType;
          vars.formData.payment_terms = paymentTerms;
          vars.formData.delivery_place = deliveryPlace;
          vars.formData.fk_company_id = authService.getCompanyId();

          vars.formState.readOnly = false;
        }, 200);
      },
      async editOrder() {
        if (!vars.formData.id) return;
        if (vars.formState.readOnly && vars.formData.confirmed) return;

        const saveFormData = Object.assign({}, vars.formData);
        vars.formState.readOnly = !vars.formState.readOnly;

        await nextTick();
        Object.assign(vars.formData, saveFormData);
      },
      async deleteOrder() {
        const result = await confirm(
          '이 항목을 삭제하시겠습니까?',
          '삭제 확인'
        );
        if (result) {
          await shipmentOrder.remove(vars.formData.id);
          await alert('삭제되었습니다', '삭제 확인');
          router.replace({ path: '/shipment/order' });
          vars.formState.readOnly = true;
        }
      },
      async saveOrder() {
        vars.loading.value = true;
        try {
          if (vars.formData.id) {
            // 기존 정보 업데이트
            await shipmentOrder.update(vars.formData.id, vars.formData);
            if (vars.grid.item1) await vars.grid.item1.saveEditData();

            notifyInfo('저장되었습니다');
          } else {
            // 채번이 없을 경우 자동 채번
            let { data } = await shipmentOrder.insert(vars.formData);
            vars.formData.id = data.id;

            const gridItem1 = vars.grid.item1;
            if (gridItem1 && gridItem1.hasEditData()) {
              await gridItem1.saveEditData();
            }

            router.replace({ path: `/shipment/order/${data.id}` });
            vars.formState.readOnly = false;
            notifyInfo('저장되었습니다');
          }
        } catch (ex) {
          if (ex.response.status === 409) {
            notifyError('이미 존재하는 수주번호 입니다');
          } else {
            console.error(ex);
            notifyError('저장 할 내용이 없습니다');
          }
        } finally {
          vars.loading.value = false;
        }
      },
      finderReturnHandler(data) {
        switch (vars.dlg.finder.key) {
          case 'order': {
            router.replace({ path: `/shipment/order/${data.id}` });
            vars.formState.readOnly = true;
            break;
          }
          case 'client': {
            vars.formData.client_company = data.name;
            break;
          }
          case 'enduser': {
            vars.formData.end_user = data.name;
            break;
          }
          case 'project': {
            vars.formData.project_number = data.project_number;
            break;
          }
          case 'etc': {
            console.log(vars.dlg.finder.data);
            vars.formData.etc = vars.dlg.finder.data;
            break;
          }
        }

        vars.dlg.finder.show = false;
        vars.dlg.finder.title = '';
        vars.dlg.finder.key = null;
        vars.dlg.finder.data = null;
      },
      async onClientChanged(e) {
        const client = e.component.option('selectedItem');
        vars.filter.baseItem.clientId = client ? client.id : null;
        methods.loadBaseItem();

        vars.filter.quoteItem.clientCompany = client ? client.name : null;
        methods.loadQuoteItem();

        if (!client) {
          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.tradeYn = false;
          vars.disabled.clientManager = true;
          vars.formData.client_manager = null;
          vars.dataSource.client_manager = [];
        } else {
          if (vars.formState.readOnly || vars.disabled.tradeYn) {
            loadClientManager(vars.dataSource, client.name);
            vars.disabled.tradeYn = false;
            vars.disabled.clientManager = false;
          } else {
            let isSelect = true;
            if (client.trade_yn) {
              isSelect = await confirm(
                '거래중지 업체입니다. 계속 진행하시겠습니까?',
                '고객업체'
              );
            }
            if (isSelect) {
              loadClientManager(vars.dataSource, client.name);
              vars.disabled.clientManager = false;
              vars.formData.client_manager = null;
            } else {
              vars.formData.client_company = e.previousValue;
              vars.disabled.tradeYn = true;
            }
          }
        }
        methods.checkPossibleSave();
      },
      async onEndUserChanged(e) {
        if (vars.formState.readOnly) {
          return;
        }
        const client = e.component.option('selectedItem');
        if (client) {
          if (client.trade_yn && !vars.disabled.tradeYn) {
            const result = await confirm(
              '거래중지 업체입니다. 계속 진행하시겠습니까?',
              'EndUser'
            );
            if (!result) {
              vars.formData.end_user = e.previousValue;
              vars.disabled.tradeYn = true;
              return;
            }
          }
        }
        vars.disabled.tradeYn = false;
      },
      async onConfirmChanged(e) {
        if (!vars.formData.id) {
          return;
        }
        if (e.value) {
          vars.formState.readOnly = true;
          vars.disabled.edit = true;
          vars.disabled.delete = true;
          vars.disabled.items = true;
        } else {
          if (vars.formData.client_company && vars.formData.order_department) {
            vars.disabled.edit = false;
            vars.disabled.delete = false;
            vars.disabled.save = false;
            vars.disabled.items = false;
          }
        }
      },
      updateEtcValue(v) {
        vars.dlg.finder.data = v;
      },
      onDepartmentChanged(e) {
        if (!e.value) {
          vars.disabled.save = true;
          vars.disabled.items = true;
          vars.disabled.manager = true;
          vars.formData.order_manager = null;
          vars.dataSource.employee = [];
        } else {
          const selectItem = e.component.option('selectedItem');
          if (selectItem) {
            loadEmployee(vars.dataSource, selectItem.id);
            vars.disabled.manager = false;
          }
        }
        methods.checkPossibleSave();

        const selectItem = e.component.option('selectedItem');
        vars.warehouseName = selectItem ? selectItem.warehouse.wh_name : '';
        vars.filter.baseItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadBaseItem();

        vars.filter.quoteItem.warehouseCode = selectItem
          ? selectItem.wh_code
          : null;
        methods.loadQuoteItem();
      },
      onSavingItem(e) {
        e.changes.forEach(element => {
          if (element.type != 'remove') {
            element.data.fk_order_id = vars.formData.id;
            delete element.data.item;
            delete element.data.current_stock;
            delete element.data.available_stock;
          }
        });
      },
      setQuantity(newData, value, currentRowData) {
        newData.not_shipped =
          currentRowData.not_shipped + (value - currentRowData.order_quantity);
        newData.order_quantity = value;
        newData.unit_price = currentRowData.unit_price;

        if (currentRowData.closing_yn) {
          newData.assign_quantity = 0;
        } else {
          if (currentRowData.available_stock >= value) {
            newData.assign_quantity = value;
          } else {
            newData.assign_quantity = currentRowData.available_stock;
          }
        }
      },
      setUnitPrice(newData, value, currentRowData) {
        newData.order_quantity = currentRowData.order_quantity;
        newData.unit_price = value;
      },
      calcSupplyPrice(rowData) {
        let supply_price = 0;
        if (rowData.assign_quantity && rowData.unit_price) {
          supply_price = rowData.assign_quantity * rowData.unit_price;
        }
        rowData.supply_price = supply_price;
        methods.calcSummary();
        return supply_price;
      },
      calcAssignQuantity(rowData) {
        if (rowData.closing_yn) {
          return 0;
        }

        let assign_quantity = 0;
        if (rowData.available_stock >= rowData.order_quantity) {
          assign_quantity = rowData.order_quantity;
        } else {
          assign_quantity = rowData.available_stock;
        }
        rowData.assign_quantity = assign_quantity;
        return assign_quantity;
      },
      calcSummary() {
        let total = sumSupplyPrice(
          vars.grid.item1,
          'order_quantity',
          'unit_price'
        );
        const response = calcPriceSummary(vars.formData.vat_type, total);
        vars.formData.supply_price = response.supply_price;
        vars.formData.vat = response.vat;
        vars.formData.total_price = response.total_price;
      },
      loadBaseCode() {
        return baseCodeLoader([
          '부가세구분',
          '수주구분',
          '결재조건',
          '납품장소',
        ])
          .then(response => {
            vars.dataSource.payment_terms = response['결재조건'];
            vars.dataSource.order_type = response['수주구분'];
            vars.dataSource.vat_type = response['부가세구분'];
            vars.dataSource.delivery_place = response['납품장소'];
          })
          .then(() => (vars.init.value = true));
      },
      loadBaseItem() {
        vars.dataSource.baseItem = getBaseItem(
          null,
          vars.filter.baseItem.clientId,
          vars.filter.baseItem.warehouseCode
        );
      },
      loadQuoteItem() {
        vars.dataSource.quoteItem = getShipmentQuoteItem(
          [
            {
              name: 'quote',
              op: 'has',
              val: {
                name: 'client_company',
                op: 'eq',
                val: vars.filter.quoteItem.clientCompany,
              },
            },
          ],
          vars.filter.quoteItem.warehouseCode
        );
      },
      checkPossibleSave() {
        if (
          vars.formData.client_company &&
          vars.formData.order_department &&
          !vars.formData.confirmed
        ) {
          vars.disabled.save = false;
          vars.disabled.items = false;
        }
      },
    };

    watch(
      () => props.id,
      () => methods.initById(props.id)
    );

    return {
      vars,
      methods,
      shipmentQuoteItem,
      generateItemButtonOption,
    };
  },
};
</script>
<style>
.popup-message {
  text-align: center;
}
</style>
