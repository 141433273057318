import defaultLayout from '@/layouts/side-nav-outer-toolbar';

import Quote from '@/views/shipment/quote';
import Order from '@/views/shipment/order';
import Release from '@/views/shipment/release';
import OnSite from '@/views/shipment/on-site';
import EstimateStatus from '@/views/shipment/quote-status';
import OrderStatus from '@/views/shipment/order-status';
import ReleaseStatus from '@/views/shipment/release-status';
import OrderToReleaseStatus from '@/views/shipment/order-to-release-status';

export default [
  {
    path: '/shipment/quote',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Quote,
  },
  {
    path: '/shipment/quote/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Quote,
    props: true,
  },
  {
    path: '/shipment/order',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Order,
  },
  {
    path: '/shipment/order/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Order,
    props: true,
  },
  {
    path: '/shipment/release',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Release,
  },
  {
    path: '/shipment/release/:id',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: Release,
    props: true,
  },
  {
    path: '/shipment/on-site',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: OnSite,
  },
  {
    path: '/shipment/quote/status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: EstimateStatus,
  },
  {
    path: '/shipment/order/status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: OrderStatus,
  },
  {
    path: '/shipment/release/status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: ReleaseStatus,
  },
  {
    path: '/shipment/order-to-release/status',
    meta: { requiresAuth: true, layout: defaultLayout },
    component: OrderToReleaseStatus,
  },
];
